import React from "react";
import { connect } from "react-redux";
import { Button, Checkbox, Form, Modal, Typography, List } from "antd";
import { CaretRightFilled } from "@ant-design/icons";
import image from "../../../assets/images/avk-logo.png";
const { Title } = Typography;

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aggreed: false,
    };
  }

  handleChangeCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };
  render() {
    const { aggreed } = this.state;
    // const { classes, deco } = this.props;
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          zIndex: 1,
          position: "relative",
          display: "flex",
        }}
      >
        {/* <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
        </Helmet> */}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: '"Archia", sans-serif',
          }}
        >
          <div style={{ width: "100%", height: "100%" }}>
            <div style={{ justifyContent: "center" }}>
              <div
                style={{
                  display: "flex",
                  marginTop: "24px",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    color: "#3E80A8",
                    display: "flex",
                    padding: "5px 18px",
                    position: "relative",
                    fontSize: "18px",
                    alignItems: "center",
                    fontWeight: 600,
                    justifyContent: "center",
                  }}
                >
                  <img src={image} alt="Anuvadak" />
                  {"Anuvadak"}
                </div>
              </div>
              <Title
                level={5}
                style={{
                  color: "#3E80A8",
                  // background:'linear-gradient(-45deg, #3E80A8 0%, #3E80A8 33%, #3E80A8 100%)',
                  fontWeight: "bold",
                  paddingBottom: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "0.5rem",
                }}
              >
                Privacy Policy
              </Title>
              <Title
                level={6}
                gutterBottom
                align="center"
                style={{
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: "1rem",
                  fontFamily: "Archia,sans-serif",
                  fontWeight: 600,
                  lineHeight: 1.6,
                  margin: 0,
                  display: "block",
                  textAlign: "center",
                  marginBottom: "0.35rem",
                }}
              >
                PLEASE READ THIS AGREEMENT CAREFULLY BEFORE USING THE SERVICES.
              </Title>
              <section
                // className={classes.tncWrap}
                style={{ textAlign: "justify", width: "100%" }}
              >
                <div>
                  <div
                    style={{
                      lineHeight: "1.5",
                      fontSize: "13px",
                      marginTop: "1em",
                      marginBottom: "1em",
                    }}
                  >
                    Welcome to Reverie Language Technologies (“Reverie”). To
                    avail the services offered by us or by any of our
                    affiliates, you must agree to the following terms and
                    conditions in this End User License Agreement (“EULA”) and
                    specifically our Privacy Policy given below. By downloading,
                    installing and using any of our apps you are deemed to have
                    read carefully and accepted the following terms and
                    conditions of this Privacy Policy which forms part and
                    parcel of our EULA.
                  </div>
                  <div
                    style={{
                      fontWeight: 900,
                      fontSize: "11px",
                      marginTop: "1em",
                      marginBottom: "1em",
                    }}
                  >
                    YOU MUST AGREE TO THE TERMS OF THIS PRIVACY POLICY IN ORDER
                    TO INSTALL AND USE OUR SOFTWARE AND SERVICES. BY CLICKING ON
                    THE “ACCEPT” BUTTON, YOU AGREE TO BE BOUND TO THE TERMS OF
                    THIS AGREEMENT. YOU MAY NOT USE OUR SOFTWARE OR SERVICES IN
                    ANY WAY UNLESS YOU HAVE ACCEPTED THESE TERMS AND CONDITIONS.
                  </div>
                  <div
                    style={{
                      fontWeight: 900,
                      fontSize: "11px",
                      marginTop: "1em",
                    }}
                  >
                    IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS OF THIS
                    PRIVACY POLICY AND THE EULA, YOU DO NOT HAVE ANY RIGHT TO
                    USE OUR SERVICES, AND YOU MUST CEASE TO USE REVERIE’S
                    SERVICES AND UNINSTALL REVERIE’S APP(S).
                  </div>
                  <div style={{ fontWeight: 600, marginTop: "1em" }}>
                    Does Reverie store my information?
                  </div>
                  <div style={{ fontWeight: 600, marginTop: "1em" }}>
                    Your right to privacy while using our App(s) with us is one
                    of our biggest priorities, and this Privacy policy explains
                    our practices including:
                  </div>
                  <div style={{ fontWeight: 900, marginBottom: "1em" }}>
                    <div>
                      <div>
                        <CaretRightFilled style={{ fontSize: "11px" }} />
                        <span
                          style={{
                            paddingLeft: "1rem",
                            fontWeight: 500,
                            fontSize: "11px",
                          }}
                        >
                          What kind of personal and non-personal information we
                          learn about you because of our relationship.
                        </span>
                      </div>
                      <div>
                        <CaretRightFilled style={{ fontSize: "11px" }} />
                        <span
                          style={{
                            paddingLeft: "1rem",
                            fontWeight: 500,
                            fontSize: "11px",
                          }}
                        >
                          How this personal and non-personal information is
                          collected by us.
                        </span>
                      </div>
                      <div>
                        <CaretRightFilled style={{ fontSize: "11px" }} />
                        <span
                          style={{
                            paddingLeft: "1rem",
                            fontWeight: 500,
                            fontSize: "11px",
                          }}
                        >
                          What kind of information we may share about you with
                          third-parties.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    Your consent to this Privacy Policy and continued usage of
                    our App(s) and Services shall constitute your consent in
                    writing to our collection and usage of such information. In
                    case you do not wish to consent to the collection of such
                    information, you may not install our App(s) or use any of
                    our Services.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    If you have any questions as to the nature of information
                    collected by us, or in case you ever want to review what
                    information has been collected by us, you may e-mail us at
                    the address of our Grievance Officer provided in the EULA.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    Do we collect Personal Information and how do we use and
                    share it?
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    We may use or share Personal Information (e.g., name,
                    address, telephone number, email address, location, User
                    Device details) where it is necessary for us to complete a
                    transaction or do something that you have asked us to do, or
                    such information as may be required for the functionality of
                    the app. For example, we may collect information which
                    includes, but is not limited to, the following:
                  </div>
                  <List>
                    <List.Item>
                      <CaretRightFilled style={{ fontSize: "11px" }} />
                      <span style={{ paddingLeft: "1rem", fontSize: "11px" }}>
                        When registering to the App, the User may be asked to
                        provide his/her e-mail address, which will be used in
                        order to personalize his/her use experience, to perform
                        certain statistical analytics, and in order to contact
                        the User from time to time in accordance with the
                        EULA.We may similarly also collect Personal Information
                        from you in the course of any communication including
                        technical support, feedback and other related
                        activities, which in turn allows us to conduct our
                        business and to enable us to deliver and improve our
                        Services.
                      </span>
                    </List.Item>
                    <List.Item>
                      <CaretRightFilled style={{ fontSize: "11px" }} />
                      <span style={{ paddingLeft: "1rem", fontSize: "11px" }}>
                        Additionally, we will need to access Contacts, SMS and
                        Call history from your User Device, solely for the
                        purpose of the functionality of Indic Keyboard Swalekh
                        Flip and/or Indic Phonebook Apps with relation to the
                        Services being provided. Such information will not be
                        collected by us and will only be stored locally on the
                        User Device, and may be utilized in order to use
                        additional features of the App reliant upon such
                        permissions. Such information shall not be sent by us to
                        any third party, unless you choose to share this data
                        through our App. Please do note that as this data is
                        stored locally on your User Device and/or any
                        third-party cloud services, such as Google’s or Apple’s
                        own backup services, Reverie shall not be responsible
                        for the preservation or security of such data.
                      </span>
                    </List.Item>
                    <List.Item>
                      <CaretRightFilled style={{ fontSize: "11px" }} />
                      <span style={{ paddingLeft: "1rem", fontSize: "11px" }}>
                        The User may also grant us with certain access
                        permissions to his/her existing account for social
                        networking sites such as Facebook, Google+ etc. (“Social
                        Networking Account”), and thereby provide us with
                        certain information about the User, such as the User’s
                        date of birth and list of friends, as made available by
                        the User through his/her Social Networking Account.
                        However, such information is also not collected by
                        Reverie, and may be processed and saved locally on the
                        User Device.
                      </span>
                    </List.Item>
                    <List.Item>
                      <CaretRightFilled style={{ fontSize: "11px" }} />
                      <span style={{ paddingLeft: "1rem", fontSize: "11px" }}>
                        You hereby agree that we may use the contact details you
                        have provided us (such as the e-mail address etc.) for
                        the purpose of informing you regarding our products and
                        services, to ask you to participate in user surveys, and
                        to send you other marketing materials and newsletters.
                        You may withdraw your consent to such emails by clicking
                        the “Unsubscribe” button in the e-mail, or by sending us
                        a written notice by e-mail to the following address:{" "}
                        <span style={{ color: "#ee4037" }}>
                          marketing@reverieinc.com
                        </span>
                      </span>
                    </List.Item>
                  </List>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    We may use the Personal Information that we collect for our
                    internal purposes to develop, tune, enhance, and improve our
                    products and services, and for advertising and marketing
                    consistent with this Privacy Policy. By using Reverie’s
                    products and services, you acknowledge, consent and agree
                    that Reverie may collect, process, and use the information
                    that you provide to us and that such information shall only
                    be used by Reverie or third parties acting under the
                    direction of Reverie, pursuant to confidentiality
                    agreements, to develop, tune, enhance, and improve Reverie’s
                    services and products.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    We do not however, for any reason whatsoever, sell your
                    Personal Information to any third party or otherwise trade
                    on it. We do everything we reasonably can to protect your
                    rights of privacy in our Software, but we are not liable for
                    any unauthorised or unlawful disclosures of your personal
                    and confidential information made by third parties who are
                    not subject to our control, for example advertisers and
                    websites that have links on/ to our App(s) and/or Website.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    Reverie will not share any Personal Information it collects
                    with any third party, except in the following cases: (a) to
                    satisfy any applicable law, regulation, legal process or
                    governmental request; (b) to enforce this Privacy Policy
                    and/or the EULA, including investigation of potential
                    violations thereof; (c) to detect, prevent, or otherwise
                    address fraud, security or technical issues; (d) to respond
                    to User’s support requests or other correspondences; (e) to
                    respond to claims that any use of the App violates the
                    rights of third-parties; (f) to protect the rights,
                    property, or personal safety of Reverie, any of its Users,
                    or the general public; (g) when Reverie is undergoing any
                    change in control, including by means of merger, acquisition
                    or purchase of all or substantially all of its assets; (h)
                    to collect, hold, process and/or manage your Personal
                    Information through Reverie’s authorized third party service
                    providers, as reasonable for business purposes, which may be
                    located in a country that does not have the same data
                    protection laws as your jurisdiction; (i) pursuant to your
                    explicit approval prior to the disclosure; or (j) to
                    cooperate with third parties for the purpose of enhancing
                    the User’s experience.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    What about Non-Personal Information?
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    We may collect non-Personal Information, which is to say
                    information of a nature that is NOT capable of identifying
                    you, or contain any of your Personal Information, such as
                    name, address etc. or contain any sensitive information such
                    as bank account details, credit card details etc. We may
                    collect this information through our App(s), and we may also
                    collect non-Personal Information through the processing,
                    analysis and anonymization of Personal Information provided
                    by you. We may share this anonymized data, where we have
                    removed any personal data, with other parties.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    The User may also automatically provide us with certain
                    technical non-Personal Information and aggregated usage
                    information, such as the User Device brand and model,
                    operating system type and version, screen resolution,
                    geo-location, which third-party applications are installed
                    on the User Device and User’s uses thereof through the App,
                    the User’s manual configurations, ‘click-stream’,
                    interactions and activities on the App, the period of time
                    the User used the App and related time stamps, crash and
                    error logs, etc.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    The information which we may collect includes (but is not
                    limited to) hardware information of the User Device (e.g.
                    location, manufacturer, OS version etc.), event data (e.g.
                    mode of typing, key height etc.), keystroke data and other
                    Non-Personal Information.
                  </div>
                  <div style={{ fontWeight: 600, marginTop: "1em" }}>
                    How would Reverie use this Non-Personal Information?
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    We collect non-Personal Information and Personal Information
                    in order to provide, operate and improve the App(s), and any
                    and all features related thereto, including for statistical
                    and research purposes; to enable us to further develop,
                    customize and improve the App(s) based on Users’ common
                    preferences, uses, attributes and anonymized data; to create
                    and provide our research and business partners and
                    affiliates with anonymized data and other aggregated,
                    statistical and/or inferred non-Personal Information; to
                    enable us to provide our Users with a better user
                    experience, with more relevant and accurate information,
                    services, features and functionalities, and with technical
                    assistance and support; and in order to abide by any
                    applicable law.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    We may process this data at our sole discretion and for this
                    purpose it may be stored with us in a secure manner on our
                    own hardware solutions or third-party cloud based providers.
                    We strive to use acceptable industry standards to ensure
                    that such data shall remain highly secure and confidential
                    and under our control. You agree that the results of such
                    processing and/or analysis may be used by Reverie for any
                    commercial purpose such as for advertising or for collecting
                    market trends, not limited to any type of targeted
                    advertising campaigns possible with the information
                    collected. Such aggregated data may be used by us for any
                    commercial purpose which may benefit advertising platforms,
                    content publishers of any kind, including, but not limited
                    to media & entertainment companies, jobs portals, telecom/
                    network providers, central and state government departments
                    or agencies and others similar in nature and scope.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    You recognize and agree that under no circumstance would you
                    be entitled to any compensation, profit-sharing,
                    remuneration etc. by virtue of such collection of
                    information by Reverie and/or any commercial interest that
                    Reverie may derive out of it.
                  </div>
                  <div style={{ fontWeight: 600, marginTop: "1em" }}>
                    Retention and Disposal:
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    Reverie may retain such information collected from Users
                    from its Platform and / or App, for a period of three (3)
                    years or for as long as necessary, depending on the type of
                    information; purpose, means and modes of usage of such
                    information; and according to the SPI Rules.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    Data destruction or disposal ensures that the Reverie
                    manages the data it controls and processes it in an
                    efficient and responsible manner. When the retention period
                    for the data expires, Reverie destroys the data covered
                    under this policy. Any exceptions to this data destruction
                    or disposal must be approved by the Business Head.The
                    Company disposes all personal data when it is no longer
                    necessary for business use, provided that the disposal does
                    not conflict with our data retention policies, our customers
                    data retention policies, a court order, or any of our
                    regulatory obligations.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    The Company runs a scheduled script annually and the data
                    which is three (3) years old is deleted. Records of data
                    deleted is maintained in the admin panel with the date of
                    deletion and the User name.
                  </div>
                  <div style={{ fontWeight: 600, marginTop: "1em" }}>
                    So is any of my data at risk?
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    The short answer is No. As stated above, we only collect
                    personally identifiable information for a very limited
                    purpose in the first place. The non-Personal Information is
                    such that is not sensitive in nature, and cannot be used to
                    identify you. The data we collect is stored securely with us
                    with best industry practices and we do not in any event
                    publish or disclose any such information collected. The
                    Non-Personal data which is shared by us with third-parties
                    is in the form of aggregate data (e.g. analysis of marketing
                    trends etc.) and would not contain any personally
                    identifiable information.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    Please do note that as regards your Personal Information,
                    Reverie may be required to disclose the same if it: (i)
                    believes it is reasonably necessary to comply with legal
                    process (such as a court order, search warrant, etc.) or
                    other legal requirements of any governmental or regulatory
                    authority, (ii) would potentially mitigate our liability in
                    an actual or potential lawsuit, (iii) is otherwise necessary
                    to protect our rights or property, or (iv) is necessary to
                    protect the legal rights or property of others.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    Please do also note that we are an organization based in
                    India and our target demographic is Indian users. If you are
                    using our App(s) or Services from other regions, whose laws
                    governing data collection and use that may differ from
                    Indian law, including those whose privacy laws may be
                    stricter than Indian law, please note that you are
                    transferring your personal and non-personal data to India to
                    Reverie. By providing your data you consent to that transfer
                    and processing.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    Do note that we employ administrative, physical and
                    electronic measures designed to protect your information
                    from unauthorized access. However, despite these efforts, no
                    security measures are perfect or impenetrable and no method
                    of data transmission can be guaranteed against any
                    interception or other type of misuse.
                  </div>
                  <div style={{ fontWeight: 600, marginTop: "1em" }}>
                    Cookies
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    On our Website, we may employ Cookies which are tiny text
                    files which are essentially small identifiers which identify
                    your computer to our server as a unique user when you visit
                    certain pages on the Website and they are stored by your
                    Internet browser on your computer’s hard drive. “Cookies”
                    help us to recognize you if you visit our website again. To
                    protect your privacy, we do not use cookies to store or
                    transmit any personally identifiable information about you.
                    The acceptance of cookies is not a requirement for visiting
                    the Website. However, we would like to point out that some
                    functionality on the Website and ordering is only possible
                    with the activation of such Cookies. Cookies can be used to
                    recognize your Internet Protocol address, saving you time
                    while you are on, or want to enter, the Website. Your
                    browser can be set to not accept cookies, but this may
                    restrict your use of the Website.
                  </div>
                  <div style={{ fontWeight: 600, marginTop: "1em" }}>
                    Third-Party Links
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    Our App(s) and our Website may contain links to other sites.
                    Such other sites may use information about your use of our
                    Services. Our Privacy Policy does not apply to practices of
                    such sites that we do not own or control or to people we do
                    not employ. Therefore, we are not responsible for the
                    privacy practices or the accuracy or the integrity of the
                    content included on such other sites. We encourage you to
                    read the individual privacy statements of such third- party
                    services before using the same.
                  </div>
                  <div style={{ fontWeight: 600, marginTop: "1em" }}>
                    Marketing Materials
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    We may send periodic promotional or informational emails to
                    you, about our App(s) and Services, and, from time-to- time,
                    about third party products and offers we think may interest
                    you. You may opt-out of such communications by following the
                    opt-out instructions contained in the e-mail. Please note
                    that it may take up to 10 business days for us to process
                    opt-out requests. If you opt-out of receiving promotional
                    emails from us, we may still send you e-mails about your
                    account or any Services you have requested or received from
                    us. Also, please note that if you take advantage of a third
                    party offer that Reverie has sent to you, that third party
                    may collect certain information from you, subject to their
                    own privacy policies. We encourage you to read the
                    individual privacy statements of such third-party services
                    before using the same.
                  </div>
                  <div style={{ fontWeight: 600, marginTop: "1em" }}>
                    Changes to the Privacy Policy
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    Reverie reserves the right, at its sole discretion, to
                    modify our Privacy Policy at any time without any notice.
                    However, Reverie at its sole discretion may notify you from
                    time to time of such changes by posting a notice within the
                    App or within the App Store description, or via its Website
                    or by sending you a notice via e-mail. You shall be
                    responsible for periodically reviewing the Privacy Policy,
                    and for reviewing and becoming familiar with any such
                    modifications.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    In the event Reverie modifies the Privacy Policy in any
                    manner whatsoever, your continued use of the App following
                    such change to the Privacy Policy constitutes your
                    acceptance of the terms and conditions of the Privacy Policy
                    as modified.
                  </div>
                  <div style={{ fontWeight: 600, marginTop: "1em" }}>
                    Feedback and Grievances
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    We welcome your comments, suggestions, reviews or feedback
                    relating to the App(s) or the Services (collectively
                    “Feedback”). Such Feedback is important to us to continue to
                    improve our services. Feedback submitted to Reverie shall
                    become the property of Reverie. We will not be required to
                    treat any Feedback as confidential, and will not be liable
                    for any ideas (including without limitation, product, App or
                    advertising ideas) and will not incur any liability as a
                    result of any similarities that may appear in our future
                    App, Services, or operations. Without limitation, we will
                    have exclusive ownership of all present and future existing
                    rights to the Feedback of every kind and nature everywhere.
                    We will be entitled to use the Feedback for any commercial
                    or other purpose whatsoever, without compensation to you or
                    any other person sending the Feedback. You acknowledge that
                    you are responsible for whatever material you submit, and
                    you, not us, have full responsibility for the message,
                    including its legality, reliability, appropriateness,
                    originality, and copyright.
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      marginTop: "1em",
                      fontSize: "11px",
                    }}
                  >
                    In case you have any grievances, you may contact our
                    Grievance Officer at{" "}
                    <span style={{ color: "#ee4037" }}>
                      grievance.officer@reverieinc.com
                    </span>
                  </div>
                  <div style={{ textAlign: "center", marginTop: "2.5vh" }}>
                    <Checkbox
                      checked={aggreed}
                      name={"aggreed"}
                      // disabled={aggreed}
                      onChange={this.handleChangeCheck}
                    >
                      {
                        "I am above 18 years of age and have read and accepted Privacy Policy"
                      }
                    </Checkbox>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span
                    style={{
                      marginRight: "11px",
                      fontWeight: "bold",
                      fontSize: "11px",
                    }}
                  >
                    A product by
                  </span>{" "}
                  <a
                    href="https://reverieinc.com"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "#3E80A8",
                      fontSize: "0.875rem",
                      textDecoration: "none",
                      paddingBottom: "1rem",
                    }}
                  >
                    Reverie Language Technologies Ltd.
                  </a>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.get("authentication").user,
  }),
  (dispatch) => ({
    // userReducer: userReducer.getActions(dispatch),
    // authReducer: authReducer.getActions(dispatch),
  })
)(PrivacyPolicy);
