import * as UserApi from "../api/user";
import toastr from "toastr";
import ReducerFactory from "../../utils/reducerFactory";

const reducerName = "user";
const initialState = {
  loading: false,
  user: {},
  users: [],
  totalUsers: 0,
  deliveryManagers: [],
  collaborators: []
};

const reducerFactory = new ReducerFactory(reducerName, initialState);


reducerFactory.addAction("USER_LOADING", `${reducerName}Loading`,
  (status) => status,
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.loading = action.data;
    return newState;
  }
);


//fetch all user
reducerFactory.addAction("FETCH_USERS", "fetchUsers",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await UserApi.fetch(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.users = action.data.data.users;
      newState.totalUsers = action.data.data.totalUsers;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

//fetch user detail
reducerFactory.addAction("FETCH_USER_DETAILS", "fetchUserDetails",
  async (apikey) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await UserApi.fetchUserDetails(apikey);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.user = action.data.data.user;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

//fetch user detail
reducerFactory.addAction("DELIVERY_MANAGER_LIST", "deliveryManagerList",
  async (apikey) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await UserApi.deliveryManagerList(apikey);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.deliveryManagers = action.data.data;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

//fetch collaboratorList detail
reducerFactory.addAction("COLLABORATOR_LIST", "collaboratorList",
  async (organisationId) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await UserApi.collaboratorList(organisationId);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.collaborators = action.data.data;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('CREATE_NEW_USER', 'createNewUser',
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await UserApi.createNewUser(formValues);
    return response;
  }, (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message);
    } else {
      if (action.data.type && action.data.type === "VALIDATION_ERROR") {
        toastr.warning(action.data.data[0].message);
      } else {
        toastr.warning(action.data.message);
      }
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('SWITCH_USER_STATUS', 'switchUserStatus',
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await UserApi.switchUserStatus(formValues);
    return response;
  }, (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      toastr.success(action.data.message);
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('SWITCH_NMT_STATUS', 'switchNmtStatus',
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await UserApi.switchNmtStatus(params);
    return response;
  }, (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      toastr.success(action.data.message);
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('EDIT_USER', 'editUser',
  async (params) => {
    const response = await UserApi.editUser(params);
    return response;
  }, (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message);
    } else {
      toastr.warning(action.data.message);
    }
    return newState;
  }
);

reducerFactory.addAction('RESET_PASSWORD_THROUGH_PROFILE', 'resetPasswordThroughProfile',
  async (params) => {
    const response = await UserApi.resetPasswordThroughProfile(params);
    return response;
  }, (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message);
    } else {
      toastr.warning(action.data.message);
    }
    return newState;
  }
);
reducerFactory.addAction('PAGE_ACCESS_VERIFICATION', 'pageAccessverification',
  async (params) => {
    const response = await UserApi.pageAccessverification(params);
    return response;
  }, (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action.data.success) {
      // toastr.success(action.data.message);
    } else {
      // toastr.warning(action.data.message);
    }
    return newState;
  }
);

reducerFactory.addAction('UPDATE_CATEGORY_LIST', 'updateUserSelectedCategories',
  async (params) => {
    const response = await UserApi.updateUserSelectedCategories(params);
    return response;
  }, (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message);
    } else {
      toastr.warning(action.data.message);
    }
    return newState;
  }
);



export default reducerFactory;
