import axios from "./index";

export async function addApiByFile(params) {
  return axios.post("apiTranslation/add/file", params).then((res) => res.data);
}

export async function getApiTranslationList(params) {
  return axios
    .post("apiTranslation/paginated/get", params)
    .then((res) => res.data);
}

export async function deleteApiTranslationItem(params) {
  return axios.post("apiTranslation/delete", params).then((res) => res.data);
}

export async function getPaginatedApiData(params) {
  return axios
    .post("apiTranslation/data/paginated/get", params)
    .then((res) => res.data);
}

export async function updateApiTranslationField(params) {
  return axios.post("apiTranslation/update", params).then((res) => res.data);
}

export async function syncApiData(params) {
  return axios.post("apiTranslation/sync", params).then((res) => res.data);
}

export async function applyNmtToApiData(params) {
  return axios
    .post("apiTranslation/nmt", params)
    .then((res) => res.data)
    .catch((err) => ({ success: false }));
}
export async function getDynamicDataDomainList(params) {
  return axios.post("apiTranslation/getDynamicDataDomainList", params).then((res) => res.data);
}

export async function sendToManualTranslation(body){
  return axios.post("apiTranslation/sendToManualTranslation", body).then(res=>res.data)
}
export async function updateToCMS(params) {
  return axios.post("apiTranslation/localization/apply", params).then((res) => res.data);
}
export async function applyNmtToTmByApiId(params) {
  return axios.post("apiTranslation/static/nmt/tm", params).then((res) => res.data);
}

export async function getStaticTranslationDataList(params) {
  return axios
    .post("staticTranslation/getStaticTranslationDataList", params)
    .then((res) => res.data);
}
export async function getDynamicTranslationDataList(params) {
  return axios
    .post("dynamicTranslation/getDynamicTranslationDataList", params)
    .then((res) => res.data);
}

export async function getApiList(params) {
  return axios.post("apiTranslation/getApiList", params).then((res) => res.data);
}

export async function applyStaticContentEditByUser(params) {
  return axios.post("staticTranslation/applyStaticContentEditByUser", params).then((res) => res.data);
}

export async function apiVerification(params){
  return axios.post("apiTranslation/api/verify",params).then((res)=>res.data);
}

export async function fetchContentTypesList(params){
  return axios.post("apiTranslation/contentTypes/list",params).then((res)=>res.data);
}

export async function analyzeWordCountForApiTranslation(params){
  return axios.post ("apiTransation/manualTranslation/analyzeWordCount/get",params).then((res)=>res.data);
}

export async function getProjectStats(params){
  return axios.post ("apiTransation/projectStats/stats",params).then((res)=>res.data);
}

export async function getStaticContentTmCount(params){
  return axios.post ("staticTranslation/tmCount/get",params).then((res)=>res.data);
}

export async function getDynamicContentTmCount(params){
  return axios.post ("dynamicTranslation/tmCount/get",params).then((res)=>res.data);
}
export async function updateApiTranslationDetails(params){
  return axios.post ("apiTranslation/details/update",params).then((res)=>res.data);
}

export async function getApiDetailsByApiId(params){
  return axios.post ("apiTranslation/details/api/get",params).then((res)=>res.data);
}

export async function sendChangesTocmsOnContentUpdate(params){
  return axios.post("apiTranslation/auto/cms/update",params).then((res)=>res.data);
}

export async function applyDynamicContentEditByUser(params) {
  return axios.post("apiTranslation/dynamic/tm/edit", params).then((res) => res.data);
}

export async function applyTransliterationToTmsByObjectId(params) {
  return axios.post("apiTranslation/dynamic/transliteration/tm", params).then((res) => res.data);
}

export async function applyNmtToTmByDynamicApiId(params) {
  return axios.post("apiTranslation/dynamic/nmt/tm", params).then((res) => res.data);
}

export async function updateContentTypesByProjectId(params) {
  return axios.post("apiTranslation/content-types/details/update", params).then((res) => res.data);
}

export async function applyTransliterationToStaticTms(params) {
  return axios.post("apiTranslation/static/transliteration/tm", params).then((res) => res.data);
}

export async function addTranslationsForNewLanguage(params) {
  return axios.post("apiTranslation/newLang/add", params).then((res) => res.data);
}
export async function getRequestList(params) {
  return axios.post("apiTranslation/json/requests/list", params).then((res) => res.data);
}

export async function jsonWebhookCall(params) {
  return axios.post("apiTranslation/json/webhook", params).then((res) => res.data) .catch((err) => err);
}

export async function deletejsonRequest(params) {
  return axios.post("apiTranslation/json/request/delete", params).then((res) => res.data);
}

export async function deleteLanguage(params) {
  return axios.post("apiTranslation/langauge/delete", params).then((res) => res.data);
}

export async function getJsonDataSegmentCount(params) {
  return axios.post("apiTranslation/dynamic/segmentCount/get", params).then((res) => res.data);
}

export async function addNewLangaugeToDynamicProject(params) {
  return axios.post("apiTranslation/dynamic/language/add", params).then((res) => res.data);
}

export async function getMetaInfoKeysList(params) {
  return axios.post("apiTranslation/keys/list/get", params).then((res) => res.data);
}

export async function uploadStandardJsonFile(params) {
  // const config = {
  //   headers: {
  //     "content-type": "multipart/form-data",
  //   },
  // };
  return axios
    .post("apiTranslation/json/standard/file/upload", params)
    .then((res) => res.data);
}

export async function fetchJsonDataByUrl(params) {
  return axios.post("apiTranslation/json/blob/fetch", params).then((res) => res.data);
}
export async function deleteJsonFile(params) {
  return axios.post("apiTranslation/json/blob/delete", params) .then((res) => res.data);
}
export async function fetchValuesByMetaKey(params) {
  return axios.post("apiTranslation/json/list/fetch", params).then((res) => res.data);
}
export async function checkManualTranslationStatus(params) {
  return axios.post("apiTranslation/json/maual/status/get", params).then((res) => res.data);
}
export async function getRequestDetailsByMetaValue(params) {
  return axios.post("apiTranslation/json/request/details", params).then((res) => res.data);
}
