import axios from "./index";

//Getting all by Date
export async function getFilesList(params) {
  return axios
    .post("fileTranslation/getFilesList", params)
    .then((res) => res.data);
}

export async function getProcessedFilesList(params) {
  return axios
    .post("fileTranslation/getProcessedFilesList", params)
    .then((res) => res.data);
}

export async function sendFileToPrabandhak(params) {
  return axios
    .post("fileTranslation/sendFileToPrabandhak", params)
    .then((res) => res.data);
}

export async function automateNmtTranslation(params) {
  return axios.post("fileTranslation/nmt/auto", params).then((res) => res.data);
}

export async function sendWebhook(params) {
  return axios.post("fileTranslation/webhook", params).then((res) => res.data);
}

export async function uploadDocfile(formData) {
  return axios.post("doc-file/upload/docs", formData).then((res) => res.data);
}

//fetch/docs
export async function getDocFilesList(params) {
  return axios.post("doc-file/fetch/docs", params).then((res) => res.data);
}

//get CategoriesList
export async function getDocCategoriesList(params) {
  return axios.post("doc-file/fetch/category", params).then((res) => res.data);
}

//send Doc File to PBK
export async function sendDocFileToPrabandhak(params) {
  return axios.post("doc-file/send/prabandhak", params).then((res) => res.data);
}

//fetch TranslatedFile
export async function fetchTranslatedFile(params) {
  return axios.post("doc-file/fetch/files", params).then((res) => res.data);
}

export async function uploadLanguageFile(params) {
  return axios.post("fileTranslation/lang/add", params).then((res) => res.data);
}

export async function mapTranslatedDocFile(params) {
  return axios
    .post("fileTranslation/mapTranslatedDocFile", params)
    .then((res) => res.data);
}

export async function editFileTranslationDetails(params) {
  return axios.post("fileTranslation/edit", params).then((res) => res.data);
}
export async function deleteFileTranslationRequest(params) {
  return axios.post("fileTranslation/delete", params).then((res) => res.data);
}
export async function exportFiles(params) {
  return axios
    .post("fileTranslation/export/files", params)
    .then((res) => res.data);
}

export async function getStaticTranslationDataList(params) {
  return axios
    .post("staticTranslation/getStaticTranslationDataList", params)
    .then((res) => res.data);
}
export async function getDynamicTranslationDataList(params) {
  return axios
    .post("dynamicTranslation/getDynamicTranslationDataList", params)
    .then((res) => res.data);
}
export async function getApiList(params) {
  return axios
    .post("apiTranslation/getApiList", params)
    .then((res) => res.data);
}
export async function applyStaticContentEditByUser(params) {
  return axios
    .post("staticTranslation/applyStaticContentEditByUser", params)
    .then((res) => res.data);
}

export async function dynamicContentTransaltion(params) {
  return axios
    .post("avk_get/translation", params)
    .then((res) => res.data);
}
