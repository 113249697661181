import * as ScopeApi from '../api/scope';
import toastr from 'toastr';
import ReducerFactory from '../../utils/reducerFactory';

const reducerName = 'scope';
const initialState = {
    loading: false,
    urlList: [],
    totalUrlsCount: 0,
    uniqueDomainUrls: [],
    trackingUrls: [],
    trackingFilesListByUrl: [],
    activeUrls: []
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction('SCOPE_LOADING', `${reducerName}Loading`,
    (status) => status, (state, action) => {
        const newState = Object.assign({}, state);
        newState.loading = action.data;
        return newState;
    }
);

reducerFactory.addAction('ADD_TO_SCOPE', 'addToScope',
    async (formValues) => {
        toastr.info(
            "Url will be added in a moment",
            "Please wait!"
        );
        const response = await ScopeApi.addToScope(formValues);
        return response;
    }, (state, action) => {
        if (action.data.success === true) {
            const { successUrl } = action.data.data;
            const success = Object.values(successUrl).filter(Boolean).length;
            if (success) {
                toastr.success(
                    `${success} Url added successfully`,
                    "Success"
                );
            }

            const failed = Object.values(successUrl).length - success;
            if (failed) {
                toastr.error(
                    `${failed} Url addition failed`,
                    "Failure"
                );
            }

            reducerFactory.action(`fetchSope`)
        } else {
            toastr.error(
                "Url add to scope failed",
                "Failed"
            );
        }
        return state
    }
);

reducerFactory.addAction('FETCH_SCOPE', 'fetchScope',
    async (params) => {
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await ScopeApi.fetchUrlList(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        newState.loading = false;

        if (action.data.success === true) {
            newState.urlList = action.data.data.urlList;
            newState.totalUrlsCount = action.data.data.total;
        } else {
            if (action.data.message) {
                toastr.warning(action.data.message);
            }
        }
        return newState;
    }
);
reducerFactory.addAction('FETCH_DOMIN_URLS', 'fetchUrlListbyDomain',
    async (params) => {
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await ScopeApi.fetchUrlListbyDomain(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        newState.loading = false;

        if (action.data.success === true) {
            newState.urlList = action.data.data.urlList;
            newState.totalUrlsCount = action.data.data.total;
        } else {
            toastr.warning(action.data.message);
        }
        return newState;
    }
);

reducerFactory.addAction('FETCH_URLS', 'fetchUrlListbyUrl',
    async (params) => {
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await ScopeApi.fetchUrlListbyurl(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        newState.loading = false;

        if (action.data.success === true) {
            newState.urlList = action.data.data.urlList;
            newState.totalUrlsCount = action.data.data.total;
        } else {
            toastr.warning(action.data.message);
        }
        return newState;
    }
);
reducerFactory.addAction('FETCH_UNIQUE_URLS', 'fetchUniqueDomains',
    async (params) => {
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await ScopeApi.fetchUniqueDomains(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        newState.loading = false;

        if (action.data.success === true) {
            newState.uniqueDomainUrls = action.data.data.uniqueDomains;
        } else {
            toastr.warning(action.data.message);
        }
        return newState;
    }
);

reducerFactory.addAction('TOGGLE_SCOPE', 'toggleScope',
    async (params) => {
        reducerFactory.action(`${reducerName}Loading`, true);
        const response = await ScopeApi.toggleScope(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        newState.loading = false;
        if (action.data.success === true) {
            toastr.success(
                "Scope toggle successfully",
                "Scope Toggle"
            )
        } else {
            toastr.error(
                "Scope toggle failed",
                "Scope Toggle"
            )
        }
        return newState;
    }
);

reducerFactory.addAction('TOGGLE_TRACK_URL', 'toggleTrackUrl',
    async (params) => {
        reducerFactory.action(`${reducerName}Loading`, true);
        const response = await ScopeApi.toggleTrackUrl(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        newState.loading = false;
        if (action.data.success === true) {
            toastr.success(
                "Track Url toggled successfully",
                "Track Url Toggle"
            )
        } else {
            toastr.error(
                "Track Url toggle failed",
                "Track Url Toggle"
            )
        }
        return newState;
    }
);

reducerFactory.addAction('PROJECT_RESYNC', 'projectResync',
    async (params) => {
        if (params.actions === "url") {
            reducerFactory.action(`${reducerName}Loading`, true);
        }
        const response = await ScopeApi.projectResync(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        if (action.data.success) {
            toastr.success(action.data.message)
        } else {
            toastr.error(action.data.message)
        }
        newState.loading = false;
        return newState;
    }
);
reducerFactory.addAction(
    "GET_TRACKING_URLS",
    "getTrackingUrls",
    async (params) => {
        const response = await ScopeApi.getTrackingUrls(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        if (action.data.success) {
            console.log(action.data)
            newState.trackingUrls = action.data.data.trackingUrls;
        } else {
            toastr.error("Some error occurred while fetching tracking urls");
        }
        return newState;
    }
)
reducerFactory.addAction(
    "GET_TRACKING_FILES_BY_URL",
    "getTrackingFilesListByUrl",
    async (params) => {
        const response = await ScopeApi.getTrackingFilesListByUrl(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        if (action.data.success) {
            console.log(action.data)
            newState.trackingFilesListByUrl = action.data.data.trackingFilesList;
        } else {
            toastr.error("Some error occurred while fetching tracking files");
        }
        return newState;
    }
)
reducerFactory.addAction(
    "GET_TRACKING_FILE_SRC",
    "getTrackingFilesrc",
    async (params) => {
        const response = await ScopeApi.getTrackingFilesrc(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        if (action.data.success) {

        } else {
            toastr.error("Some error occurred while fetching tracking src");
        }
        return newState;
    }
)
reducerFactory.addAction(
    "GET_FILE_HTMLS",
    "getFilehtmls",
    async (params) => {
        const response = await ScopeApi.getFilehtmls(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        if (action.data.success) {

        } else {
            toastr.error("Some error occurred while fetching tracking file html");
        }
        return newState;
    }
)
reducerFactory.addAction(
    "ENABLE_URL_TRACK",
    "enableUrlTrack",
    async (params) => {
        const response = await ScopeApi.enableUrlTrack(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        if (action.data.success) {

        } else {
            toastr.error("Some error occurred while fetching tracking file html");
        }
        return newState;
    }
)

reducerFactory.addAction(
    "FETCH_ACTIVE_URL",
    "fetchActiveUrls",
    async (formValues) => {
        console.log(formValues);
        const response = await ScopeApi.fetchActiveUrls(formValues);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        if (action.data.success) {
            console.log(action.data.success);
        } else {
            toastr.error("Some error occurred");
        }
        return newState;
    }
)
reducerFactory.addAction(
    "PROJECT_CONTENT_TRACKER",
    "projectContentTracker",
    async (params) => {
        const response = await ScopeApi.projectContentTracker(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        if (action.data.success) {

        } else {
            toastr.error("Some error occurred");
        }
        return newState;
    }
)
reducerFactory.addAction(
    "GET_PREVIEW_URL",
    "getPreviewUrl",
    async (params) => {
        const response = await ScopeApi.getPreviewUrl(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        if (action.data.success) {

        } else {
            toastr.error("Some error occurred");
        }
        return newState;
    }
)

reducerFactory.addAction(
    "VERIFY_SCRIPT_INTEGRATION",
    "verifyScriptIntegration",
    async (params) => {
        const response = await ScopeApi.verifyScriptIntegration(params);
        return response;
    },
    (state, action) => {
        const newState = Object.assign({}, state);
        if (action.data.success) {

        } else {
            toastr.error("Some error occurred");
        }
        return newState;
    }
)


export default reducerFactory;