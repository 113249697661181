import {
  Form, Input, Button, Row, Col, Checkbox, Select
} from "antd";
import AuthReducer from "../../../redux/modules/auth";
import { connect } from "react-redux";
import { useHistory,Link } from "react-router-dom";
import "./FreeOrgAdminFormNew.css";
import { useState } from "react";
import { SyncOutlined, ArrowLeftOutlined, EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import toastr from "toastr";
import { languageMap } from "../../../constants/LanguageMap";
const { Option } = Select;


const FreeOrgAdminForm = (props) => {
  const [enteredOtp, setEnteredOtp] = useState("");
  const [enteredUrl, setEnteredUrl] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [resendOtpTimer, setResendOtpTimer] = useState(20);
  const [authenticationCode, setAuthenticationCode] = useState("");
  const [tcChecked, setTcChecked] = useState(false);
  const [submitted, setSubmitted] = useState(false)
  const [otpVerified, setOtpVerified] = useState(false)
  const [languages, setLanguages] = useState([]);
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordInputType, setPasswordInputType] = useState('password')
  const [confirmPasswordInputType, setConfirmPasswordInputType] = useState('password')


  const {
    authReducer, sendOtpLoader, resendOtpLoader, verifyOtpLoader, submitFormLoader, uiConfig
  } = props;

  let history = useHistory();

  const togglePasswordField = () => {
    setPasswordInputType(prevType => prevType === "password" ? "text" : "password");
  };

  const toggleConfirmPasswordField = () => {
    setConfirmPasswordInputType(prevType => prevType === "password" ? "text" : "password");
  };


  // send otp and create a non-verified user
  const sendOtp = (formData) => {
    let { name, email, phone, url, languages, password } = formData;
    console.log(languages);
    // create user with a default password
    name = name.trim();
    // Check if the URL starts with 'www'
    if (url.startsWith("www.")) {
      // Convert 'www' to 'https://www'
      url = "https://" + url;
    }
    if (name.length < 3) {
      toastr.warning("Name should be atleast 3 letters long")
      return;
    }
    authReducer.sendOtp({ name, email, phone, url, languages, password }).then(res => {
      if (res && res.success) {
        setAuthenticationCode(res.data.authenticationCode);
        setOtpSent(true);
      }
    });
  };

  const onBoardFreeOrgAdmin = () => {
    authReducer.onBoardFreeOrgAdmin({ authenticationCode }).then(res => {
      if (res && res.success) {
        const { token, projectId, role } = res.data;
        window.localStorage.setItem('avk_token', token);
        window.localStorage.setItem('role', btoa(role));
        authReducer.verifyClientToken()
        document.location.reload()
        // history.push(`/app/project/verify/${projectId}`);
      }
    })
  };

  const resendOtp = () => {
    authReducer.resendOtp({ authenticationCode });
  }


  // const countdownTimer = () => {
  //   const myInterval = setInterval(() => {
  //     setResendOtpTimer((currentSeconds) => {
  //       if (currentSeconds > 0) {
  //         return currentSeconds - 1;
  //       } else {
  //         clearInterval(myInterval);
  //         return 20;
  //       }
  //     });
  //   }, 1000);
  // };

  const verifyOtp = () => {
    authReducer.verifyOtp({
      otp: enteredOtp, authenticationCode
    }).then(res => {
      if (res && res.success) {
        setOtpVerified(true);
      }
    })
  }

  const validateThreeOptions = (rule, value, callback) => {
    if (value.length > 3) {
      callback('You can only select up to three options.');
    } else {
      callback();
    }
  };

  const handleSelectChange = (values) => {
    setLanguages(values);
  };

  function handleDropdownVisibleChange(open) {
    if (languages.length >= 3 && open) {
      // Automatically close the dropdown if 3 or more values are selected
      setLanguages(languages.slice(0, 3));
    }
  }

  function renderDropdown(menu) {
    // Hide the dropdown if 3 or more values are selected
    return languages.length >= 3 ? null : menu;
  }

  return (
    <div className="free-org-admin-form-new">
      <div className="signup-card-header">
        <span className="signup-card-header-text">Get started with Anuvadak Now</span>
        <div className="signup-card-header-sub">
            Launch multilingual website faster with minimal developer
            involvement Easily translate and manage translated content in one
            place
          </div>
      </div>
      <Row className="form-main-container">
        <Col span={24}>
          <Form
            name="Free Org Admin"
            // labelCol={{ span: 5 }}
            // wrapperCol={{ span: 24 }}
            scrollToFirstError={true}
            onFinish={(value) => {
              if (otpSent && otpVerified && !submitted) {
                onBoardFreeOrgAdmin();
              } else {
                sendOtp(value);
              }
            }}
            labelAlign="left"
          >
            <Form.Item > 
              <Form.Item name="name" noStyle rules={[
                { required: true, message: "Please enter your name" },
                { min: 3, message: "Minimum 3 characters" }
              ]}
              
              >
                <Input placeholder="Your Name" className="form-box-item"/>
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <Form.Item
                name="url"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Please enter valid URL",
                    type: "url",
                  },
                ]}
              >
                <Input onChange={(e) => setEnteredUrl(e.target.value)} placeholder="Website URL" className="form-box-item"/>
              </Form.Item>
            </Form.Item>
            <Form.Item>
              <Form.Item
                name="email"
                noStyle
                rules={[
                  {
                    required: true,
                    message: "Please enter valid Email",
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Email ID" className="form-box-item"/>
              </Form.Item>
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input
                  type={passwordInputType}
                  placeholder="Password"
                  suffix={
                    passwordInputType === "password" ? (
                      <EyeInvisibleOutlined onClick={togglePasswordField}/>
                    ) : (
                      <EyeOutlined onClick={togglePasswordField}/>
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                        //check if both passwords match
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                    },
                }),                ]}
              >
                <Input
                  type={confirmPasswordInputType}
                  placeholder="Confirm Password"
                  suffix={
                    confirmPasswordInputType === "password" ? (
                      <EyeInvisibleOutlined onClick={toggleConfirmPasswordField}/>
                    ) : (
                      <EyeOutlined onClick={toggleConfirmPasswordField}/>
                    )
                  }
                />
              </Form.Item>
            <Form.Item>
              <Form.Item
                name="languages"
                noStyle
                validateTrigger={["onChange", "onBlur"]}
                rules={[
                  {
                    required: true,
                    message: "Please select atleast one language",
                  },
                  // { validator: validateThreeOptions, message: "Select any 3 Language" }
                ]}
                label="Language"
                
              >
                <Select
                  mode="multiple"
                  allowClear
                  // maxTagCount={2}
                  style={{ textTransform: "capitalize"}}
                  onChange={handleSelectChange}
                  onDropdownVisibleChange={handleDropdownVisibleChange}
                  dropdownMatchSelectWidth={false}
                  dropdownRender={renderDropdown}
                  value={languages}
                  placeholder='Languages'
                  // className="form-box-item"
                  
                >
                  {
                    // Object.keys(languageMap).map((lang) => {
                    //   return <Option style={{ textTransform: "capitalize" }} value={lang} key={languageMap[lang]} >
                    //     {lang}
                    //   </Option>
                    // })
                    <Option style={{ textTransform: "capitalize" }} value={"hindi"} key={"hi"} ></Option>
                  }
                </Select>
              </Form.Item>
            </Form.Item>
            <Row gutter={[15]}>
              <Col span={18}>
                <Form.Item>
                  <Form.Item
                    name="phone"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid Number",
                        len: 10,
                        pattern: new RegExp(/^[0]?[6789]\d{9}$/)
                      },
                    ]}
                  >
                    <Input placeholder="Mobile Number" className="form-box-item" />
                  </Form.Item>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  {!otpSent && <Button
                    htmlType="submit"
                    disabled={otpVerified}
                    style={{
                      background: otpVerified ? "gray" : "#15bed4",
                      color:'white'
                    }}
                    className="otp-button"
                    loading={sendOtpLoader}
                  >
                    Send OTP
                  </Button>}
                  {otpSent && <Button
                    disabled={otpVerified}
                    style={{
                      background: otpVerified ? "gray" : "#15bed4",
                      color:'white'
                    }}
                    className="otp-button"
                    onClick={() => resendOtp()}
                    loading={resendOtpLoader}
                  >
                    Resend OTP
                  </Button>}
                </Form.Item>
              </Col>
            </Row>
            {otpSent && (
              <Row gutter={[10]}>
                <Col span={18}>
                  <Form.Item name="otp" style={{ fontSize: "1.7vh" }}>
                    <Input
                      placeholder="* * * * * *"
                      type="number"
                      onChange={(e) => setEnteredOtp(e.target.value)}
                      className="form-box-item"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item >
                    <Button
                      disabled={otpVerified}
                      style={{
                        background: otpVerified ? "green" : "#15bed4",
                        color: "white",                        
                      }}
                      className="otp-button"
                      onClick={() => { verifyOtp() }}
                      loading={verifyOtpLoader}
                    >
                      {otpVerified ? "Verified" : "Verify"}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={[0, 0]} justify="center">
              <Col span={24} >
                <Form.Item style={{ marginBottom: "0px" }}>
                  <div style={{display:"flex", justifyContent:"start"}}>
                    <Checkbox
                      onChange={(e) => {
                        setTcChecked(e.target.checked);
                      }}
                      value={tcChecked}
                      style={{ color: "#424242", fontStyle: "bold"}}
                    >
                    </Checkbox>
                    &nbsp;<span className="tNc" > I hereby accept the 
                      <a
                        onClick={() => {
                          history.push("privacy-policy");
                        }}
                      >
                        {" "}
                        Terms and Conditions
                      </a>                     
                    </span>
                  </div>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item style={{ marginTop: "5px" }}>
                  <Button
                    htmlType="submit"
                    block
                    disabled={(!(tcChecked && otpVerified) || submitFormLoader)}
                    style={{ background: (tcChecked && otpVerified) ? "rgb(4 42 68)" : "linear-gradient(0deg, #054B75, #054B75)", color: "white", marginBottom: "0px"}}
                    className="submit-button"
                  >
                    {submitFormLoader ? (
                      <SyncOutlined spin style={{ fontSize: "1.7em" }} />
                    ) : (
                      <div style={{ display: "flex" }}>
                        <div style={{ flexGrow: 1, textTransform: "capitalize" }}>
                          {/* Add {languages.join(" , ")} To Your Website */}
                          REGISTER FOR FREE
                        </div>
                      </div>
                    )}
                  </Button>
                </Form.Item>
              </Col>
              <Col span={24}>
              <div className="login-link-box"><Link to="/sign-in">Already Have an account? </Link></div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div >
  );
};

export default connect(
  (state) => ({
    resendOtpLoader: state.get("authentication").resendOtpLoader,
    sendOtpLoader: state.get("authentication").sendOtpLoader,
    verifyOtpLoader: state.get("authentication").verifyOtpLoader,
    submitFormLoader: state.get("authentication").submitFormLoader,
    uiConfig: state.get("authentication").uiConfig
  }),
  (dispatch) => ({
    authReducer: AuthReducer.getActions(dispatch),
  })
)(FreeOrgAdminForm);
