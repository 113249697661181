import * as MixedLangContentApi from "../api/mixedLangContent";
import toastr from "toastr";
import ReducerFactory from "../../utils/reducerFactory";

const reducerName = "mixedLangContent";
const initialState = {
  loading: false,
  mixedLangContent: [],
  total: 0,
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

//fetch batches as per request id
reducerFactory.addAction(
  "FETCH_MIXED_LANG_CONTENT",
  "fetchMixedLangContent",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await MixedLangContentApi.fetch(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.mixedLangContent = action.data.data.mixedLangContent;
      newState.total = action.data.data.total;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "ANALYZE_WORD_COUNT",
  "analyzeWordCount",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await MixedLangContentApi.analyzeWordCount(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.filteredTranslation = action.data.data.tmData;
    }
    newState.loading = false;
    return newState;
  }
);

export default reducerFactory;
