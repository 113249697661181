import axios from "./index";

//route to fetch all users
export async function fetch(params) {
  return axios
    .post("user/list", params)
    .then((res) => res.data);
}

//route to fetch user details
export async function fetchUserDetails(apikey) {
  return axios
    .post("user/fetchUserDetails", { apikey })
    .then((res) => res.data);
}

//route to switch user status
export async function switchUserStatus(requestBody) {
  return axios.post("/user/switchStatus", requestBody).then(res => res.data);
}

export async function deliveryManagerList() {
  return axios.get("/user/delivery-manager/list").then(res => res.data);
}

export async function collaboratorList() {
  return axios.get("/user/collaborator/list").then(res => res.data);
}

export async function createNewUser(body) {
  return axios.post("/user", body).then(res => res.data);
}

// route to switch NMT allowed status
export async function switchNmtStatus(body) {
  return axios.post("/user/update", body).then(res => res.data);
}

// edit user details
export async function editUser(body) {
  return axios.post("/user/update", body).then(res => res.data);
}

//reset password through profile
export async function resetPasswordThroughProfile(body) {
  return axios.post("/user/resetPasswordThroughProfile", body).then(res => res.data);
}

//update user categories list
export async function updateUserSelectedCategories(body) {
  return axios.post("user/update/category/list", body).then(res => res.data);
}

export async function pageAccessverification(body) {
  return axios.post("/user/access/verify",body).then(res=>res.data).catch(err=>err);
}
