import ReducerFactory from "../../utils/reducerFactory";
import toastr from "toastr";
import * as RazorpayApi from "../api/transaction";

const reducerName = "transaction";
const initialState = {
  orderDetails: {},
  orderStatus: {},
  paymentDetails: null,
  paymentList: [],
  topUpDetails: {},
  query: {}
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

//create new Payment Order
reducerFactory.addAction(
  "CREATE_PAYMENT_ORDER",
  "createPaymentOrder",
  async (params) => {
    const response = await RazorpayApi.createPaymentOrder(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if(!action.data.data.success){
      return toastr.warning("Invalid Request.!");
    }
    if (action?.data?.data?.success) {
      newState.orderDetails = action.data.data.data;
    }
    return newState;
  }
);

//check order success
reducerFactory.addAction(
  "CHECK_PAYMENT_ORDER_SUCCESS",
  "checkOrderPaymentSuccess",
  async (params) => {
    const response = await RazorpayApi.checkOrderPaymentSuccess(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.data.success) {
      newState = Object.assign({}, state);
      newState.orderStatus = action.data.data.data;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    return newState;
  }
);

//fetch payment details
reducerFactory.addAction(
  "FETCH_PAYMENT_DETAILS",
  "fetchPaymentDetails",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await RazorpayApi.fetchPaymentDetails(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.paymentDetails = action.data.data;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

//fetch payment list
reducerFactory.addAction(
  "FETCH_ALL_PAYMENTS",
  "fetchAllPayments",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await RazorpayApi.fetchAllPayments(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.paymentList = action.data.data;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

//fetch Plans list
reducerFactory.addAction(
  "FETCH_ALL_PLANS",
  "fetchAllPlans",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await RazorpayApi.fetchAllPlans(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.plansList = action.data.data;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

// add Top Up
reducerFactory.addAction(
  "ADD_TOP_UP",
  "addTopUp",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await RazorpayApi.addTopUp(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.data?.success) {
      newState.topUpDetails = action.data.data.data;
    }
    return newState;
  }
)

// create Query
reducerFactory.addAction(
  "CREATE_QUERY",
  "createQuery",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await RazorpayApi.createQuery(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.query = {};
      toastr.success("Thank you for Contacting us. We will get back to you within 48 Hours");
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
)

// create Query
reducerFactory.addAction(
  "UPDATE_TRANSACTION",
  "updateTransaction",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await RazorpayApi.updateTransaction(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.query = {};
      toastr.success("Transaction dismissed by user!!.");
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
)

export default reducerFactory;

