import { Image } from "antd";
import React from "react";
import EmailIllustration from "../../../assets/images/Illustrations/email_sent.svg";
import "./NotificationBanner.css";
export default class NotificationBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 30,
    };
  }

  componentDidMount() {
    // 60 second timer which will redirect to homepage in the end
    setInterval(() => {
      this.setState(
        (prevState) => ({
          timer: prevState.timer - 1,
        }),
        () => {
          if (this.state.timer === 0) this.props.history.push("/");
        }
      );
    }, 1000);
  }
  render() {
    const message = this.props.match.params.message;
    const { timer } = this.state;
    return (
      <div className="notification-banner">
        <div className="message"> {message}</div>

        <div className="flex-row image-container">
          <Image height={300} src={EmailIllustration} preview={false} />
        </div>
        <div className="timer">
          You will be redirected to home page in{" "}
          <strong>
            <span style={{ color: "red" }}>{timer}</span>{" "}
          </strong>
          seconds
        </div>
      </div>
    );
  }
}
