import axios from "./index";

//route to create a new payment order
export async function createPaymentOrder(params) {
    return axios.post("transaction/create", params)
}

//route to check order status
export async function checkOrderPaymentSuccess(params) {
    return axios.post("transaction/success", params)
}

export async function fetchPaymentDetails(params) {
    return axios.post("transaction/fetch/payment/details", params)
        .then((res) => res.data);
}

export async function fetchAllPayments() {
    return axios.get("proUser/payment/list")
        .then((res) => res.data);
}

export async function fetchAllPlans() {
    return axios.get("transaction/plans/list")
        .then((res) => res.data);
}

export async function addTopUp(params) {
    return axios.post("transaction/add/topup", params)
}

export async function createQuery(params) {
    return axios.post("query/create",params)
        .then(res => res.data);
}

export async function updateTransaction(params) {
    return axios.post("transaction/update", params).then(res => res.data);
}
