/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { reducer as form } from 'redux-form/immutable';
import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import history from '../utils/reducerFactory';
//  import languageProviderReducer from 'containers/LanguageProvider/reducer';
//  import login from './modules/login';
import authentication from './modules/auth'
import project from './modules/project';
import manualTranslation from './modules/manualTranslation';
import user from './modules/user';
import organisation from './modules/organisation';
import scope from './modules/scope';
import translation from './modules/translation';
import batches from './modules/batches';
import dashboard from './modules/dashboard';
import notification from './modules/notification';
import domainscope from './modules/domainscope';
import transaction from './modules/transaction';
import demo from './modules/demo';
import fileTranslation from './modules/fileTranslation';
import apiTranslation from './modules/apiTranslation';
import mixedLangContent from './modules/mixedLangContent'
//  import uiReducer from './modules/ui';
//  import initval from './modules/initForm';
/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    form,
    //  login: login.getReducer(),
    authentication: authentication.getReducer(),
    project: project.getReducer(),
    manualTranslation: manualTranslation.getReducer(),
    user: user.getReducer(),
    organisation: organisation.getReducer(),
    scope: scope.getReducer(),
    translation: translation.getReducer(),
    batches: batches.getReducer(),
    dashboard: dashboard.getReducer(),
    notification: notification.getReducer(),
    domainscope: domainscope.getReducer(),
    transaction: transaction.getReducer(),
    demo: demo.getReducer(),
    fileTranslation: fileTranslation.getReducer(),
    apiTranslation: apiTranslation.getReducer(),
    mixedLangContent: mixedLangContent.getReducer(),
    router: connectRouter(history),
    ...injectedReducers,
  });

  // Wrap the root reducer and return a new root reducer with router state
  const mergeWithRouterState = connectRouter(history);
  return mergeWithRouterState(rootReducer);
}
