export const languageMap = {
  // english: "en",
  hindi: "hi",
  odia: "od",
  punjabi: "pa",
  gujarati: "gu",
  marathi: "mr",
  telugu: "te",
  tamil: "ta",
  assamese: "as",
  bengali: "bn",
  malayalam: "ml",
  kannada: "kn",
  urdu: "ur"
};

export const sansadLanguageMap = {
  "odia":"od",
  "punjabi":"pa",
  "gujarati":"gu",
  "marathi":"mr",
  "telugu":"te",
  "tamil":"ta",
  "assamese":"as",
  "bengali":"bn",
  "malayalam":"ml",
  "kannada":"kn",
};

export const revereseLangMap = {
  "en": "english",
  "hi": "hindi",
  "od": "odia",
  "pa": "punjabi",
  "gu": "gujarati",
  "mr": "marathi",
  "te": "telugu",
  "ta": "tamil",
  "as": "assamese",
  "bn": "bengali",
  "ml": "malayalam",
  "kn": "kannada",
  "ur": "urdu",
  "mai": "maithili",
  "sa": "sanskrit",
  "kok": "konkani",
  "ks": "kashmiri",
  "kas-IN": "kashmiri(arabic)",
  "mni": "manipuri",
  "brx": "bodo",
  "sd": "sindhi",
  "doi": "dogri",
  "ne": "nepali",
  "sat": "santali",
  "af": "afrikaans",
  "sq": "albanian",
  "am": "amharic",
  "bg": "bulgarian",
  "my": "burmese",
  "km": "cambodian",
  "hr": "croatian",
  "cs": "czech",
  "da": "danish",
  "nl": "dutch",
  "et": "estonian",
  "fa": "farsi",
  "fi": "finnish",
  "fr": "french",
  "de": "german",
  "el": "greek",
  "hu": "hungarian",
  "is": "icelandic",
  "id": "indonesia",
  "it": "italian",
  "ku": "kurdish",
  "lo": "lao",
  "ln": "lingala",
  "mg": "malagasy",
  "ms": "malay",
  "mt": "maltese",
  "mn": "mongolian",
  "no": "norwegian",
  "ps": "pashto",
  "pl": "polish",
  "pt": "portuguese",
  "ro": "romanian",
  "ru": "russian",
  "sr": "serbian",
  "si": "sinhalese",
  "sk": "slovak",
  "sl": "slovenian",
  "so": "somali",
  "ckb": "sorani",
  "es": "spanish",
  "sw": "swahili",
  "sv": "swedish",
  "syl": "sylheti",
  "tg": "tajik",
  "th": "thai",
  "tr": "turkish",
  "tw": "twi",
  "uk": "ukrainian",
  "uz": "uzbek",
  "vi": "vietnamese",
  "zu": "zulu",
  "ar": "arabic",
  "az": "azeri",
  "dv": "dhivehi",
  "iw": "hebrew",
  "zh-TW": "chinese(trad)",
  "zh-CN": "chinese(sim)",
  "tl": "Tagalog",
  "ko": "Korean",
}

export const langColorCode = {
  hi: "#EF4438",      // Hindi
  mr: "#2B6E99",      // Marathi
  bn: "#212121",      // Bengali
  as: "#EF6C38",      // Assamese
  pa: "#2BB743",      // Punjabi
  te: "#6131A2",      // Telugu
  ta: "#228F8F",      // Tamil
  kn: "#607D8B",      // Kannada
  ml: "#FFAA88",      // Malayalam
  gu: "#09446A",      // Gujarati
  od: "#EF9E38",      // Odia
  ur: "#E67E22",      // Urdu

  // Adding new color codes for the additional languages
  en: "#4A90E2",      // English
  mai: "#C2185B",     // Maithili
  sa: "#673AB7",      // Sanskrit
  kok: "#8E44AD",     // Konkani
  ks: "#34495E",      // Kashmiri
  "kas-IN": "#16A085",// Kashmiri (Arabic)
  mni: "#E74C3C",     // Manipuri
  brx: "#27AE60",     // Bodo
  sd: "#D35400",      // Sindhi
  doi: "#2980B9",     // Dogri
  ne: "#F1C40F",      // Nepali
  sat: "#7F8C8D",     // Santali
  af: "#3498DB",      // Afrikaans
  sq: "#1ABC9C",      // Albanian
  am: "#9B59B6",      // Amharic
  bg: "#FF6347",      // Bulgarian
  my: "#FF5733",      // Burmese
  km: "#008080",      // Cambodian
  hr: "#2ECC71",      // Croatian
  cs: "#2C3E50",      // Czech
  da: "#ECF0F1",      // Danish
  nl: "#FFA500",      // Dutch
  et: "#4682B4",      // Estonian
  fa: "#DDA0DD",      // Farsi
  fi: "#800080",      // Finnish
  fr: "#FF4500",      // French
  de: "#FFD700",      // German
  el: "#32CD32",      // Greek
  hu: "#8B4513",      // Hungarian
  is: "#87CEFA",      // Icelandic
  it: "#DAA520",      // Italian
  ku: "#A0522D",      // Kurdish
  lo: "#A52A2A",      // Lao
  ln: "#708090",      // Lingala
  mg: "#00FF7F",      // Malagasy
  ms: "#B22222",      // Malay
  mt: "#FF1493",      // Maltese
  mn: "#C71585",      // Mongolian
  no: "#FFDAB9",      // Norwegian
  ps: "#FFFF00",      // Pashto
  pl: "#FF69B4",      // Polish
  pt: "#B0E0E6",      // Portuguese
  ro: "#F08080",      // Romanian
  ru: "#CD5C5C",      // Russian
  sr: "#66CDAA",      // Serbian
  si: "#BA55D3",      // Sinhalese
  sk: "#F4A460",      // Slovak
  sl: "#F5DEB3",      // Slovenian
  so: "#DEB887",      // Somali
  ckb: "#FFE4B5",     // Sorani
  es: "#F0E68C",      // Spanish
  sw: "#EEE8AA",      // Swahili
  sv: "#BDB76B",      // Swedish
  syl: "#AFEEEE",     // Sylheti
  tg: "#DB7093",      // Tajik
  th: "#FFD700",      // Thai
  tr: "#D8BFD8",      // Turkish
  tw: "#D3D3D3",      // Twi
  uk: "#FFB6C1",      // Ukrainian
  uz: "#8A2BE2",      // Uzbek
  vi: "#DA70D6",      // Vietnamese
  zu: "#FF6347",      // Zulu
  ar: "#FF7F50",      // Arabic
  az: "#7B68EE",      // Azeri
  dv: "#FF8C00",      // Dhivehi
  iw: "#9932CC",      // Hebrew
  "zh-TW": "#8A2BE2", // Traditional Chinese
  "zh-CN": "#8B0000", // Simplified Chinese
  tl: "#00CED1",      // Tagalog
  ko: "#DC143C",      // Korean
};

export const allLanguageMap = {
  "english": "en",
  "hindi": "hi",
  "odia": "od",
  "punjabi": "pa",
  "gujarati": "gu",
  "marathi": "mr",
  "telugu": "te",
  "tamil": "ta",
  "assamese": "as",
  "bengali": "bn",
  "malayalam": "ml",
  "kannada": "kn",
  "urdu": "ur",
  "maithili": "mai",
  "sanskrit": "sa",
  "konkani": "kok",
  "kashmiri": "ks",
  "kashmiri(arabic)": "kas-IN",
  "manipuri": "mni",
  "bodo": "brx",
  "sindhi": "sd",
  "dogri": "doi",
  "nepali": "ne",
  "santali": "sat",
  "afrikaans": "af",
  "albanian": "sq",
  "amharic": "am",
  "bulgarian": "bg",
  "burmese": "my",
  "cambodian": "km",
  "croatian": "hr",
  "czech": "cs",
  "danish": "da",
  "dutch": "nl",
  "estonian": "et",
  "farsi": "fa",
  "finnish": "fi",
  "french": "fr",
  "german": "de",
  "greek": "el",
  "hungarian": "hu",
  "icelandic": "is",
  "indonesia": "id",
  "italian": "it",
  "kurdish": "ku",
  "lao": "lo",
  "lingala": "ln",
  "malagasy": "mg",
  "malay": "ms",
  "maltese": "mt",
  "mongolian": "mn",
  "norwegian": "no",
  "pashto": "ps",
  "polish": "pl",
  "portuguese": "pt",
  "romanian": "ro",
  "russian": "ru",
  "serbian": "sr",
  "sinhalese": "si",
  "slovak": "sk",
  "slovenian": "sl",
  "somali": "so",
  "sorani": "ckb",
  "spanish": "es",
  "swahili": "sw",
  "swedish": "sv",
  "sylheti": "syl",
  "tajik": "tg",
  "thai": "th",
  "turkish": "tr",
  "twi": "tw",
  "ukrainian": "uk",
  "uzbek": "uz",
  "vietnamese": "vi",
  "zulu": "zu",
  "arabic": "ar",
  "azeri": "az",
  "dhivehi": "dv",
  "hebrew": "iw",
  "chinese(trad)": "zh-TW",
  "chinese(sim)": "zh-CN",
  "Tagalog": "tl",
  "Korean": "ko",
}

