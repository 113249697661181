/* eslint-disable no-param-reassign */
import axios from "axios";
import appConfig from "../../appConfig";
import history from "../../utils/history";
axios.defaults.baseURL = appConfig.prodRun
  ? `${window.location.origin}/api`
  : "http://localhost:3001/api/";
axios.defaults.headers = {
  "Content-Type": "application/json",
  "Cache-Control":"no-cache"
};
axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
  const accessSecret = window.localStorage.getItem("avk_token");
  if (accessSecret) {
    config.headers.authorization = `${accessSecret}`;
    config.headers.timezone = Date.now();
    return config;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    if (!response.data.success && response.data.flag === "TIME_TRAVEL") {
      localStorage.removeItem("avk_token");
      localStorage.removeItem("role");
      history.push("/");
    }
    return response;
  },
  (error) => {
    if (
      error?.response?.data?.code === 500 &&
      error?.response?.data?.name === "TokenExpiredError"
    ) {
      localStorage.removeItem("avk_token");
      localStorage.removeItem("role");
      history.push("/");
    }
    if (error?.response?.data) {
      error.response.data.success = false;
    }
    return Promise.reject(error?.response?.data);
  }
);
export default axios;
