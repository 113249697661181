import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './assets/styles/toastr.css';
import { HashRouter as Router } from 'react-router-dom';
import toastr from "toastr";
import configureStore from './redux/configureStore';
import history from './utils/history';

import App from './App';

toastr.options.preventDuplicates = true;

const store = configureStore();
const MOUNT_NODE = document.getElementById('root');
const render = () => {
  ReactDOM.render(
      <Provider store={store}>
           <Router>
              <App/>
          </Router>
      </Provider>,
      MOUNT_NODE
  )
}

render();