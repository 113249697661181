import toastr from "toastr";
import * as ApiTranslationApi from "../api/apiTranslation";
import * as _ from "lodash";
import configUI from "../../appConfig";

const { default: ReducerFactory } = require("../../utils/reducerFactory");

const reducerName = "apiTranslation";
const initialState = {
  loading: false,
  apiList: [],
  apiDataList: [],
  apiDataLoading: false,
  apiDataPageNo: 1,
  apiDataPageSize: 10,
  apiDataTotalCount: 1,
  dynamicDomainList: [],
  staticDataList: [],
  staticDataLoading: false,
  staticDataPageNo: 1,
  staticDataPageSize: 10,
  staticDataTotalCount: 1,
  dynamicDataList: [],
  dynamicDataLoading: false,
  dynamicDataPageNo: 1,
  dynamicDataPageSize: 10,
  dynamicDataTotalCount: 1,
  totalSegmentCount: 0,
  totalWordCount: 0,
  dynamicApisCount: 0,
  totalApisCount: 0,
  staticApisCount: 0,
  staticNoTranslationAvailableCount: 0,
  staticMachineTranslatedCount: 0,
  staticPbkTranslatedCount: 0,
  staticUserTranslatedCount: 0,
  dynamicNoTranslationAvailableCount: 0,
  dynamicMachineTranslatedCount: 0,
  dynamicPbkTranslatedCount: 0,
  dynamicUserTranslatedCount: 0,
  apiDetails: {},
  jsonRequestTotalCount: 0,
  jsonRequestsList: [],
  nmtJsonRequestsList: [],
  nmtReqsCount: 0,
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

// add file for api translation by file
reducerFactory.addAction(
  "TOGGLE_LOADING_API_TRANSLATION",
  "toggleLoadingApiTranslation",
  (status) => status,
  (state, action) => {
    let newState = Object.assign({}, state);
    const loading = action.data;
    return {
      ...newState,
      loading,
    };
  }
);

reducerFactory.addAction(
  "TOGGLE_LOADING_API_DATA",
  "toggleLoadingApiData",
  (status) => status,
  (state, action) => {
    let newState = Object.assign({}, state);
    const apiDataLoading = action.data;
    return {
      ...newState,
      apiDataLoading,
    };
  }
);

reducerFactory.addAction(
  "CLEAR_API_DATA_LIST",
  "clearApiDataList",
  (status) => status,
  (state, action) => {
    let newState = Object.assign({}, state);
    return {
      ...newState,
      apiDataList: [],
    };
  }
);

reducerFactory.addAction(
  "FETCH_API_TRANSLATION_LIST",
  "fetchApiTranslationList",
  async (params) => {
    reducerFactory.action("toggleLoadingApiTranslation", true);
    const response = await ApiTranslationApi.getApiTranslationList(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      const list = action.data.data.data;
      newState.apiList = list;
      newState.totalCount = action.data.data.totalCount;
    } else {
      toastr.error("Some error occured while fetching api list");
      newState.apiList = [];
    }
    newState.loading = false;
    return newState;
  }
);

// add file for api translation by file
reducerFactory.addAction(
  "ADD_FILE_BY_API",
  "addFileByApi",
  async (params) => {
    reducerFactory.action("toggleLoadingApiTranslation", true);
    const response = await ApiTranslationApi.addApiByFile(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data[0].success) {
      const inserted = action.data[0].data.inserted;
      toastr.success(
        `Api translation file accepted, ${inserted} new api inserted`
      );
    } else {
      toastr.error("Some error occured while uploading file");
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "DELETE_API_TRANSLATION_ITEM",
  "deleteApiTranslationItem",
  async (params) => {
    reducerFactory.action("toggleLoadingApiTranslation", true);
    const response = ApiTranslationApi.deleteApiTranslationItem(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(`Api Translation item deleted successfully`);
    } else {
      toastr.error("Some error occured while deleting api translation item");
    }

    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "UPDATE_API_TRANSLATION_FIELD",
  "updateApiTranslationField",
  async (params) => {
    reducerFactory.action("toggleLoadingApiTranslation", true);
    const response = ApiTranslationApi.updateApiTranslationField(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(`Api Translation item updated successfully`);
      const updatedData = action.data.data.updatedData;
      // update the field that you sent the query for updating
      for (let i = 0; i < newState.apiList.length; i++) {
        const element = newState.apiList[i];
        if (element.apiId === updatedData.apiId) {
          newState.apiList[i] = updatedData;
        }
      }
    } else {
      toastr.error("Some error occured while deleting api translation item");
    }

    newState.loading = false;
    return _.cloneDeep(newState);
  }
);

reducerFactory.addAction(
  "GET_PAGINATED_API_DATA",
  "getPaginatedApiData",
  async (params) => {
    reducerFactory.action("toggleLoadingApiData", true);
    reducerFactory.action("clearApiDataList");
    const response = await ApiTranslationApi.getPaginatedApiData(params);
    return { ...response, apiType: params.apiType };
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      if (action.data.apiType === "dynamic") {
        newState.apiDataList = action.data.data;
        newState.apiDataPageNo = 1;
        newState.apiDataPageSize = 10;
        newState.apiDataTotalCount = 1;
      } else {
        newState.apiDataList = action.data.data.data;
        newState.apiDataPageNo = action.data.data?.meta?.pagination?.page || 1;
        newState.apiDataPageSize =
          action.data.data?.meta?.pagination?.pageSize || 10;
        newState.apiDataTotalCount =
          action.data.data?.meta?.pagination?.total || 1;
      }
    } else {
      newState.apiDataList = [];
      newState.apiDataPageNo = 1;
      newState.apiDataPageSize = 10;
      newState.apiDataTotalCount = 1;
    }
    newState.apiDataLoading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "CHANGE_PAGINATION_API_DATA",
  "changePaginationApiData",
  (params) => params,
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.apiDataPageNo) {
      newState.apiDataPageNo = action.data.apiDataPageNo;
    }

    if (action.data.apiDataPageSize) {
      newState.apiDataPageSize = action.data.apiDataPageSize;
    }

    return newState;
  }
);

reducerFactory.addAction(
  "SYNC_API_DATA",
  "syncApiData",
  async (params) => {
    const response = await ApiTranslationApi.syncApiData(params);
    return { ...response, apiIdList: params.apiIdList };
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      const updatedApiList = action.data.apiIdList;

      for (let i = 0; i < newState.apiList.length; i++) {
        const element = newState.apiList[i];
        if (updatedApiList.includes(element.apiId)) {
          newState.apiList[i].status = configUI.apiTranslationStatus.SYNCING;
        }
      }
      toastr.success("Request to sync data accepted.");
    } else {
      toastr.warning("Some error occured while sending sync request!");
    }
    return _.cloneDeep(newState);
  }
);

reducerFactory.addAction(
  "APPLY_NMT_TO_API_DATA",
  "applyNmtToApiData",
  async (params) => {
    const response = await ApiTranslationApi.applyNmtToApiData(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Request to apply nmt accepted");
    } else {
      toastr.warning(action.data.message, "ERROR");
    }

    return newState;
  }
);
reducerFactory.addAction(
  "GET_DYNAMIC_DATA_DOMAIN_LIST",
  "getDynamicDataDomainList",
  async (params) => {
    const response = await ApiTranslationApi.getDynamicDataDomainList(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      newState.dynamicDomainList = action.data.data.domainList;
      // toastr.success("Request to apply nmt accepted");
    } else {
      toastr.warning("Some error occured while fetching data");
    }

    return newState;
  }
);
reducerFactory.addAction(
  "SEND_TO_PBK_STATIC_CONTENT",
  "sendToManualTranslation",
  async (body) => {
    const response = await ApiTranslationApi.sendToManualTranslation(body);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message);
    } else {
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);
reducerFactory.addAction(
  "UPDATE_TO_CMS",
  "updateToCMS",
  async (params) => {
    const response = await ApiTranslationApi.updateToCMS(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      // toastr.success(action.data.message);
      toastr.success(
        "CMS Updation request accepted sucessfully.Please wait for a moment, we will upadte you once the completed"
      );
    } else {
      toastr.warning(action.data.message);
    }

    return newState;
  }
);

reducerFactory.addAction(
  "APPLY_NMT_TO_TM_BY_APIID",
  "applyNmtToTmByApiId",
  async (params) => {
    const response = await ApiTranslationApi.applyNmtToTmByApiId(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      // newState.dynamicDomainList = action.data.data.domainList;
      toastr.success(action.data.message);
    } else {
      toastr.warning(action.data.message);
    }

    return newState;
  }
);

//getApiList
reducerFactory.addAction(
  "GET_API_LIST",
  "getApiList",
  async (params) => {
    const response = await ApiTranslationApi.getApiList(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      newState.apiDataList = action.data.data.apiDataList;
      // toastr.success("JSON Data fetched Sucessfully");
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "GET_STATIC_TRANSLATION_DATA_LIST",
  "getStaticTranslationDataList",
  async (params) => {
    const response = await ApiTranslationApi.getStaticTranslationDataList(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      newState.staticDataList = action.data.data.lookUpDataList;
      newState.staticDataTotalCount = action.data.data.lookUpDataCount;
      // toastr.success("JSON Data fetched Sucessfully");
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "GET_DYNAMIC_TRANSLATION_DATA_LIST",
  "getDynamicTranslationDataList",
  async (params) => {
    reducerFactory.action("toggleLoadingApiTranslation", true);
    const response = await ApiTranslationApi.getDynamicTranslationDataList(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      newState.dynamicDataList = action.data.data.lookUpDataList;
      newState.dynamicDataTotalCount = action.data.data.lookUpDataCount;
      // toastr.success("JSON Data fetched Sucessfully");
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "APPLY_STATIC_CONTENT_EDIT_BY_USER",
  "applyStaticContentEditByUser",
  async (params) => {
    const response = await ApiTranslationApi.applyStaticContentEditByUser(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      // newState.apiDataList = action.data.data.apiDataList;
      toastr.success("Content Updated Sucessfully");
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "API_VERIFICATION",
  "apiVerification",
  async (params) => {
    const response = await ApiTranslationApi.apiVerification(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Api verification successfull");
    } else {
      toastr.error("INVALID API: Api verication failed");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_CONTENTTYPES_LIST",
  "fetchContentTypesList",
  async (params) => {
    const response = await ApiTranslationApi.fetchContentTypesList(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(
        "The API list has been successfully fetched. Navigate to the 'API List' tab for more details."
      );
    } else {
      toastr.warning("ERROR: Eroor in fetching the Api List. Please try again");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "ANALYZE_WORDCOUNT_FOR__APITRANSLATION",
  "analyzeWordCountForApiTranslation",
  async (params) => {
    const response = await ApiTranslationApi.analyzeWordCountForApiTranslation(
      params
    );
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.totalSegmentCount = action.data.data.totalSegmentCount;
      // newState.totalWordCount=action.data.totalWordCount;
      if (action.data.data.totalSegmentCount === 0) {
        toastr.error(
          "Currently, there is no new content available for translation.",
          `Total Count :${action.data.data.totalSegmentCount}`
        );
      } else {
        toastr.success(
          "Segment Count fetched successfully",
          `Total Segment Count :${action.data.data.totalSegmentCount}`
        );
      }
    } else {
      toastr.warning("Error while fetching the word count");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "GET_PROJECT_STATS",
  "getProjectStats",
  async (params) => {
    const response = await ApiTranslationApi.getProjectStats(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.dynamicApisCount = action.data.data.dynamicApisCount;
      newState.staticApisCount = action.data.data.staticApisCount;
      newState.totalApisCount = action.data.data.totalApisCount;
    } else {
      toastr.warning("Error while fetching the Project stats");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "GET_STATIC_CONTENT_TMCOUNT",
  "getStaticContentTmCount",
  async (params) => {
    const response = await ApiTranslationApi.getStaticContentTmCount(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.staticNoTranslationAvailableCount =
        action.data.data.noTranslationAvailableCount;
      newState.staticMachineTranslatedCount =
        action.data.data.machineTranslatedCount;
      newState.staticPbkTranslatedCount = action.data.data.pbkTranslatedCount;
      newState.staticUserTranslatedCount = action.data.data.userTranslatedCount;
    } else {
      toastr.warning("Error while fetching the Project stats");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "GET_DYNAMIC_CONTENT_TMCOUNT",
  "getDynamicContentTmCount",
  async (params) => {
    const response = await ApiTranslationApi.getDynamicContentTmCount(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.dynamicNoTranslationAvailableCount =
        action.data.data.noTranslationAvailableCount;
      newState.dynamicMachineTranslatedCount =
        action.data.data.machineTranslatedCount;
      newState.dynamicPbkTranslatedCount = action.data.data.pbkTranslatedCount;
      newState.dynamicUserTranslatedCount =
        action.data.data.userTranslatedCount;
    } else {
      toastr.warning("Error while fetching the Project stats");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "UPDATE_API_TRANSLATION_DETAILS",
  "updateApiTranslationDetails",
  async (params) => {
    const response = await ApiTranslationApi.updateApiTranslationDetails(
      params
    );
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Api Details Updated Successfully");
    } else {
      toastr.warning("Error while fetching the Project stats");
    }
    return newState;
  }
);
reducerFactory.addAction(
  "GET_API_DETAILS_BY_APIID",
  "getApiDetailsByApiId",
  async (params) => {
    const response = await ApiTranslationApi.getApiDetailsByApiId(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      console.log(action.data.data);
      newState.apiDetails = action.data.data;
    } else {
      toastr.warning("Error while fetching the Project stats");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "SEND_CHANGES_TO_CMS_ON_CONTENT_UPDATE",
  "sendChangesTocmsOnContentUpdate",
  async (params) => {
    const response = await ApiTranslationApi.sendChangesTocmsOnContentUpdate(
      params
    );
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
    } else {
      toastr.warning(
        "ERROR:Error while updating translated content to the CMS."
      );
    }
    return newState;
  }
);

reducerFactory.addAction(
  "APPLY_TRANSLITERATION_TO_TM_BY_OBJECTID",
  "applyTransliterationToTmsByObjectId",
  async (params) => {
    const response =
      await ApiTranslationApi.applyTransliterationToTmsByObjectId(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      // newState.dynamicDomainList = action.data.data.domainList;
      toastr.success("Applied Transliteration successfully");
    } else {
      toastr.warning(action.data.message);
    }

    return newState;
  }
);

reducerFactory.addAction(
  "APPLY_NMT_TO_TM_BY_DYNAMIC_APIID",
  "applyNmtToTmByDynamicApiId",
  async (params) => {
    const response = await ApiTranslationApi.applyNmtToTmByDynamicApiId(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      // newState.dynamicDomainList = action.data.data.domainList;
      toastr.success(action.data.message);
    } else {
      toastr.warning(action.data.message);
    }

    return newState;
  }
);

reducerFactory.addAction(
  "APPLY_DYNAMIC_CONTENT_EDIT_BY_USER",
  "applyDynamicContentEditByUser",
  async (params) => {
    const response = await ApiTranslationApi.applyDynamicContentEditByUser(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    console.log(action.data);
    if (action.data.success) {
      // newState.apiDataList = action.data.data.apiDataList;
      toastr.success("Content Updated Sucessfully");
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "UPDATE_CONTENT_TYPES_BY_PROJECTID",
  "updateContentTypesByProjectId",
  async (params) => {
    const response = await ApiTranslationApi.updateContentTypesByProjectId(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    console.log(action.data);
    if (action.data.success) {
      toastr.success("Updated to the content-types successfully");
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "APPLY_TRANSLITERATION_TO_STATIC_TM",
  "applyTransliterationToStaticTms",
  async (params) => {
    const response = await ApiTranslationApi.applyTransliterationToStaticTms(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      // newState.dynamicDomainList = action.data.data.domainList;
      toastr.success("Applied Transliteration successfully");
    } else {
      toastr.warning(action.data.message);
    }

    return newState;
  }
);
reducerFactory.addAction(
  "ADD_TRANSLATIONS_FOR_NEW_LANGUAGE",
  "addTranslationsForNewLanguage",
  async (params) => {
    const response = await ApiTranslationApi.addTranslationsForNewLanguage(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      // newState.dynamicDomainList = action.data.data.domainList;
      toastr.success("Data synced succesfully");
    } else {
      toastr.warning(action.data.message);
    }

    return newState;
  }
);
reducerFactory.addAction(
  "GET_REQUEST_LIST",
  "getRequestList",
  async (params) => {
    const response = await ApiTranslationApi.getRequestList(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action.data.success) {
      newState.jsonRequestsList = action?.data?.data?.jsonRequestsList;
      newState.jsonRequestTotalCount = action?.data?.data?.totalCount;
    } else {
      toastr.warning("ERROR:Error while fetching requests");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "JSON_WEBHOOK_CALL",
  "jsonWebhookCall",
  async (params) => {
    const response = await ApiTranslationApi.jsonWebhookCall(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Webhool successfull");
    } else {
      toastr.warning("ERROR:Error in webhook call");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "GET_REQUEST_LIST",
  "getRequestList",
  async (params) => {
    reducerFactory.action("toggleLoadingApiTranslation", true);
    const response = await ApiTranslationApi.getRequestList(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action.data.success) {
      newState.jsonRequestsList = action?.data?.data?.jsonRequestsList;
      newState.jsonRequestTotalCount = action?.data?.data?.totalCount;
      newState.nmtJsonRequestsList =
        action?.data?.data?.nmtReqs;
      newState.nmtReqsCount = action?.data?.data?.nmtReqsCount;
    } else {
      toastr.warning("ERROR:Error while fetching requests");
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "JSON_WEBHOOK_CALL",
  "jsonWebhookCall",
  async (params) => {
    const response = await ApiTranslationApi.jsonWebhookCall(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action.data.success) {
      // toastr.success("Webhool successfull");
    } else {
      toastr.warning("ERROR:Error in webhook call");
    }
    return newState;
  }
);
reducerFactory.addAction(
  "DELETE_JSON_REQUEST",
  "deletejsonRequest",
  async (params) => {
    const response = await ApiTranslationApi.deletejsonRequest(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Request Deleted Successfully", "SUCCESS");
    } else {
      toastr.warning("ERROR:Error while deleting request");
    }
    return newState;
  }
);
reducerFactory.addAction(
  "DELETE_LANGUAGE",
  "deleteLanguage",
  async (params) => {
    const response = await ApiTranslationApi.deleteLanguage(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Langauge data deleted Successfully", "SUCCESS");
    } else {
      toastr.warning("ERROR:Error while deleting");
    }
    return newState;
  }
);
reducerFactory.addAction(
  "GET_JSON_DATA_SEGMENTCOUNT",
  "getJsonDataSegmentCount",
  async (params) => {
    const response = await ApiTranslationApi.getJsonDataSegmentCount(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.success) {
      toastr.success(
        "Segement Count for the JSON data fetched SuccessFully",
        "SUCCESS"
      );
    } else {
      toastr.warning("ERROR:Error while calculating");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "ADD_NEW_LANGUAGE_TO_DYNAMIC_PROJECT",
  "addNewLangaugeToDynamicProject",
  async (params) => {
    const response = await ApiTranslationApi.addNewLangaugeToDynamicProject(
      params
    );
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.success) {
      toastr.success(
        "Successfully added new language to the project",
        "SUCCESS"
      );
    } else {
      toastr.warning("ERROR:Error while adding language to the project");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "GET_METE_INFO_KEYS_LIST",
  "getMetaInfoKeysList",
  async (params) => {
    const response = await ApiTranslationApi.getMetaInfoKeysList(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.success) {
    } else {
      toastr.warning("ERROR:Error while fetching the keys");
    }
    return newState;
  }
);
reducerFactory.addAction(
  "UPLOAD_STANDARD_JSON_FILE",
  "uploadStandardJsonFile",
  async (params) => {
    const response = await ApiTranslationApi.uploadStandardJsonFile(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.success) {
    } else {
      toastr.warning(
        "ERROR:Error while uploading file. Please try again after some time"
      );
    }
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_JSON_DATA_BY_URL",
  "fetchJsonDataByUrl",
  async (params) => {
    const response = await ApiTranslationApi.fetchJsonDataByUrl(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.success) {
    } else {
      toastr.warning(
        "ERROR:Error while uploading file. Please try again after some time"
      );
    }
    return newState;
  }
);
reducerFactory.addAction(
  "DELETE_JSON_FILE",
  "deleteJsonFile",
  async (params) => {
    const response = await ApiTranslationApi.deleteJsonFile(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.success) {
    } else {
      toastr.warning(
        "ERROR:Error while deleting file. Please try again after some time"
      );
    }
    return newState;
  }
);
reducerFactory.addAction(
  "FETCH_VALUES_BY_META_KEY",
  "fetchValuesByMetaKey",
  async (params) => {
    const response = await ApiTranslationApi.fetchValuesByMetaKey(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.success) {
    } else {
      toastr.warning(
        "ERROR:Error while fetching meta Keys. Please try again after some time"
      );
    }
    return newState;
  }
);

reducerFactory.addAction(
  "CHECK_MANUAL_TRANSLATION_STATUS",
  "checkManualTranslationStatus",
  async (params) => {
    const response = await ApiTranslationApi.checkManualTranslationStatus(
      params
    );
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.success) {
    } else {
    }
    return newState;
  }
);

reducerFactory.addAction(
  "GET_REQUEST_DETAILS_BY_META_VALUE",
  "getRequestDetailsByMetaValue",
  async (params) => {
    const response = await ApiTranslationApi.getRequestDetailsByMetaValue(
      params
    );
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.success) {
    } else {
      toastr.warning(
        "ERROR:Error while fetching meta Keys. Please try again after some time"
      );
    }
    return newState;
  }
);

export default reducerFactory;
