import axios from "./index";

//route to create a new demo id
export async function createDemoId(params) {
  return axios.post("demo/createDemoId", params);
}

//route to fetch demo ids
export async function fetchDemoIds(params) {
  return axios.post("demo/fetchDemoIds", params).then((res) => res.data);
}

//route to edit demo ids
export async function editDemoEntry(params) {
  return axios.post("demo/editDemoEntry", params).then((res) => res.data);
}

//route to delete demo ids
export async function deleteDemoEntry(params) {
  return axios.post("demo/deleteDemoEntry", params).then((res) => res.data);
}
