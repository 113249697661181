
import axios from "./index";
import toastr from "toastr";

//fetch url list using project id
export async function sendManualTranslationRequest(body) {

      return axios.post('/manual-translation/request',body,{
          timeout:0
       })
      .then((res) => res.data
      );
  }
  
  //fetch url list using project id
export async function getManualTranslationRequest(params) {
  const { pageNumber, pageSize , searchText }=params;
  return axios.get(`/manual-translation/request/${pageNumber}/${pageSize}?searchText=${searchText}`)
    .then((res) => res.data);
}

//fetch url list using project id
export async function getManualTranslationRequestByProjectId(params) {
  const {projectId, pageNumber, pageSize}=params;
  return axios.get(`/manual-translation/request/${projectId}/${pageNumber}/${pageSize}`)
    .then((res) => res.data);
}

  //fetch url list using project id
  export async function getRequestInfo(requestId) {
    return axios.get(`/manual-translation/request/${requestId}`)
      .then((res) => res.data);
  }

  //approve a request
  export async function approveRequest(body) {
    return axios.post('/prabandhak/approve',body)
      .then((res) => res.data);
  }
  

  //delete translation request based on request id
  export async function deleteRequest(requestId) {
    return axios
      .post("/manual-translation/delete", {requestId})
      .then((res) => res.data);
  }

    //reject a request bnased on request id
    export async function rejectRequest(requestId) {
      return axios.post('/manual-translation/reject',{requestId})
        .then((res) => res.data);
    }

        //reject a request bnased on request id
        export async function fetchSubBatches(formData) {
          return axios
            .post("/manual-translation/fetchSubBatches", formData)
            .then((res) => res.data);
        }

    //upload batch file(s)
    export async function uploadBatchFile(formData) {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      return axios.post("/manual-translation/upload/batchFile", formData, config).then((res) => res.data);
    }

    //analyze batch file(s)
    export async function analyzeBatchFile(formData) {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      return axios.post("/manual-translation/analyze/batchFile", formData, config).then((res) => res.data);
    }

    export async function revertManualTranslation(body){
      return axios.post("/manual-translation/revert",body).then((res) => res.data);
    }

    export async function apiTranslationManualRequest(requestBody){
      return axios.post("/manual-translation/apiTranslation/request",requestBody).then((res)=>res.data);
    }

    
    
  