import * as OrganisationApi from "../api/organisation";
import toastr from "toastr";
import ReducerFactory from "../../utils/reducerFactory";

const reducerName = "organisation";
const initialState = {
  loading: false,
  organisation: {},
  organisations: [],
  totalOrganisations: 0,
};

const reducerFactory = new ReducerFactory(reducerName, initialState);


reducerFactory.addAction("ORGANISATION_LOADING", `${reducerName}Loading`,
  (status) => status,
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.loading = action.data;
    return newState;
  }
);


//fetch all organisations by page
reducerFactory.addAction(
  "FETCH_ORGANISATIONS",
  "fetchOrganisations",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await OrganisationApi.fetch(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.organisations = action.data.data.organisations;
      newState.totalOrganisations = action.data.data.totalOrganisations;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

//fetch organisation detail
reducerFactory.addAction(
  "FETCH_ORGANISATION_DETAILS",
  "fetchOrganisationDetails",
  async (organisationId) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await OrganisationApi.fetchOrganisationDetails(organisationId);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.organisation = action.data.data;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

//fetch all organisations
reducerFactory.addAction(
  "FETCH_ALL_ORGANISATIONS",
  "fetchAllOrganisations",
  async () => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await OrganisationApi.fetchAll();
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.allOrganisations = action.data.data.allOrganisations;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

//Create a new organisation
reducerFactory.addAction(
  "CREATE_ORGANISATIONS",
  "createOrganisation",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await OrganisationApi.createOrganisation(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    newState.loading = false;
    return newState;
  }
);

export default reducerFactory;
