import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
    Button, Form, Input, Row, Col, Select
} from "antd";

import {
    EyeOutlined, EyeInvisibleOutlined,
} from "@ant-design/icons";

const { Option } = Select;

class SignUpForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: "",
            organisation: "",
            role: "organisationAdmin",
            termsCondition: false,
            passwordInputType: "password",
            confirmPasswordInputType: "password",

        }
    }

    onChangeInputField = (inputField) => {
        this.setState(inputField);
    }

    togglePasswordField = () => {
        const { passwordInputType } = this.state;
        this.setState({ passwordInputType: passwordInputType === "password" ? "text" : "password" });
    };

    toggleConfirmPasswordField = () => {
        const { confirmPasswordInputType } = this.state;
        this.setState({ confirmPasswordInputType: confirmPasswordInputType === "password" ? "text" : "password" });
    };

    onSubmitForm = () => {
        const { onSubmitForm } = this.props;
        const {
            lastName, firstName, email, password, confirmPassword,
            organisation, role
        } = this.state;
        onSubmitForm({
            lastName, firstName, email, password, confirmPassword,
            organisation, role
        })
    }

    render() {
        const { passwordInputType, confirmPasswordInputType, role } = this.state;
        //Component to toggle password input field type
        const TogglePasswordIcon = () => {
            return (
                <div onClick={this.togglePasswordField} style={{ cursor: "pointer" }}>
                    {passwordInputType === "password" ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                </div>
            );
        };

        //Component to toggle confirm password input field type
        const ToggleConfirmPasswordIcon = () => {
            return (
                <div onClick={this.toggleConfirmPasswordField} style={{ cursor: "pointer" }}>
                    {confirmPasswordInputType === "password" ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                </div>
            );
        };

        const signupButtonStyle = {
            width: "100%"
        }
        const addUserButtonStyle = {

        }
        return <div>
             <div className="text-center">
                <h2>Sign Up</h2>
            </div>
            <div>
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    className="row-col"
                    onValuesChange={this.onChangeInputField}
                >
                    <Row gutter={10}>
                        <Col span={12}>
                            <Form.Item
                                name="firstName"
                                rules={[
                                    { required: true, message: "Please input your first name!" },
                                ]}
                            >
                                <Input placeholder="First Name" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="lastName"
                                rules={[
                                    { required: true, message: "Please input your last name!" },
                                ]}
                            >
                                <Input placeholder="Last Name" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: "Please input your email!" },
                        ]}
                    >
                        <Input type="email" placeholder="Enter your valid email id." />
                    </Form.Item>

                    <Form.Item
                        name="organisation"
                        rules={[
                            { required: true, message: "Please input your organisation name." },
                        ]}
                    >
                        <Input placeholder="Enter your organisation Name" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: "Please input your password!" },
                        ]}
                    >
                        <Input type={passwordInputType} placeholder="Password" suffix={<TogglePasswordIcon />} />
                    </Form.Item>
                    <Form.Item
                        name="confirmPassword"
                        rules={[
                            { required: true, message: "Please input your confirm password!" },
                        ]}
                    >
                        <Input type={confirmPasswordInputType} placeholder="Confirm Password" suffix={<ToggleConfirmPasswordIcon />} />
                    </Form.Item>

                    {/* <Form.Item name="remember" valuePropName="checked">
                                <Checkbox>
                                    I agree the{" "}
                                    <a href="#pablo" className="font-bold text-dark">
                                        Terms and Conditions
                                    </a>
                                </Checkbox>
                            </Form.Item> */}

                    <Form.Item >
                        <Button onClick={this.onSubmitForm} type="primary" htmlType="submit"
                            style={signupButtonStyle}>
                            Sign Up
                        </Button>
                    </Form.Item>
                </Form>
            </div>
                <div>
                    <p className="font-semibold text-muted text-center">
                        Already have an account?{" "}
                        <Link to="/sign-in" className="font-bold text-dark">Sign In</Link>
                    </p>
                </div>
        </div>
    }
}

export default SignUpForm;