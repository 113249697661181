import * as BatchApi from "../api/batches";
import toastr from "toastr";
import ReducerFactory from "../../utils/reducerFactory";

const reducerName = "batches";
const initialState = {
  loading: false,
  batches: [],
  total: 0
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

//loading batches
reducerFactory.addAction('BATCHES_LOADING', `${reducerName}Loading`,
  (status) => status, (state, action) => {
    const newState = Object.assign({}, state);
    newState.loading = action.data;
    return newState;
  }
);

//fetch batches as per request id
reducerFactory.addAction(
  "FETCH_BATCHES",
  "fetchBatches",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await BatchApi.fetch(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.batches = action.data.data.batches;
      newState.total = action.data.data.total;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

export default reducerFactory;
