import * as DomainScopeApi from '../api/domainscope.js';
import toastr from 'toastr';
import ReducerFactory from '../../utils/reducerFactory';

const reducerName = 'domainscope';
const initialState = {
  loading: false,
  statsLoading:false,
  //stats
  totalDomain:0,
  nmtCall:0,
  totalTraffic:0,
  wordCount:0,
  uniqueWordCount:0,
  //Pie chart traffic
  langWiseTraffic:[],
  //
  domainList:[],
  totalDomainList:0,
  domainWiseTraffic:[],
  totalDomainWiseTraffic:0,
  domainWiseWordCount:[],
  totalDomainWiseWordCount:0,
  domainWiseNMTCall:[],
  totalDomainWiseNMTCall:0,
  domains:[],
  overallDomainLangWise: [],
  urlListNMTCall: [],
  totalUrlNMTCall: 0,
  hostnames:[],
  totalDomains:0,
  projectWiseCollaborators:[]
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction('DOMAIN_SCOPE_LOADING', `${reducerName}Loading`,
  (status) => status, (state, action) => {
    const newState = Object.assign({}, state);
    newState.loading = action.data;
    return newState;
  }
);
reducerFactory.addAction('STATS_LOADING', 'statsLoading',
  (status) => status, (state, action) => {
    const newState = Object.assign({}, state);
    newState.statsLoading = action.data;
    return newState;
  }
);



reducerFactory.addAction('MULTI_DOMAIN_SCOPE_STATS', 'multiDomainScopeStats', 
    async (params)=>{
        reducerFactory.action('statsLoading', true)
        const response = await DomainScopeApi.multiDomainScopeStats(params);
        return response;
    },
    (state,action) => {
        const newState = Object.assign({},state);
        if (action.data.success) {
            newState.totalDomain = action.data.data.totalDomain;
            newState.nmtCall = action.data.data.nmtCall;
            newState.totalTraffic = action.data.data.totalTraffic;
            newState.wordCount = action.data.data.sourceWordCount;
            newState.uniqueWordCount = action.data.data.uniqueWordCount;
        } else {         
            toastr.warning("Error encountered while fetching stats");
        }
        newState.statsLoading = false;
        return newState;
    }
);

reducerFactory.addAction('FETCH_DOMAIN_SCOPE', 'fetchDomainScope', 
    async (params)=>{
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await DomainScopeApi.fetchDomainScope(params);
        return response;
    },
    (state,action) => {
        const newState = Object.assign({},state);
        if (action.data.success) {
            newState.totalDomainList = action.data.data.totalDomainList;
            newState.domainList = action.data.data.domainList;
        } else {
            toastr.warning(action.data.message);
        }
        newState.loading = false;
        return newState;
    }
);
reducerFactory.addAction('FETCH_DOMAINS', 'fetchDomains', 
    async (params)=>{
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await DomainScopeApi.fetchDomains(params);
        return response;
    },
    (state,action) => {
        const newState = Object.assign({},state);
        if (action.data.success) {
            newState.domains = action.data.data && action.data.data.domains ? action.data.data.domains:[];
        } else {
            toastr.warning(action.data.message);
        }
        newState.loading = false;
        return newState;
    }
);

reducerFactory.addAction('FETCH_DOMAIN_WISE_WORDCOUNT', 'fetchDomainWiseWordCount', 
    async (params)=>{
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await DomainScopeApi.fetchDomainWiseWordCount(params);
        return response;
    },
    (state,action) => {
        const newState = Object.assign({},state);
        if (action.data.success) {
            newState.totalDomainWiseWordCount = action.data.data.totalDomainUrlsList;
            newState.domainWiseWordCount = action.data.data.domainUrlsList;
        } else {
            toastr.warning(action.data.message);
        }
        newState.loading = false;
        return newState;
    }
);

reducerFactory.addAction('FETCH_DOMAIN_WISE_TRAFFIC', 'fetchDomainWiseTraffic', 
    async (params)=>{
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await DomainScopeApi.fetchDomainWiseTraffic(params);
        return response;
    },
    (state,action) => {
        const newState = Object.assign({},state);
        if (action.data.success) {
            newState.domainUrlsList = action.data.data.domainUrlsList;
            newState.overallDomainLangWise = action.data.data.overallDomainLangWise;
            newState.totalDomainTraffic = action.data.data.totalDomainTraffic;
            newState.totalDomainUrlsList = action.data.data.totalDomainUrlsList;
        } else {
            toastr.warning(action.data.message);
        }
        newState.loading = false;
        return newState;
    }
);

reducerFactory.addAction('FETCH_DOMAIN_WISE_NMTCALL', 'fetchDomainWiseNMTCall', 
    async (params)=>{
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await DomainScopeApi.fetchDomainWiseNMTCall(params);
        return response;
    },
    (state,action) => {
        const newState = Object.assign({},state);
        if (action.data.success) {
            newState.urlListNMTCall = action.data.data.domainUrlsList;
            newState.totalUrlNMTCall = action.data.data.totalDomainUrlsList

        } else {
            toastr.warning(action.data.message);
        }
        newState.loading = false;
        return newState;
    }
);

reducerFactory.addAction('TOGGLE_DOMAIN_ACTIVE_STATUS', 'toggleDomainActiveStatus', 
    async (params)=>{
        const response = await DomainScopeApi.toggleDomainActiveStatus(params);
        return response;
    },
    (state,action) => {
        const newState = Object.assign({},state);
        if (action.data.success) {
            const { hostname,status } = action.data.data;
            const domainList = [...newState.domainList];
            for (let i = 0; i < domainList.length; i++) {
                const element = domainList[i];
                if (element.hostname === hostname) {
                    element.active = status;
                }
            }
            newState.domainList = domainList;
        } else {
            toastr.warning(action.data.message);
        }
        return newState;
    }
);

reducerFactory.addAction('UPDATE_DOMAIN_SCOPE', 'updateDomainScope', 
    async (params)=>{
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await DomainScopeApi.updateDomainScope(params);
        return response;
    },
    (state,action) => {
        const newState = Object.assign({},state);
        if (action.data.success) {
            toastr.success("Languages set successfully")
        } else {
            toastr.warning(action.data.message);
        }
        newState.loading = false;
        return newState;
    }
);



reducerFactory.addAction('FETCH_MULTI_DOMAINS', 'fetchMultiDomains', 
    async (params)=>{
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await DomainScopeApi.fetchMultiDomains(params);
        return response;
    },
    (state,action) => {
        const newState = Object.assign({},state);
        if (action.data.success) {
            newState.hostnames = action.data.data.hostnames;
            newState.totalDomains = action.data.data.totalDomains;
        } else {
            toastr.warning(action.data.message);
        }
        newState.loading = false;
        return newState;
    }
);


reducerFactory.addAction('ASSIGN_COLLABORATOR_BY_DOMAIN', 'assignColloborator', 
    async (params)=>{
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await DomainScopeApi.assignColloborator(params);
        return response;
    },
    (state,action) => {
        const newState = Object.assign({},state);
        if (action.data.success) {
            newState.hostnames = action.data.data.hostnames;
            newState.totalDomains = action.data.data.totalDomains;
        } else {
            toastr.warning(action.data.message);
        }
        newState.loading = false;
        return newState;
    }
);
//getCollaboratorASsignedList

reducerFactory.addAction('GET_COLLABORATOR_ASSSIGNED_LIST', 'getCollaboratorAssignedList', 
    async (params)=>{
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await DomainScopeApi.getCollaboratorAssignedList(params);
        return response;
    },
    (state,action) => {
        const newState = Object.assign({},state);
        if (action.data.success) {
            newState.projectWiseCollaborators = action.data.data;
            console.log("data",action.data.data);
        } else {
            toastr.warning(action.data.message);
        }
        newState.loading = false;
        return newState;
    }
);

reducerFactory.addAction('MULTIDOMAIN_COLLABORATOR_DELETE', 'onMultiDomainColloboratorDelete', 
    async (params)=>{
        reducerFactory.action(`${reducerName}Loading`, true)
        const response = await DomainScopeApi.onMultiDomainColloboratorDelete(params);
        return response;
    },
    (state,action) => {
        const newState = Object.assign({},state);
        if (action.data.success) {
            console.log("data",action.data.success);
        } else {
            toastr.warning(action.data.message);
        }
        newState.loading = false;
        return newState;
    }
);


export default reducerFactory;