import { Button, Input, Form, Layout, Image } from "antd";
import React from "react";
import authReducer from "../../redux/modules/auth";
import toastr from "toastr";
import { connect } from "react-redux";
import comfirmPasswordIllustration from "../../assets/images/Illustrations/confirm_pw.svg";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import LeftBanner from "../../components/layout/LeftBanner/LeftBanner";
import BackgroundWave from "../../assets/images/freeTrialBackground.svg"
import { Row, Col } from 'antd';
const { Content } = Layout;

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      passwordInputType: "password",
      confirmPasswordInputType: "password",
    };
  }

  componentDidMount() {
    // const { token } = this.props.match.params;
  }

  togglePasswordField = () => {
    const { passwordInputType } = this.state;
    this.setState({
      passwordInputType: passwordInputType === "password" ? "text" : "password",
    });
  };

  toggleConfirmPasswordField = () => {
    const { confirmPasswordInputType } = this.state;
    this.setState({
      confirmPasswordInputType:
        confirmPasswordInputType === "password" ? "text" : "password",
    });
  };

  submitPassword = async () => {
    const { confirmPassword, password } = this.state;
    const { authReducer } = this.props;
    if (password !== confirmPassword) {
      toastr.error("confirm password doesnt match");
    } else {
      const { match } = this.props;
      const { token } = match.params;
      const data = { password, token };
      const req = await authReducer.updatePassword(data);
      if (req.success) this.props.history.push("/");
    }
  };

  onChangeInputField = (inputField) => {
    this.setState(inputField);
  };

  render() {
    const { passwordInputType, confirmPasswordInputType } = this.state;
    const freeTrial = this.props.location.pathname.includes("/register");
    const policy = this.props.location.pathname.includes("/privacy-policy");
    const leftBannnerSpan=freeTrial || policy ? 24 : 10;
    const bannerStyle = freeTrial || policy
        ? {
            width: "100%",
            backgroundColor: "#002e43",
            height: "99.9vh",
            backgroundImage: `url(${BackgroundWave})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            overflowY: "hidden",
           
        }
        : {
            width: "100%",
            backgroundColor: "#002e43",
            height: "100vh",
        };

    //Component to toggle password input field type
    const TogglePasswordIcon = () => {
      return (
        <div onClick={this.togglePasswordField} style={{ cursor: "pointer" }}>
          {passwordInputType === "password" ? (
            <>
              <EyeInvisibleOutlined />
            </>
          ) : (
            <>
              <EyeOutlined />
            </>
          )}
        </div>
      );
    };

    //Component to toggle confirm password input field type
    const ToggleConfirmPasswordIcon = () => {
      return (
        <div
          onClick={this.toggleConfirmPasswordField}
          style={{ cursor: "pointer" }}
        >
          {confirmPasswordInputType === "password" ? (
            <>
              <EyeInvisibleOutlined />
            </>
          ) : (
            <>
              <EyeOutlined />
            </>
          )}
        </div>
      );
    };

    return (
      <Row>
      {!(freeTrial || policy ) && <Col xs={24} sm={24} md={24} lg={14} xl={14} span={14} >
           <div style={bannerStyle}> <LeftBanner freeTrial={freeTrial} /></div>
       </Col>}
   <Col xs={24} sm={24} md={24} lg={leftBannnerSpan} xl={leftBannnerSpan}>
      <Content className="sign-container">
        <div
          className="flex-row"
          style={{
            justifyContent: "center",
            padding: "3em 0 3em 0",
          }}
        >
          <Image
            height={200}
            src={comfirmPasswordIllustration}
            preview={false}
          />
        </div>
        <div className="text-center">
          <h2>Please enter new password</h2>
        </div>
        <Form onValuesChange={this.onChangeInputField}>
          <Form.Item name="password">
            <Input
              type={passwordInputType}
              suffix={<TogglePasswordIcon />}
              placeholder="Type new password here"
            />
          </Form.Item>
          <Form.Item name="confirmPassword">
            <Input
              type={confirmPasswordInputType}
              suffix={<ToggleConfirmPasswordIcon />}
              placeholder="Confirm Password"
            />
          </Form.Item>
          <Button type={confirmPasswordInputType} onClick={this.submitPassword}>
            Update Password
          </Button>
        </Form>
      </Content>
      </Col>
    </Row>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    authReducer: authReducer.getActions(dispatch),
  })
)(UpdatePassword);
