import axios from "./index";

//route to fetch all batches
export async function fetch(params) {
  return axios
    .post("mixedLangContent/fetchPaginatedContent", params)
    .then((res) => res.data);
}

//analyze word count for translation request
export async function analyzeWordCount(params) {
  return await axios
    .post(`mixedLangContent/analyze/new/content`, params)
    .then((res) => res);
}
