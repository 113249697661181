import * as Notification from "../api/notification.js";
import ReducerFactory from "../../utils/reducerFactory";

const reducerName = "notification";

const initialState = {
  notifications: [],
  loading: false,
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(
  "NOTIFICATION_LOADING",
  `${reducerName}Loading`,
  (status) => status,
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_NOTIFICATION",
  "fetchNotification",
  async (pageNumber) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await Notification.fetchNotification(pageNumber);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.notifications = action.data.data.notifications;
      newState.total = action.data.data.total;
    } else {
      newState = Object.assign({}, state);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_DRAWER_NOTIFICATION",
  "fetchNotificationForDrawer",
  async (pageNumber) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await Notification.fetchNotification(pageNumber);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.AllNotifications = action.data.data.notifications;
      newState.total = action.data.data.total;
    } else {
      newState = Object.assign({}, state);
    }
    newState.loading = false;
    return newState;
  }
);

export default reducerFactory;
