import { Modal } from "antd";
import * as React from "react";
import FreeOrgAdminForm from "../../forms/FreeOrgAdminForm/FreeOrgAdminForm";
import "./LeftBanner.css";

import AnuvadakLogo from "../../../assets/images/anuvadak-login-logo.png"
import BengaliCharacter from "../../../assets/Icons/bengali.svg"
import OdiaCharacter from "../../../assets/Icons/odia.svg"
import TeluguCharacter from "../../../assets/Icons/telugu.svg"
import GujaratiCharacter from "../../../assets/Icons/gujarati.svg"
import HindiCharacter from "../../../assets/Icons/hindi.svg"
import MalayalamCharacter from "../../../assets/Icons/malayalam.svg"

const svgUrl = "https://avtstagecdn.blob.core.windows.net/static/svg";

const movingImages = () => {
  return (
    <>
      <img
        className={"position-absolute lang-svg-transition animated fadeIn"}
        style={{
          left: "5%",
          top: "14%",
        }}
        src={BengaliCharacter}
        alt="lang"
      />

      <img
        className={"position-absolute lang-svg-transition animated fadeIn"}
        style={{
          left: "30%",
        }}
        src={OdiaCharacter}
        alt="lang"
      />

      <img
        className={"position-absolute lang-svg-transition animated fadeIn"}
        style={{
          left: "15%",
          top: "90%",
        }}
        src={TeluguCharacter}
        alt="lang"
      />

      <img
        className={"position-absolute lang-svg-transition animated fadeIn"}
        style={{
          left: "55%",
          top: "4%",
        }}
        src={GujaratiCharacter}
        alt="lang"
      />

      <img
        className={"position-absolute lang-svg-transition animated fadeIn"}
        style={{
          left: "50%",
          top: "80%",
        }}
        src={HindiCharacter}
        alt="lang"
      />

      <img
        className={"position-absolute lang-svg-transition animated fadeIn"}
        style={{
          left: "80%",
          top: "70%",
        }}
        src={MalayalamCharacter}
        alt="lang"
      />
    </>
  );
};

const randomMovement = (event) => {
  const imageElements = Array.from(
    document.getElementsByClassName("lang-svg-transition")
  );
  const translateX = `${Math.random() * 100}px`;
  const translateY = `${Math.random() * 90}px`;
  const scale = 1 + Math.random() * 0.14;
  const mouseX = event.pageX;
  const mouseY = event.pageY;

  for (const image of imageElements) {
    const img = image;
    // calculate the image's center coordinates
    const imageCenterX = img.offsetLeft + img.offsetWidth / 2;
    const imageCenterY = img.offsetTop + img.offsetHeight / 2;
    // calculate the euclidean distance
    const distance = Math.sqrt(
      Math.pow(imageCenterX - mouseX, 2) + Math.pow(imageCenterY - mouseY, 2)
    );
    if (distance <= 120) {
      img.style.transform = `translate(${translateX},${translateY}) scale(${scale},${scale})`;
    }
  }
};

function LeftBanner(props) {
  const { freeTrial } = props;
  return (
    <div
      className="banner flex-column"
      onMouseMove={randomMovement}
    >
      {!freeTrial && movingImages()}
      <div
        className={"login-logo"}
        style={{
          display: "flex",
          justifyContent: freeTrial ? "center" : "flex-start",
        }}
      >
        <img src={AnuvadakLogo} height={30} id="login-logo" alt="anuvadak-logo" />
      </div>
      {freeTrial ? (
        <div
          className="flex-column tutu banner-text"
          style={{ width: "95vw" }}
        ></div>
      ) : (
        <div className="flex-column tutunot banner-text" style={{ zIndex: 10 }}>
          <div className="banner-heading">
            Accelerate website localization <span className="blinker">_</span>
          </div>
          <div className="banner-sub-heading">
            Launch multilingual website faster with minimal developer
            involvement Easily translate and manage translated content in one
            place
          </div>
        </div>
      )}
      {freeTrial && (
        <div style={{ borderRadius: "10%" }}>
          <Modal
            visible={true}
            footer={null}
            closable={false}
            mask={false}
            width={600}
            // height={700}
            className="free-trial-modal"
          // centered
          >
            <FreeOrgAdminForm />
          </Modal>
        </div>
      )}
    </div>
  );
}

export default LeftBanner;
