import React, { useReducer, useState } from "react";
import { Button, Checkbox, Form, Modal } from "antd";
import "./TermsAndConditions.css";
import userReducer from "../../../redux/modules/user";
import authReducer from "../../../redux/modules/auth";
import { connect } from "react-redux";
import toastr from "toastr";

const TermsAndConditions = (props) => {
  const { user, userReducer, authReducer, onClose, closable } = props;
  const [eulaChecked, setEulaChecked] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  return (
    <Modal
      open={modalOpen}
      centered
      width={900}
      footer={[]}
      onCancel={() => {
        onClose && onClose();
      }}
      closable={closable}
    >
      <div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          <span style={{ fontSize: "18px" }}>
            <strong>END-USER LICENSE AGREEMENT (EULA)</strong>
          </span>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "10px",
          }}
        >
          This End User License Agreement (<strong>"EULA"</strong>) is a legal
          agreement between you (<strong>“You”</strong> and/or{" "}
          <strong>“Your”</strong>) and Reverie Language Technologies Ltd. (
          <strong>“Reverie”</strong>) for Your use of the Company’s Software, (
          <strong>“Anuvadak”</strong>) and any related services (
          <strong>“Services”</strong>).
        </div>

        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "10px",
          }}
        >
          <strong>
            YOU AGREE TO THE TERMS OF THIS END USER LICENSE AGREEMENT BY
            DOWNLOADING, INSTALLING, COPYING, ACCESSING, OR USING THIS SOFTWARE.
            YOU REPRESENT AND WARRANT THAT YOU HAVE FULL AUTHORITY TO BIND THAT
            PERSON, COMPANY, OR LEGAL ENTITY TO THESE TERMS IF YOU ACCEPT THESE
            TERMS ON BEHALF OF ANOTHER PERSON, COMPANY, OR OTHER LEGAL ENTITY.
          </strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "10px",
          }}
        >
          <strong>IF YOU DO NOT AGREE TO THESE TERMS:</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "10px",
          }}
        >
          <strong>
            * DO NOT DOWNLOAD, INSTALL, COPY, ACCESS OR USE THIS SOFTWARE, AND
          </strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "10px",
          }}
        >
          <strong>
            * RETURN THIS SOFTWARE AND PROOF OF ENTITLEMENT TO THE PARTY FROM
            WHOM YOU OBTAINED THEM AS SOON AS POSSIBLE.
          </strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>1. DEFINITIONS</strong>
        </div>
        <ul>
          <li style={{ textAlign: "left", marginTop: "20px" }}>
            <strong>“Authorized Partner”</strong> refers to any of Reverie's
            distributors, resellers, or other business partners who have been
            given written authorization by Reverie to offer Support or Software
            licencing rights under this Agreement.
          </li>
          <li style={{ textAlign: "left", marginTop: "20px" }}>
            <strong>“Device”</strong> means the personal digital assistants,
            Windows/Mac on which the software will be installed and/or used.
          </li>
          <li style={{ textAlign: "left", marginTop: "20px" }}>
            <strong>“Documentation”</strong> refers to the printed, electronic,
            or online instructions that come with the Software in English and
            other languages, if they are available.
          </li>
          <li style={{ textAlign: "left", marginTop: "20px" }}>
            <strong>“Grant Letter”</strong> refers to a Reverie confirmation
            notification letter verifying the Software and Support you ordered.
          </li>
          <li style={{ textAlign: "left", marginTop: "20px" }}>
            <strong>“License period”</strong> means the period as more
            particularly described in this Agreement.
          </li>
          <li style={{ textAlign: "left", marginTop: "20px" }}>
            <strong>“Normal working hours”</strong> shall mean 09:30 a.m. to
            6:30 p.m. India Standard Time (IST) on working days and “Working
            Days” means Mondays to Fridays inclusive, but excluding bank and
            public holidays in India.
          </li>
          <li style={{ textAlign: "left", marginTop: "20px" }}>
            <strong>“Software”</strong> means Reverie software application in
            object code format that was licenced from Reverie and purchased from
            Reverie or its Authorised Partners.
          </li>
          <li style={{ textAlign: "left", marginTop: "20px" }}>
            <strong>“Support”</strong> means the Technical Assistance in order
            to use the software, provided by Reverie, which may or may not be
            chargeable to you. For more details, please refer to{" "}
            <a
              href="http://www.reverieinc.com"
              target="_blank"
              data-saferedirecturl="https://www.google.com/url?q=http://www.reverieinc.com&amp;source=gmail&amp;ust=1687254160714000&amp;usg=AOvVaw3jQPdO8dXvrkRsbG3HbQEZ"
            >
              www.reverieinc.com
            </a>
            .
          </li>
          <li style={{ textAlign: "left", marginTop: "20px" }}>
            <strong>“Updates”</strong> means collections of any or all virus
            definition files including detections and solutions for new viruses
            along with the corrections, improvements or modifications to the
            software.
          </li>
          <li style={{ textAlign: "left", marginTop: "20px" }}>
            <strong>“Upgrades”</strong> means any correction, improvement,
            modification or yearly enhancements in the form of the new version
            of the software, which Reverie generally releases.
          </li>
          <li style={{ textAlign: "left", marginTop: "20px" }}>
            <strong>“You”</strong> means the “End User” which means:* An
            individual (such an individual) installing/using/legally acquiring
            the right to use this software on his/her own behalf and every other
            person (whether authorized or not by such an individual) who
            uses/has the possession of the device on which the software is
            installed and activated; or * An organization (including but not
            limited to a partnership, joint venture, Limited Liability
            partnership, Limited Liability Company, Company of any nature,
            Trust, Governmental Authority, Unincorporated Organization,
            Association of Persons) or any person (such person) who have been
            authorized by such an organization to download, install, use the
            software, accept the Agreement on behalf of the organization and
            every other person (whether authorized or not by such person) who
            uses/has the possession of the Device on which the software is
            installed and activated.
          </li>
        </ul>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>2. License</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          * <strong>Term:</strong> If the Term is specified in the Grant Letter,
          the licence is valid for a certain amount of time (“Term”); otherwise,
          the licences are perpetual.
        </div>

        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <span>
            1. You have the right to use this programme from the time of
            purchase/license activation until the expiration of the licence
            period.
          </span>
          <br />
          <span>
            2. You understand, agree, and accept that for the duration of the
            licencing period; you are entitled to Internet updates and telephone
            technical assistance.
          </span>
          <br />
          <span>
            3. You accept and understand that any unauthorised use of this
            programme, as well as any breach of any or all of the Agreement's
            terms and conditions, will result in the automatic and immediate
            termination of this Agreement. Furthermore, Reverie and/or its
            representatives may take criminal and/or civil action against you,
            including but not limited to the right to block the key file/license
            key/product key without prior warning. In this case, you will not
            receive a refund.
          </span>
          <br />
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>3. License Grant; Proprietary Rights</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          1. Reverie hereby grants you a non-exclusive, non-transferable right
          to use the Software (for the purposes of this Agreement, to use the
          Software includes to download, install, and access the Software)
          listed in the Grant Letter solely for your own internal business
          operations, subject to the terms and conditions of this Agreement. If
          you haven't purchased Support, you won't be able to get Updates and
          Upgrades (or a service subscription granting rights to Updates and
          Upgrades).
          <br />
          2. Reverie maintains strict confidentiality over the Software,
          including its object code and source code, whether or not it is
          delivered to you. Except to the extent of the limited Software use
          licence granted to you in this Agreement, Reverie (or its licensors)
          owns and reserves all – and you may not exercise any – right, title,
          and interest in and to the Software, including, without limitation,
          all intellectual property rights in and to the Software. This is not a
          sale transaction, and you will not get any title, intellectual
          property rights, or ownership rights to the Software as a result of
          this Agreement.
          <br />
          3. You acknowledge and agree that the Software, including all ideas,
          methods, algorithms, formulae, processes, and concepts used in
          developing or incorporating the Software, all future Updates and
          Upgrades, and all other improvements, revisions, corrections,
          bug-fixes, hot-fixes, patches, modifications, enhancements, releases,
          upgrades, and policy and database updates and other updates in, of, or
          to the Software, all derivative works based upon any of the Software.
          <br />
          <strong>4. Copy and Use Terms</strong>
          <br />
          1. Copies - You may make as many copies of the Software as you need
          for back-up, archiving, or disaster recovery reasons.
          <br />
          2. Subsidiaries; Managing Parties: You may only allow a Subsidiary to
          use the Software in compliance with the provisions of this Agreement
          for as long as such organisation is your Subsidiary. You may also
          delegate management of your information technology resources to a
          third party with whom you have a contract{" "}
          <strong>("Managing Party")</strong>, provided that (i) the Managing
          Party only uses the Software for your internal operations and not for
          the benefit of another third party or the Managing Party, and (ii) the
          Managing Party agrees to abide by the terms and conditions of this
          Agreement.
          <br />
          3. General Restrictions: You may not, and you may not cause or permit
          any third party to: (i) decompile, disassemble, or reverse-engineer
          the Software; or create or recreate the Software's source code; (ii)
          remove, erase, obscure, or tamper with any copyright or other product
          identification or proprietary rights notices, seal, or instructional
          label printed or stamped on, affixed to, or encoded or recorded in or
          on any Software or Documentation; or fail to preserve (iii) sell,
          market, licence, sub-license, distribute, or otherwise grant to any
          person or entity any right to use the Software, except to the extent
          expressly permitted in this Agreement; or use the Software to provide,
          alone or in combination with any other product or service, any product
          or service to any person or entity, whether on a fee basis or
          otherwise; or use the Software to provide, alone or in combination
          with any other product or service, any product or service to any
          person or entity, whether on a fee basis or otherwise; (iv) integrate
          or merge any element of the Program or Documentation with or into any
          other software or documentation; change, adapt, tamper with,
          translate, or create derivative works of the Software or
          Documentation; or refer to or otherwise use the Software in any effort
          to develop software (including, without limitation, any routine,
          script, code, or programme) with functional attributes, visual
          expressions, or other features similar to those of the Software, or to
          compete with Reverie; (v) publish any performance or benchmark tests
          or analysis relating to the Software without Reverie's prior written
          permission; (vi) use the Software in any manner or for any purpose
          that violates this Agreement, any law or regulation, including but not
          limited to privacy rights and export laws, any right of Reverie or any
          third party, including but not limited to intellectual property
          rights; (vii) make an attempt to perform any of the preceding.
          <br />
          4. Responsibilities: You are solely and exclusively liable for: (i)
          All information and/ or data which you post, upload, transmit,
          process, disseminate or otherwise share with others by use of, or in
          connection with, the Software; (ii) informing authorized users of the
          contents of the Agreement (e.g., including provisions regarding
          processing of personal data); (iii) The results which you achieve by
          your use of the Software; (iv) Any loss, damage, or costs incurred due
          to incompatibility between the Software or updates to them and any
          third-party Software You install or otherwise use, and any other
          issues that may arise as a result of the interaction between the
          Software and third-party Software or similar products; (v) Any damage
          or liability to any party resulting from your use of the Software; and
          (vi) Any unauthorized use of the Software.
          <br />
          You are responsible for your Content within the subscribed services.
          You are legally responsible for all information, data, text, software,
          music, sound, photographs, graphics, video, messages or other
          materials <strong>("Content")</strong> uploaded, posted or stored
          through your use of the Software. You agree not to use the Software
          for any illegal purpose or in violation of any applicable local,
          state, federal or international law. You are responsible for any
          content that may be lost or unrecoverable through your use of the
          Software. You agree that you will not use the Software to share,
          store, or in any way distribute financial data that is not in
          accordance with the law. Any users suspected of having information
          which involves fraud, embezzlement, money laundering, insider trading,
          support for terrorism, or any other activity may lead to immediate
          termination of this Agreement. Reverie may also report to law
          enforcement officials in the appropriate jurisdictions. Reverie is not
          responsible for the content or data you provide through your use of
          the Software. You agree not to use the Software to upload, post,
          distribute, link to, publish, reproduce, engage in or transmit any of
          the following, including but not limited to: (i) Illegal, fraudulent,
          libellous, defamatory, obscene, pornographic, profane, threatening,
          abusive, hateful, harassing, offensive, inappropriate or objectionable
          information or communications of any kind. (ii) Virus, trojan horse,
          worm or other disruptive or harmful software or data; and (iii) Any
          information, software or content which is not yours legally and may be
          protected by copyright or other proprietary right, or derivative
          works, without permission from the copyright owner or intellectual
          property rights owner.
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>5. Technical Support and Maintenance</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          If you have bought Support, the Reverie Technical Support and
          Maintenance Terms and Conditions apply. The Reverie Technical Support
          and Maintenance Terms and Conditions are incorporated by reference and
          can be found at &lt;&lt;insert link&gt;&gt;.
          <br />
          You have no further rights to receive any Support, including Upgrades,
          Updates, and telephone support, once the support or service
          subscription period mentioned in a Grant Letter has elapsed. Reverie
          has the right to amend the Support provided at any moment, effective
          as of the start of any renewal period. You will obtain any and all
          privacy-related rights and permissions from individual persons as may
          be required by regulation, statute, or other law, or your internal
          policies or guidelines, in order to disclose to Reverie applicable
          personally identifiable information, data, and material in connection
          with Reverie's performance of Support or otherwise under this
          Agreement.
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>6. License Fee</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          The License fee for the Software will be decided solely by Reverie. We
          may terminate or suspend this Agreement due to lack of payment on your
          behalf, within 30 days of the date you have been invoiced. Reverie
          reserves the right, at its sole discretion to discontinue all support
          for the Software or subscribed services, and/or for any features,
          online or other services or content accessible through the respective
          licenses or Software in accordance with its current policy. Reverie
          reserves the right, in its sole discretion to make any changes to the
          Software, including adding or removing any features of the Software.
          Reverie has no obligation to update the Software/Product, and the
          Software may be changed without notice to you. Any License Fees
          referred to herein, including other charges, shall not be refundable
          under any circumstances, including but not limited to the termination
          of this <strong>EULA</strong> for whatever reason. Except as expressly
          provided herein, all payments under this <strong>EULA</strong> will be
          irrevocable, non-refundable, and non-creditable.
        </div>

        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>7. Indemnity</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          You shall unconditionally indemnify and hold harmless Reverie and its
          officers, contractors, directors, employees, agents, group companies
          and affiliates from and against any and all expenses, damages, claims,
          suits, actions, judgments and costs, whatsoever, including attorney’s
          fees, arising out of, or in any way connected with (a) breach of the
          terms of this <strong>EULA</strong> and the License granted hereunder;
          (b) breach of applicable laws in connection with this{" "}
          <strong>EULA</strong>; (c) Your infringement of any third parties’
          intellectual property rights; (e) Your use of the Software, including
          where such use is in a manner not authorized by this{" "}
          <strong>EULA</strong> or for any other unlawful &nbsp;purpose or in
          combination with any third party software; and (f) gross negligence,
          willful misconduct or fraud by You in the performance of Your
          obligations under this EULA.&nbsp;
        </div>

        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>8. Term and Termination</strong>
        </div>

        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          1. You affirm that You are fully able and competent to enter into the
          terms, conditions, obligations, affirmations, representations, and
          warranties set forth in this Agreement, and to abide by and comply
          with this Agreement.
          <br />
          2. This Agreement is effective until terminated by Reverie with or
          without cause, in its sole discretion. Reverie may terminate this
          Agreement without notice to you if you fail to comply with any of its
          terms. Any such termination by Reverie shall be in addition to and
          without prejudice to such rights and remedies as may be available to
          Reverie including injunctive and other equitable remedies.
          <br />
          3. The disclaimers, limitations on liability, ownership, termination,
          your warranty, and the indemnity provisions of this Agreement shall
          survive the termination or expiry of this Agreement.
          <br />
          4. On the termination of this Agreement, you must remove all Software
          from your systems and destroy any copies of the Software in your
          possession whether in electronic or printed format.
          <br />
          5. Any termination of this Agreement shall not affect Reverie’s rights
          to any payments due to it.
          <br />
          <br />
          <strong>9. Limited Warranty and Disclaimer</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          1. Limited Warranty: Reverie warrants that the Software licenced
          hereunder will work substantially in accordance with the Documentation
          for a period as provided along with the specific product from the
          purchase date.
          <br />
          2. Exclusive Remedy: In the event of a breach of Limited Warranty,
          Reverie will (i) repair or replace the Software, or (ii) if such
          repair or replacement would be commercially unreasonable in Reverie's
          opinion, refund the price paid by you for the applicable Software,
          upon Reverie's receipt of your written representation and promise that
          you have removed all instances of the Software and will not use the
          Software.
          <br />
          3. Exclusion of Warranty: THE ABOVE LIMITED WARRANTY IS VOID IF (i)
          THE SOFTWARE IS NOT USED IN ACCORDANCE WITH THIS AGREEMENT OR THE
          DOCUMENTATION, (ii) THE SOFTWARE OR ANY PART THEREOF HAS BEEN MODIFIED
          BY ANY ENTITY OTHER THAN REVERIE, OR (iii) A MALFUNCTION IN THE
          SOFTWARE HAS BEEN CAUSED BY ANY EQUIPMENT OR SOFTWARE NOT SUPPLIED BY
          REVERIE.
          <br />
          4. Disclaimer: EXCEPT FOR THE LIMITED WARRANTY SET FORTH ABOVE, THE
          SOFTWARE IS PROVIDED "AS IS," WITH REVERIE DISCLAIMINING ALL
          REPRESENTATIONS, WARRANTIES, AND CONDITIONS, ORAL OR WRITTEN, EXPRESS
          OR IMPLIED, ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE, OR
          USAGE IN TRADE, OR OTHERWISE, INCLUDING, WITHOUT LIMIT. WITHOUT
          LIMITING THE FOREGOING, REVERIE MAKES NO WARRANTY, REPRESENTATION, OR
          GUARANTEE AS TO THE SOFTWARE'S USE OR PERFORMANCE, AND DOES NOT
          WARRANT, REPRESENT, OR GUARANTEE THAT THE SOFTWARE WILL OPERATE
          FAIL-SAFELY, UNINTERRUPTEDLY, OR WITHOUT ERRORS OR DEFECTS, OR THAT
          THE SOFTWARE WILL PROTECT AGAINST ALL POSSIBLE THREATS.
          <br />
          5. High Risk System Terms: THE SOFTWARE COULD FAIL, AND IT WAS NOT
          DESIGNED, DEVELOPED, TESTED, OR INTENDED TO BE RELIABLE IN HIGH-RISK
          SYSTEMS. REVERIE HAS NO RESPONSIBILITY FOR, AND YOU WILL INDEMNIFY AND
          HOLD REVERIE HARMLESS FROM, ANY CLAIMS, SUITS, DEMANDS, OR PROCEEDINGS
          ALLEGING, CLAIMING, SEEKING, OR ASSERTING ANY LIABILITY, LOSS,
          OBLIGATION, RISK, COST, DAMAGE, AWARD, PENALTY, SETTLEMENT, JUDGMENT,
          FINE, OR EXPENSES (INCLUDING ATTORNEYS FEES) ARISING FROM OR IN
          CONNECTION WITH YOUR USE OF THE SOFTWARE ON OR IN A HIGH RISK SYSTEM,
          INCLUDING, WITHOUT LIMITATION, THOSE THAT (i) ARE BASED ON A CLAIM,
          ALLEGATION, OR ASSERTION THAT THE FUNCTIONING OF THE HIGH RISK SYSTEM
          DEPENDS OR DEPENDS ON THE FUNCTIONING OF THE SOFTWARE OR THAT THE
          FAILURE OF THE SOFTWARE CAUSED A HIGH RISK SYSTEM TO FAIL; (ii) ARE
          BASED ON A CLAIM, ALLEGATION, OR ASSERTION THAT THE FAILURE OF THE
          SOFTWARE CAUSED A HIGH RISK SYSTEM TO FAIL.
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>10. Limitation of Remedies and Damages</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          1. NEITHER PARTY SHALL BE LIABLE TO THE OTHER UNDER ANY CIRCUMSTANCES
          OR LEGAL THEORY, WHETHER IN TORT, NEGLIGENCE, CONTRACT OR OTHERWISE,
          FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY,
          CONSEQUENTIAL, OR EXTRA-CONTRACTUAL DAMAGES OF ANY KIND, LOSS OF
          GOODWILL, LOST PROFITS OR REVENUE, DAMAGES DUE TO WORK STOPPAGE AND/OR
          COMPUTER FAILURE OR MALFUNCTION AND/OR AND/OR COSTS OF PROCURING
          SUBSTITUTE SOFTWARE OR SERVICES,WHETHER OR NOT FORESEEABLE, EVEN IF
          THE EXCLUSIVE REMEDIES PROVIDED BY THIS AGREEMENT FAIL OF THEIR
          ESSENTIAL PURPOSE AND EVEN IF EITHER PARTY HAS BEEN ADVISED OF THE
          POSSIBILITY OR PROBABILITY OF SUCH DAMAGES.
          <br />
          2. EVEN IF THE CLAIM FOR SUCH DAMAGES IS BASED ON CONTRACT, TORT, OR
          ANY OTHER LEGAL THEORY, NEITHER PARTY'S AGGREGATE LIABILITY TO THE
          OTHER PARTY FOR DIRECT DAMAGES UNDER THIS AGREEMENT OR IN CONNECTION
          WITH ITS SUBJECT MATTER SHALL EXCEED THE AMOUNT OF TOTAL FEES PAID OR
          PAYABLE BY YOU FOR THE SOFTWARE.
          <br />
          3. NO TERM OF THIS AGREEMENT EXCLUDES OR LIMITS YOUR LIABILITY FOR
          EXCESSIVE USE OF THE SOFTWARE AND/OR ANY INFRINGEMENT OF REVERIE'S
          INTELLECTUAL PROPERTY RIGHTS IN THE SOFTWARE.
          <br />
          4. REVERIE’S LIABILITY FOR ANY CLAIM UNDER OR ARISING OUT OF THIS EULA
          OR IN RELATION TO THE SOFTWARE WILL NOT EXCEED THE LICENSE FEES YOU
          PAID FOR THE SOFTWARE GIVING RISE TO THE CLAIM.
          <br />
          5. IN THIS SECTION, THE LIMITATION OF LIABILITY IS BASED ON THE FACT
          THAT END USERS USE THEIR COMPUTERS FOR DIFFERENT PURPOSES. AS A
          RESULT, ONLY YOU CAN INSTALL BACK-UP PLANS AND SAFEGUARDS THAT ARE
          APPROPRIATE FOR YOUR NEEDS IN THE EVENT THAT A SOFTWARE ERROR CAUSES
          COMPUTER PROBLEMS AND RELATED DATA LOSSES. FOR THESE BUSINESS REASONS,
          YOU AGREE TO THE LIMITATIONS OF LIABILITY IN THIS SECTION AND
          ACKNOWLEDGE THAT THE FEE CHARGED FOR THE SOFTWARE WOULD BE HIGHER IF
          YOU DID NOT AGREE TO THIS PROVISION.
          <br />
          6. All responsibility or liability for any damages caused by the
          Software, including, without limitation, damages caused by computer
          viruses or other malicious code contained within the Software is
          disclaimed.
          <br />
          7. THE COMPANY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING
          OUT OF OR RELATING TO THE USE OR THE INABILITY TO USE THE SOFTAWARE,
          ITS CONTENT OR FUNCTIONALITY, INCLUDING BUT NOT LIMITED TO DAMAGES
          CAUSED BY OR RELATED TO ERRORS, OMISSIONS, INTERRUPTIONS, DEFECTS,
          DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, FAILURE TO
          CONNECT, NETWORK CHARGES, INCOMPATIBILITY OF HARDWARE RESULTING EITHER
          INDEPENDENTLY OR PURSUANT TO AN UPDATE, OR USE OF ANY HARDWARE OTHER
          THAN WHICH IS MADE AVAILABLE TO YOU BY THE COMPANY, AND ALL OTHER
          DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, OR CONSEQUENTIAL
          DAMAGES EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES.
          <br />
          <br />
          <br />
          8. YOU SHALL BE SOLELY RESPONSIBLE FOR OBTAINING AND MAINTAINING THE
          SOFTWARE (INCLUDING THE PAYMENT OF ANY ADDITIONAL FEES THEREFORE)
          REQUIRED TO CONNECT TO, ACCESS OR OTHERWISE USE THE SOFTWARE OR ANY OF
          THE RELATED SERVICES, INCLUDING, HARDWARE, SERVERS, PRODUCT, OPERATING
          SYSTEMS, NETWORKING, WEB SERVERS, INTERNET AND TELEPHONE SERVICE
          (COLLECTIVELY “EQUIPMENT”). YOU WILL ALSO BE RESPONSIBLE FOR
          MAINTAINING THE SECURITY OF THE EQUIPMENT AND FOR ALL USES OF THE
          EQUIPMENT WITH OR WITHOUT YOUR KNOWLEDGE OR CONSENT.
          <br />
          <br />
          <br />
          9. YOU ARE SOLELY RESPONSIBLE FOR THE CONTENT AND OTHER MATERIALS YOU
          PROVIDE THROUGH THE SOFTWARE OR SHARE WITH OTHER USERS OR RECIPIENTS.
          <br />
          <br />
          <br />
          10. YOU SPECIFICALLY AGREE THAT THE COMPANY SHALL NOT BE RESPONSIBLE
          FOR UNAUTHORISED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR
          DATA, ANY MATERIAL OR DATA SENT OR RECEIVED OR NOT SENT OR RECEIVED,
          OR ANY TRANSACTIONS ENTERED INTO THROUGH THE SOFTWARE. YOU
          SPECIFICALLY AGREE THAT THE COMPANY IS NOT RESPONSIBLE OR LIABLE FOR
          ANY THREATENING, DEFAMATORY, OBSCENE, OFFENSIVE OR ILLEGAL SERVICES OR
          CONDUCT OF ANY OTHER PARTY OR ANY INFRINGEMENT OF ANOTHER'S RIGHTS,
          INCLUDING INTELLECTUAL PROPERTY RIGHTS. YOU SPECIFICALLY AGREE THAT
          THE COMPANY IS NOT RESPONSIBLE FOR ANY SERVICES SENT USING AND/OR
          INCLUDED IN THE SOFTWARE BY ANY THIRD PARTY.
          <br />
          <br />
          <br />
          11. IN NO EVENT SHALL THE COMPANY AND/OR ITS AFFILIATES BE LIABLE FOR
          ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL
          DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION,
          DAMAGES FOR OR IN RELATION TO: (A) LOSS OF USE, DATA OR PROFITS
          ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF
          SOFTWARE AND/OR THE SERVICES; (B) ANY DELAY OR INABILITY TO USE THE
          SOFTWARE AND/OR SERVICES; (C) THE PROVISION OF OR FAILURE TO PROVIDE
          SERVICES, OR FOR ANY INFORMATION OR SERVICES OBTAINED THROUGH THE
          SOFTWARE AND/OR SERVICES; (D) OR OTHERWISE ARISING OUT OF THE USE OF
          THE SOFTWARE AND/OR SERVICES ; WHETHER OR NOT ALL OF THE ABOVE LOSSES,
          CLAIMS OR DAMAGES ARE BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
          LIABILITY, EVEN IF THE COMPANY AND/OR ITS AFFILIATES AND/OR ITS
          ASSOCIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSSES, CLAIMS
          OR DAMAGES.
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>11. Intellectual Property Rights&nbsp;</strong>
        </div>

        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          Title and ownership of the Software, Products, documentation and all
          patents, copyrights, trade secrets, and other worldwide proprietary
          and intellectual property rights in or related thereto are and will
          remain the exclusive property of Reverie. You shall promptly notify
          Reverie in writing, of any unauthorised use of the Software or related
          documentation or infringement of Reverie’s intellectual or proprietary
          rights. You agree to indemnify and defend Reverie against any third
          party claim arising out of your use of the Software.
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>12. Confidentiality:&nbsp;</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          You may have access to proprietary information and materials relating
          to Reverie’s business, technology, and/or products{" "}
          <strong>("Confidential Information")</strong>. The value of
          Confidential Information is enormous, and it may be jeopardised if it
          was revealed to other parties or used in violation of this Agreement.
          Except as authorised under this Agreement, You agree not to use such
          Confidential Information for your own account or the account of any
          third party, and will protect Confidential Information at least to the
          same extent as you protect your own Confidential Information and to
          the extent that a reasonable person would protect such Confidential
          Information.&nbsp;
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>13. Governing Laws and Jurisdiction</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          All disputes arising out of or relating to this Agreement or its
          subject matter will be governed by the substantive laws of India only
          with an exclusive jurisdiction of Bangalore courts.
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>14. REPRESENTATIONS AND WARRANTIES</strong>
          <br />
          <br />
          You represent and warrant to Reverie that:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. The Software
          provided under this <strong>EULA</strong> has been selected by You
          based on Your general conformance to the needs identified by you.
          &nbsp;
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. You have evaluated
          all the risks associated with the use and License of the Software and
          accept the entire risk associated with such use and License.
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. All data made
          available or provided by You to Reverie in connection with use of the
          Software and Services or otherwise during the Term of this{" "}
          <strong>EULA</strong>, is in strict compliance with all applicable
          data protection and privacy laws.
          <br />
          <br />
          <strong>15. NO TRANSFER</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          You shall not transfer this <strong>EULA</strong> or the rights
          granted herein to any third party unless it is in connection with the
          transfer of the right to use the Software and the Services as may be
          provided by the Company.
          <br />
          <br />
          <br />
          <strong>
            16. REVERIE’S RIGHT TO SUSPEND YOUR ACCESS TO THE SOFTWARE
          </strong>
          <br />
          <br />
          &nbsp;&nbsp;1. Reverie may suspend Your right to access or use any
          portion or all of the Software immediately upon notice to You if it
          determines that:
          <br />
          (a) Your use of the Software: (i) poses a security risk to the
          Company, the Software or any third party or; (ii) may subject the
          Company or its affiliates, or any third party to liability, or (iii)
          may be fraudulent;
          <br />
          (b) You are in breach of this <strong>EULA</strong>, including if you
          are delinquent on Your payment obligations as stipulated under this{" "}
          <strong>EULA</strong>; or
          <br />
          (c) You cease to operate in the ordinary course, have made an
          assignment for the benefit of creditors or similar disposition of
          assets, or become the subject of any bankruptcy, reorganization,
          liquidation, dissolution or similar proceeding.
          <br />
          <br />
          <br />
          &nbsp;&nbsp;2. Effect of suspension:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. If Reverie suspends Your right to
          access or use any portion or all of the Software in accordance with
          Clause 16.a above:
          <br />
          1. You shall remain responsible for all License Fees incurred through
          the date of suspension;
          <br />
          2. You shall remain responsible for all License Fees for any portion
          of the Software to which You continue to have access, and fees and
          charges for in-process tasks completed after the date of suspension;
          <br />
          3. Reverie’s right to suspend Your right to access or use the Software
          is in addition to Reverie’s right to terminate this{" "}
          <strong>EULA</strong> pursuant to Clause 8 of this{" "}
          <strong>EULA</strong>
          .<br />
          <br />
          <br />
          <strong>17. Dispute Resolution</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          Any claim or disputes arising out of or in connection with this{" "}
          <strong>EULA</strong>, shall be referred to and finally resolved by
          arbitration with a seat in Bangalore in accordance with the
          Arbitration and Conciliation Act, 1996. The language of the
          arbitration shall be English. Subject to the foregoing, the parties
          agree to submit to the jurisdiction of the courts of Bangalore, India.
          In the event that any of the provisions of this <strong>EULA</strong>{" "}
          shall be held by the court or other tribunals of competent
          jurisdiction to be unenforceable, the remaining portions hereof shall
          remain in full force and effect.
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          <strong>18. Miscellaneous</strong>
        </div>
        <div
          style={{
            fontFamily: "inherit",
            textAlign: "left",
            marginBottom: "20px",
          }}
        >
          * Any terms of this Agreement which by their nature should survive the
          termination of this Agreement shall survive such termination.
          <br />
          * Reverie may assign this Agreement in its entirety at any time with
          your consent in writing; however, any assignment resulting from or as
          part of a merger, consolidation, acquisition of all or substantially
          all of Reverie's assets, or internal restructuring or reorganisation
          does not require your consent.
          <br />
          * This Agreement, along with all documents included by reference,
          constitutes the parties' complete agreement and explicitly replaces
          and cancels any previous communication, representation, or
          advertisement, whether oral or written, on the subject matter hereof.
          If the terms and conditions of an order you place with an Authorised
          Partner or Reverie conflict with the terms and conditions of (i) this
          Agreement or (ii) the Grant Letter, the terms and conditions of this
          Agreement and the Grant Letter will take precedence.
          <br />
          * No aspect of this Agreement will be deemed waived unless it is in
          writing and signed by Reverie.
          <br />
          * If any provision of this Agreement is found to be invalid,
          unenforceable, invalid, or prohibited by law, that provision will be
          deemed restated to reflect the parties' original intent as closely as
          possible in accordance with applicable law, and the remaining
          provisions of this Agreement will remain in full force and effect.
          <br />
          * Except for normal operating communications, all notifications,
          requests, demands, and determinations for Reverie under this Agreement
          must be directed to the appropriate address as provided along with the
          specific Software.
          <br />
          * You agree and acknowledge that you have read and understood the
          terms of this Agreement prior to using the Software. This Agreement
          may be amended by Reverie, from time to time without specific notice
          to You. You agree to review these terms at a regular basis.
          <br />* Except as specified otherwise under this <strong>EULA</strong>
          , this Agreement is being entered on supplier basis and nothing herein
          shall be construed to create an agent-principal relationship, joint
          venture or partnership between You and Reverie.
          <br />
          * Reverie shall be under no liability whatsoever in the event of
          non-availability of any portion of the Software occasioned by any
          force majeure event including without limitation, any act of God, war,
          disease, pandemic, endemic, revolution, riot, civil commotion, strike,
          lockout, flood, fire, failure of any public utility, man-made
          disaster, infrastructure failure or any other cause whatsoever beyond
          the control of Reverie.
          <br />
          <br />
          <br />
          <strong>19. CONTACT DETAILS</strong>
          <br />
          <br />
          Should You have questions about the <strong>EULA</strong>, You may
          contact the Company at:
          <br />
          <br />
          Name: <strong>Reverie Languages Technologies Pvt Ltd.</strong>
          <br />
          Grievance Officer
          <br />
          Email:{" "}
          <strong>
            <a href="mailto:Delivery@reverieinc.com" target="_blank">
              Delivery@reverieinc.com
            </a>
          </strong>
          <br />
          <br />
          The Grievance Officer shall redress Your grievances expeditiously,
          within 1 (one) month from the date of receipt of the grievance. Except
          where required by law, Reverie cannot ensure a response to questions
          or comments regarding topics unrelated to the terms of the{" "}
          <strong>EULA.</strong>
        </div>

        <table
          role="module"
          border="0"
          cellpadding="0"
          cellspacing="0"
          width="100%"
          style={{ tableLayout: "fixed" }}
        >
          <tbody>
            <tr>
              <td
                style={{
                  padding: "15px 2px 18px 3px",
                  lineHeight: "20px",
                  textAlign: "inherit",
                }}
                height="100%"
                valign="top"
                bgcolor=""
                role="module-content"
              >
                <div>
                  <div style={{ fontFamily: "inherit", textalign: "center" }}>
                    <span>
                      Reverie Language Technologies, 5th floor, Avana Jio
                      Infocom, Iblur junction, bengaluru, 560103
                    </span>
                  </div>
                  <div></div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {user && user.apikey && !user.contractAgreed && (
          <div style={{ textAlign: "center", padding: "1em" }}>
            <Form className="flex-row" style={{ justifyContent: "center" }}>
              <Form.Item>
                <Checkbox
                  checked={eulaChecked}
                  onChange={(e) => setEulaChecked(e.target.checked)}
                >
                  I agree to the terms and conditions
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  disabled={!eulaChecked}
                  variant="contained"
                  onClick={() => {
                    user && user.apikey
                      ? userReducer

                          .editUser({
                            apikey: user.apikey,

                            updateObj: { contractAgreed: true },
                          })

                          .then((data) => {
                            if (data.success) {
                              setModalOpen(false);

                              authReducer.contractAgreed();
                            }
                          })
                      : toastr.warning("Something went wrong");
                  }}
                  size="small"
                  color="primary"
                >
                  Agree
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default connect(
  (state) => ({
    user: state.get("authentication").user,
  }),
  (dispatch) => ({
    userReducer: userReducer.getActions(dispatch),
    authReducer: authReducer.getActions(dispatch),
  })
)(TermsAndConditions);
