import axios from "./index";

export async function getPaginatedTmByLangAndUrl(formValues){
    return await axios.post(`/translation/dashboard/get`,formValues).then(res=>res.data);
}
export async function translationSEOFetch(formValues){
    return await axios.post(`/translation/seo/get`,formValues).then(res=>res.data);
}
export async function fetchTmCounts(formValues){
    return await axios.post(`/translation/counts`,formValues).then(res=>res.data);
}

export async function applyNmtTranslationToTm(formValues){
    const { projectId, targetLang, idArr, tp, nmtService, requestId } = formValues;
    return await axios.post(`/translation/nmt/apply`,{
        projectId,
        targetLang,
        idArr,
        tp,
        nmtService,
        requestId
    }).then(res=>res.data);
}

export async function applyTransliterationToTm(formValues){
    const { projectId, targetLang, idArr, tp, requestId } = formValues;
    return await axios.post(`/transliteration/applyTransliteration`,{
        projectId,
        targetLang,
        idArr,
    }).then(res=>res.data);
}

export async function applyUserTranslationToTm(formValues){
    const { projectId, targetLang, sourceHash, preProcessedTarget,index, displayType } = formValues;
    return await axios.post(`/translation/user/update`,{
        projectId,
        targetLang,
        sourceHash,
        preProcessedTarget,
        displayType
    }).then(res=>res.data)
    .then(res=>{
        res.index = index;
        return res;
    });
}

//fetch filtered TM
export async function fetchTmByFilter(filteredValues){
    return await axios.post(`/translation/filter`,filteredValues).then(res=>res.data);
}

//download report
export async function downloadReport(params){
    return await axios.post(`/report/download`,params).then(res=>res.data);
}

//analyze word count for translation request
export async function analyzeWordCount(params){
    return await axios.post(`/translation/analyze/new/content`, params).then(res=>res)
}

//Toggle display type of a TM
export async function toggleDisplayData(params){
    return await axios.post("translation/toggle/transliteration/type", params).then(res=>res)
}

// Create TM Revision
export async function createTmRevision(params){
    const { projectId, languages, sourceHash } = params;
    return await axios.post(`/translation/revision/create`,{
        projectId,
        languages,
        sourceHash
    }).then(res=>res.data)
}

//Fetch TM revision
export async function fetchTmRevision(params){
    return await axios.post("translation/revision/list", params).then(res=>res)
}

//Generate Report
export async function generateReport(params){
    return await axios.post("translation/generateReport", params).then(res=>res)
}

export async function fetchMetaTags(params) {
    return await axios.post("/meta/paginated/get",params).then(res=>res.data);
}
    
//Fetch TM revision
export async function downloadBatchFile(params){
    return await axios.post("translation/download/batchFile", params).then(res=>res.data)
}

//on language deletion
export async function languageDeletion(params){
    
    return await axios.post("translation/languageDeletion", params).then(res=>res.data)
}

export async function getstringxmlFileurls(params){
    console.log(params);

    const {projectName,apikey}=params;

     const config = {
      headers: {
        projectName:projectName,
        apikey:apikey
      },
    };
    
    return await axios.post("translation/stringxmlFileurls",params,config).then(res=>res.data)
}

export async function mobileTranslation(Data) {
    const { file, sourceLanguage ,languages,fileName,apikey,projectName}=Data;
    console.log("data",Data);
    const formData = new FormData();
    formData.append("file", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "projectType":"mob",
        "sourceLanguage":sourceLanguage,
        "targetLanguage":languages,
        "apikey":apikey,
        "projectName":projectName
      },
    };
    return axios.post("translation/xmlFileTranslation", formData, config)
                .then((res) => res.data)
                .catch((err)=> { 
                  return {"success":false}
                });;
}

export async function onMobileprojectResync(params){
    
    return await axios.post("translation/mobileTranslationResync", params).then(res=>res.data)
}

// replace text segment api
export async function replaceTextSegment(params){
    return await axios.post("translation/replace", params).then(res=>res.data).catch(err => err);
}
//getStringxmlFilePathByLanguage
export async function getStringxmlFilePathByLanguage(params){
    
    return await axios.post("translation/getStringxmlFilePathByLanguage", params).then(res=>res.data)
}

//mobile Language Addition

export async function mobileLanguageAddition(params){
    
    return await axios.post("translation/mobileLanguageAddition", params).then(res=>res.data)
}

// view Analysis Data

export async function viewAnalysisData(params){
    return await axios.post('translation/newContent/view',params).then(res=>res.data);
}

// fetch suggestion data
export async function fetchSuggestionData(params) {
    return await axios.post("suggestion/filter", params)
        .then(res=>res.data)
        .catch(err=> {
            return { success: false }
        });
}

// accept suggestion data
export async function acceptSuggestionData(params) {
    return await axios.post("suggestion/accept", params)
        .then(res=>res.data)
        .catch(err=> {
            return { success: false }
        });
}

// reject suggestion data
export async function rejectSuggestionData(params) {
    return await axios.post("suggestion/reject", params)
        .then(res=>res.data)
        .catch(err=> {
            return { success: false }
        });
}

export async function downloadSuggestionReport(params) {
    return await axios.post("suggestion/download", params)
        .then(res => res.data)
        .catch(err => {
            return { success: false }
        })
}

export async function uploadSuggestionReport(params) {
    return await axios.post("suggestion/upload", params)
        .then(res => res.data)
        .catch(err => {
            return { success: false }
        });
}

// fetch feedback data
export async function fetchFeedbackData(params) {
    return await axios.post("feedback/get", params)
        .then(res=>res.data)
        .catch(err=> {
            return { success: false }
        });
}

// fetch TOTAL feedback data
export async function fetchFeedbackDataForCsv(params) {
    return await axios.post("feedback/csv/get", params)
        .then(res=>res.data)
        .catch(err=> {
            return { success: false }
        });
}