import axios from "./index";

// export async function register(data) {
//   return axios.post('user-service/user', data);
// }

// export async function samlLogin() {
//   return axios.get('user-service/whoami');
// }

export async function login(data) {
  return axios.post("auth", data).then((res) => res.data);
}

export async function signUp(data) {
  return axios.post("auth/new", data).then((res) => res.data);
}
export async function verifyEmailId(data) {
  return axios.post("auth/verification", data).then((res) => res.data);
}

// export async function checkAuth() {
//   const authToken = window.localStorage.getItem('nVent_auth');
//   if (authToken !== null && authToken !== undefined) {
//     return axios.get('user-service/user');
//   }
//   return undefined;
// }

// export async function getUsers(data) {
//   return axios.get('user-service/users');
// }

// export async function changePassword(data) {
//   return axios.patch('user-service/user-pwd-reset', data);
// }

export async function updatePassword(data) {
  // axios.headers.authorization = `${data.uuid}`;
  let config={
    headers:{
      Authorization:`${data.token}`
    }
  }
  return axios.post("auth/update-password",{ password: data.password, token: data.token },config).then((res) => res.data);
}

export async function forgotPassword(email) {
  return axios.post("auth/password-reset", { email }).then((res) => res.data);
}

export async function verifyClientToken(){
  return axios.get(`/user/check/?timestamp=${new Date().getTime()}`).then(res=> res.data);
}

export async function fetchConfig(){
  return axios.get("/auth/fetchUIEndpoints").then(res=> res.data);
}

export async function userVerification(formData){
  return axios.post("auth/verification", formData).then(res=> res.data);
}

export async function checkProjectExists(formData){
  return axios.post("auth/checkProjectExists", formData).then(res=> res.data);
}

export async function verifyOtp(formData){
  return axios.post("auth/verifyOtp", formData).then(res=> res.data);
}

export async function resendOtp(formData){
  return axios.post("auth/resendOtp", formData).then(res=> res.data);
}

export async function sendOtp(formData){
  return axios.post("auth/sendOtp", formData).then(res=> res.data);
}

export async function onBoardFreeOrgAdmin(formData){
  return axios.post("auth/onboard/free-org", formData).then(res=> res.data);
}
