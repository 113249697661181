import * as AuthApi from "../api/auth";
import toastr from "toastr";
import ReducerFactory from "../../utils/reducerFactory";
import * as FileTranslationApi from "../api/fileTranslation";

const reducerName = "fileTranslation";
const initialState = {
  loading: true,
  filesList: [],
  categoriesList: [],
  docFileList: [],
  docCategoriesList: [],
  jsonDataList: [],
  lookUpDataCount: 0,
  apiDataList: [],
  responseData: [],
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(
  "AUTH_LOADING",
  `${reducerName}Loading`,
  (status) => status,
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.loading = action.data;
    return newState;
  }
);

//getFilesList

reducerFactory.addAction(
  "GET_FILES_LIST",
  "getFilesList",
  async (params) => {
    const response = await FileTranslationApi.getFilesList(params);
    return response;
  },
  (state, action) => {
    let newState;
    console.log(action.data);
    newState = Object.assign({}, state);
    if (action?.data?.success) {
      console.log("ppp");
      newState.filesList = action.data.data.filesList;
      newState.categoriesList = action.data.data.categoriesList;
    }
    return newState;
  }
);

//getProcessedFilesList

reducerFactory.addAction(
  "GET_PROCESSED_FILES_LIST",
  "getProcessedFilesList",
  async (params) => {
    const response = await FileTranslationApi.getProcessedFilesList(params);
    return response;
  },
  (state, action) => {
    let newState;
    console.log(action.data);
    newState = Object.assign({}, state);
    if (action?.data?.success) {
      console.log("processed files");
      newState.processedFile = action.data.data.processedFile;
    }
    return newState;
  }
);

reducerFactory.addAction(
  "SEND_FILE_TO_PRABANDHAK",
  "sendFileToPrabandhak",
  async (params) => {
    const response = await FileTranslationApi.automateNmtTranslation(params);
    return response;
  },
  (state, action) => {
    let newState;
    console.log(action);
    newState = Object.assign({}, state);
    if (action?.data?.success) {
      toastr.success("Project successfully created on Prabandhak.");
      toastr.info("Please try webhook after some time");
      console.log("prabandhak files");
      // newState.prabadn = action.data.data.prabadn;
    } else {
      toastr.error(action.data.message);
    }
    return newState;
  }
);

reducerFactory.addAction(
  "SEND_WEBHOOK",
  "sendWebhook",
  async (params) => {
    const response = await FileTranslationApi.sendWebhook(params);
    return response;
  },
  (state, action) => {
    let newState;
    console.log(action);
    newState = Object.assign({}, state);
    if (action?.data?.success) {
      console.log("webhook call");
      toastr.success("Files fetched successfully.");
      // newState.prabadn = action.data.data.prabadn;
    } else {
      console.log("Error while calling webhook");
      console.log(action?.data);
      toastr.info(
        "Encountered some error while calling webhook, Please try again after some time."
      );
    }
    return newState;
  }
);
reducerFactory.addAction(
  "UPLOAD_DOC_FILE",
  "uploadDocfile",
  async (formData) => {
    toastr.info("Please Wait ,File Upload will take couple of minutes ");
    const response = await FileTranslationApi.uploadDocfile(formData);
    return response;
  },
  (state, action) => {
    let newState;
    console.log(action);
    newState = Object.assign({}, state);
    if (action?.data?.success) {
      toastr.success("File Uploded Sucessfully");
      console.log("File Uploaded Sucessfully");
      // newState.prabadn = action.data.data.prabadn;
    } else {
      toastr.error(action.data.message);
    }
    return newState;
  }
);

//fetch Doc Files List
reducerFactory.addAction(
  "GET_DOC_FILES_LIST",
  "getDocFilesList",
  async (params) => {
    const response = await FileTranslationApi.getDocFilesList(params);
    return response;
  },
  (state, action) => {
    let newState;
    console.log(action.data);
    newState = Object.assign({}, state);
    if (action?.data?.success) {
      console.log(action.data.data.docsList);
      newState.docFileList = action.data.data.docsList;
    }
    return newState;
  }
);

//fetch Doc Categoies  List
reducerFactory.addAction(
  "GET_DOC_CATEGORIES_LIST",
  "getDocCategoriesList",
  async (params) => {
    const response = await FileTranslationApi.getDocCategoriesList(params);
    return response;
  },
  (state, action) => {
    let newState;
    console.log(action.data);
    newState = Object.assign({}, state);
    if (action?.data?.success) {
      console.log(action.data.data.categoriesList);
      newState.docCategoriesList = action.data.data.categoriesList;
    }
    return newState;
  }
);

//send Doc File to PBK
reducerFactory.addAction(
  "SEND_DOC_FILE_TO_PRABANDHAK",
  "sendDocFileToPrabandhak",
  async (params) => {
    const response = await FileTranslationApi.sendDocFileToPrabandhak(params);
    return response;
  },
  (state, action) => {
    let newState;
    console.log(action.data);
    newState = Object.assign({}, state);
    // if (action?.data?.success) {
    //   console.log(action.data.data.categoriesList);
    //   newState.docCategoriesList = action.data.data.categoriesList;
    // }
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_TRANSLATED_FILE",
  "fetchTranslatedFile",
  async (params) => {
    const response = await FileTranslationApi.fetchTranslatedFile(params);
    return response;
  },
  (state, action) => {
    let newState;
    console.log(action.data);
    newState = Object.assign({}, state);
    // if (action?.data?.success) {
    //   console.log(action.data.data.categoriesList);
    //   newState.docCategoriesList = action.data.data.categoriesList;
    // }
    return newState;
  }
);

reducerFactory.addAction(
  "UPLOAD_LANGUAGE_FILE",
  "uploadLanguageFile",
  async (params) => {
    const response = await FileTranslationApi.uploadLanguageFile(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action.data[0].success) {
      toastr.success("File Updated Successfully!");
    } else {
      console.log(action.data);
      toastr.error("File Updation Failed");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "MAP_TRANSLATED_DOC_FILE",
  "mapTranslatedDocFile",
  async (params) => {
    const response = await FileTranslationApi.mapTranslatedDocFile(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    console.log(action);
    if (action.data.success) {
      toastr.success("File Updated Successfully!");
    } else {
      console.log(action.data);
    }
    return newState;
  }
);

reducerFactory.addAction(
  "EDIT_FILE_TRANSLATION_DETAILS",
  "editFileTranslationDetails",
  async (params) => {
    const response = await FileTranslationApi.editFileTranslationDetails(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Details updated successfully");
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    return newState;
  }
);
reducerFactory.addAction(
  "DELETE_FILE_TRANSLATION_REQUEST",
  "deleteFileTranslationRequest",
  async (params) => {
    const response = await FileTranslationApi.deleteFileTranslationRequest(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("File Request Deleted successfully");
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "EXPORT_FILES",
  "exportFiles",
  async (params) => {
    const response = await FileTranslationApi.exportFiles(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message);
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    return newState;
  }
);

reducerFactory.addAction(
  "GET_STATIC_TRANSLATION_DATA_LIST",
  "getStaticTranslationDataList",
  async (params) => {
    const response = await FileTranslationApi.getStaticTranslationDataList(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    console.log(action.data);
    if (action.data.success) {
      console.log(action.data.data.lookUpDataList);
      newState.jsonDataList = action.data.data.lookUpDataList;
      newState.lookUpDataCount = action.data.data.lookUpDataCount;
      // toastr.success("JSON Data fetched Sucessfully");
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    return newState;
  }
);
reducerFactory.addAction(
  "GET_DYNAMIC_TRANSLATION_DATA_LIST",
  "getDynamicTranslationDataList",
  async (params) => {
    const response = await FileTranslationApi.getDynamicTranslationDataList(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    console.log(action.data);
    if (action.data.success) {
      console.log(action.data.data.lookUpDataList);
      newState.jsonDataList = action.data.data.lookUpDataList;
      newState.lookUpDataCount = action.data.data.lookUpDataCount;
      // toastr.success("JSON Data fetched Sucessfully");
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    return newState;
  }
);
//getApiList
// reducerFactory.addAction(
//   "GET_API_LIST",
//   "getApiList",
//   async (params) => {
//     const response = await FileTranslationApi.getApiList(params);
//     return response;
//   },
//   (state, action) => {
//     let newState = Object.assign({}, state);
//     console.log(action.data);
//     if (action.data.success) {
//       newState.apiDataList = action.data.data.apiDataList;
//       // toastr.success("JSON Data fetched Sucessfully");
//     } else {
//       toastr.warning(action.data.message || "Some error occured");
//     }
//     return newState;
//   }
// );
reducerFactory.addAction(
  "APPLY_STATIC_CONTENT_EDIT_BY_USER",
  "applyStaticContentEditByUser",
  async (params) => {
    const response = await FileTranslationApi.applyStaticContentEditByUser(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    console.log(action.data);
    if (action.data.success) {
      // newState.apiDataList = action.data.data.apiDataList;
      toastr.success("Content Updated Sucessfully");
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    return newState;
  }
);
reducerFactory.addAction(
  "DYNAMIC_CONTENT_TRANSLATION",
  "dynamicContentTransaltion",
  async (params) => {
    const response = await FileTranslationApi.dynamicContentTransaltion(
      params
    );
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    console.log(action.data);
    if (action.data.success) {
      newState.responseData = action.data.data.translatedData;
      toastr.success("File Taranslated and fetched the response successfully");
    } else {
      toastr.warning(action.data.message || "Some error occured");
    }
    return newState;
  }
);

export default reducerFactory;
