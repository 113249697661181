import * as TranslationAPI from '../api/manualTranslation';
import toastr from 'toastr';
import ReducerFactory from '../../utils/reducerFactory';

/* eslint-disable no-param-reassign */

const reducerName = 'manualTranslation';
const initialState = {
  loading: false,
  total:0,
  requests:[],
  request:null,
  subBatches:[],
  batchIdList:[]
};
const reducerFactory = new ReducerFactory(reducerName, initialState);
reducerFactory.addAction('MANUAL_TRANSLATION_LOADING', `${reducerName}Loading`,
  (status) => status, (state, action) => {
    const newState = Object.assign({}, state);
    newState.loading = action.data;
    return newState;
  }
);


reducerFactory.addAction("SEND_MANUAL_TRANSLATION_REQUEST", "sendManualTranslationRequest",
  async (body) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationAPI.sendManualTranslationRequest(body);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      toastr.success(action.data.message);
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading= false;
    return newState;
  }
);

reducerFactory.addAction('GET_MANUAL_TRANSLATION_REQUEST', 'getManualTranslationRequest',
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationAPI.getManualTranslationRequest(formValues);
    return response;
  }, (state, action) => {
    let newState;
    if (action.data.success) {
      // window.localStorage.setItem('nVent_auth', action.data.data.auth);
      newState = Object.assign({}, state);
      newState.requests = action.data.data.requests;
      newState.total = action.data.data.total;
    } else {
      newState = Object.assign({}, state);
      newState.error = action.data.message.result;
      toastr.warning(action.data.message.result);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('GET_MANUAL_TRANSLATION_REQUEST_BY_PROJECT_ID', 'getManualTranslationRequestByProjectId',
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationAPI.getManualTranslationRequestByProjectId(formValues);
    return response;
  }, (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      newState.requests = action.data.data.requests;
      newState.total = action.data.data.total;
    } else {
      newState.error = action.data.message.result;
      toastr.warning(action.data.message.result);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('GET_REQUEST_INFO', 'getRequestInfo',
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationAPI.getRequestInfo(formValues);
    return response;
  }, (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      newState.request = action.data.data;
    } else {
      newState.error = action.data.message;
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('APPROVE_REQUEST', 'approveRequest',
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationAPI.approveRequest(formValues);
    return response;
  }, (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message);
    } else {
      newState.error = action.data.message;
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);


reducerFactory.addAction(
  "DELETE",
  "delete",
  async (requestId) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationAPI.deleteRequest(requestId);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message);
    } else {
      newState.error = action.data.message;
      toastr.warning(action.data.message);
    }
    return newState;
  }
);

reducerFactory.addAction('REJECT_REQUEST', 'rejectRequest',
  async (requestId) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationAPI.rejectRequest(requestId);
    return response;
  }, (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message);
    } else {
      newState.error = action.data.message;
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('UPLOAD_BATCH_FILE', 'uploadBatchFile',
  async (formData) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationAPI.uploadBatchFile(formData);
    return response;
  }, (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message);
    } else {
      newState.error = action.data.message;
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('FETCH_SUB_BATCHES', 'fetchSubBatches',
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await TranslationAPI.fetchSubBatches(formValues);
    return response;
  }, (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.subBatches = action.data.data.batches;
      newState.batchIdList = action.data.data.batchIdList
    } else {
      newState = Object.assign({}, state);
      newState.error = action.data.message.result;
      toastr.warning(action.data.message.result);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction("REVERT_MANUAL_TRANSLATION","revertManualTranslation",
  async(params)=>{
    reducerFactory.action(`${reducerName}Loading`, true);
    const response=await TranslationAPI.revertManualTranslation(params);
    return response
  },(state,action)=>{
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      toastr.success("Data reverted successfully");
    } else {
      newState = Object.assign({}, state);
      // newState.error = action.data.message.result;
      toastr.warning('Error while reverting');
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction("API_TRANSLATION_MANUAL_REQUEST","apiTranslationManualRequest",
    async(params)=>{
      reducerFactory.action(`${reducerName}Loading`, true);
      toastr.info("Please wait, Project creation will take couple of minutes");
      const response=await TranslationAPI.apiTranslationManualRequest(params);
      return response
    },(state,action)=>{
      let newState = Object.assign({}, state);
      if (action.data.success) {
           toastr.success("SUCCESS","Prabandhak project created successfully");
      } else {
        toastr.warning("ERROR","Error while sending manual translation request")
      }
      newState.loading = false;
      return newState;
}
)


export default reducerFactory;
