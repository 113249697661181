import * as ProjectApi from "../api/project";
import toastr from "toastr";
import ReducerFactory from "../../utils/reducerFactory";
import configUI from "../../appConfig";

const reducerName = "project";
const initialState = {
  loading: false,
  totalProjects: 0,
  projects: [],
  project: null,
  totalUrlsCount: 0,
  urlList: [],
  allUrlList: [],
  fontSizeList: [],
  noTranslationList: [],
  swalekhIntegrationNodesList:[],
  totalFontCount: 0,
  totalNoTranslationCount: 0,
  totalSwalekhIntegrationCount: 0,
  sourceWordCount: 0,
  uniqueWordCount:0,
  newContentWordCount: 0,
  machineTranslatedWordCount: 0,
  userTranslatedWordCount: 0,
  pbkTranslatedWordCount: 0,
  newContentCount: 0,
  machineTranslatedCount: 0,
  userTranslatedCount: 0,
  pbkTranslatedCount: 0,
  freeOrgProjectId:"",
  domains:[],
  canonical: false,
  glossarytransliterationData:[],
  glossarytransliterationDataCount:0
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction(
  "PROJECT_LOADING",
  `${reducerName}Loading`,
  (status) => status,
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction(
  "CREATE",
  "create",
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.create(formValues);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      toastr.success(action.data.message);
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "NEW_PROJECT_MULTIORIGIN",
  "newProjectMultiOrigin",
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.newProjectMultiOrigin(formValues);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      toastr.success(action.data.message);
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH",
  "fetch",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.fetch(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.projects = action.data.data.projectList;
      newState.totalProjects = action.data.data.totalProjects;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

// add scope to project
reducerFactory.addAction( "SCOPE", "scope",
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.scope(formValues);
    return response;
  },
  (state, action) => {
    let newState;
    
    if (action.data.success) {
      newState = Object.assign({}, state);
      toastr.success(action.data.message);
      toastr.warning("Wordcount will be updated shortly. Please check back after some time");
    } else {
      newState = Object.assign({}, state);   
     
      // toastr.warning(action.message);
    }
    newState.loading = false;
    return newState;
  }
);

//fetch project details from projectId
reducerFactory.addAction(
  "FETCH_PROJECT_DETAILS", "fetchProjectDetails",
  async (projectId) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.fetchProjectDetails(projectId);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.project = action.data.data;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

//fetch url list from projectId
reducerFactory.addAction(
  "FETCH_URL_LIST",
  "fetchUrlList",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.fetchUrlList(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.urlList = action.data.data.urlList;
      newState.totalUrlsCount = action.data.data.total;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

//fetch all url list from projectId
reducerFactory.addAction(
  "FETCH_ALL_URL_LIST",
  "fetchAllUrlList",
  async (projectId) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.fetchAllUrlList(projectId);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.allUrlList = action.data.data.allUrlList;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

// delete project action
reducerFactory.addAction(
  "DELETE_PROJECT",
  "deleteProject",
  async (params) => {
    toastr.info(
      "Project delete request sent, Project will be deleted in a moment",
      "Project Delete"
    );
    const response = await ProjectApi.deleteProject(params);
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.success("Project deleted successfully", "Project Delete");
    } else {
      toastr.error("Project deletion request failed", "Project Delete");
    }
    return state;
  }
);

// update project action
reducerFactory.addAction(
  "UPDATE_PROJECT",
  "updateProject",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.updateProject(params);
    return response;
  },
  (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      newState.project = { ...newState.project, ...action.data.data }
      toastr.success("Project updated successfully");
    } else {
      toastr.error("Project updation request failed", "Project Update");
    }
    newState.loading = false;
    return newState;
  }
);

// network call to get project report
reducerFactory.addAction(
  "FETCH_PROJECT_REPORT",
  "fetchProjectReport",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.fetchProjectReport(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.sourceWordCount = action.data.data.wordCount.sourceWordCount;
      newState.uniqueWordCount = action.data.data.wordCount.uniqueWordCount;
      newState.newContentWordCount =
        action.data.data.wordCount.newContentWordCount;
      newState.machineTranslatedWordCount =
        action.data.data.wordCount.translationWordCount.MACHINE;
      newState.userTranslatedWordCount =
        action.data.data.wordCount.translationWordCount.USER;
      newState.pbkTranslatedWordCount =
        action.data.data.wordCount.translationWordCount.PBK;
      newState.translatedWordCount = action.data.data.wordCount.translatedWordCount;
      newState.transliteratedWordCount = action.data.data.wordCount.transliteratedWordCount;
      } else {
      toastr.error(
        "Some Error Occured while fetching project report",
        "Project Report"
      );
    }
    newState.loading = false;
    return newState;
  }
);

// set font size
reducerFactory.addAction(
  "SET_FONT_SIZE",
  "setFontSize",
  async (params) => {
    const response = await ProjectApi.setFontSize(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Font size set successfully");
    } else {
      toastr.error(action.data.message);
    }
    return newState;
  }
);

// create ignore pattern
reducerFactory.addAction(
  "SET_IGNORE_PATTERN",
  "setIgnorePattern",
  async (params) => {
    const response = await ProjectApi.setIgnorePattern(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Ignore pattern created successfully");
    } else {
      toastr.error("Some error occurred. Please try again");
    }
    return newState;
  }
);

// set no translation
reducerFactory.addAction(
  "SET_NO_TRANSLATION",
  "setNoTranslation",
  async (params) => {
    const response = await ProjectApi.setNoTranslation(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("No translation set successfully");
    } else {
      toastr.error(action.data.message);
    }
    return newState;
  }
);

reducerFactory.addAction(
  "SET_SWALEKH_INTEGRATION_DATA",
  "setSwalekhIntegrationData",
  async (params) => {
    const response = await ProjectApi.setSwalekhIntegrationData(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Class added successfully");
    } else {
      toastr.error(action.data.message);
    }
    return newState;
  }
);

// fetch font size list
reducerFactory.addAction(
  "FETCH_FONT_SIZE",
  "fetchFontSizeList",
  async (params) => {
    const response = await ProjectApi.fetchFontSizeList(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.fontSizeList = action.data.data.customFontSize;
      newState.totalFontCount = action.data.data.totalCount;
      // toastr.success("font size list fetched successfully");
    } else {
      toastr.error("Some error occurred. Please try again");
    }
    return newState;
  }
);

// fetch no translation list
reducerFactory.addAction(
  "FETCH_NO_TRANSLATION",
  "fetchNoTranslationList",
  async (params) => {
    const response = await ProjectApi.fetchNoTranslationList(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.noTranslationList = action.data.data.noTranslationNodes;
      newState.totalNoTranslationCount = action.data.data.totalCount;
      // toastr.success("no translation list fetched successfully");
    } else {
      toastr.error("Some error occurred. Please try again");
    }
    return newState;
  }
);

// fetch Swalekh Integration list
reducerFactory.addAction(
  "FETCH_SWALEKH_INTEGRATION_LIST",
  "fetchSwalekhIntegrationist",
  async (params) => {
    const response = await ProjectApi.fetchSwalekhIntegrationist(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.swalekhIntegrationNodesList = action.data.data.swalekhIntegrationNodes;
      newState.totalSwalekhIntegrationCount = action.data.data.totalCount;
      // toastr.success("no translation list fetched successfully");
    } else {
      toastr.error("Some error occurred. Please try again");
    }
    return newState;
  }
);

// fetch ignore pattern list
reducerFactory.addAction(
  "FETCH_IGNORE_PATTERN",
  "fetchIgnorePatternList",
  async (params) => {
    const response = await ProjectApi.fetchIgnorePatternList(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.ignorePatternList = action.data.data.patterns;
      newState.totalIgnorePatternCount = action.data.data.totalCount;
      // toastr.success("ignore pattern list fetched successfully");
    } else {
      toastr.error("Some error occurred. Please try again");
    }
    return newState;
  }
);

// delete font size element
reducerFactory.addAction(
  "DELETE_FONT_SIZE",
  "deleteCustomFontElement",
  async (id) => {
    const response = await ProjectApi.deleteCustomFontElement({ id });
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("font size deleted successfully");
    } else {
      toastr.error("Some error occurred. Please try again");
    }
    return newState;
  }
);

// delete font size element
reducerFactory.addAction(
  "DELETE_NO_TRANSLATION",
  "deleteNoTranslationElement",
  async (id) => {
    const response = await ProjectApi.deleteNoTranslationElement({ id });
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("No translation node deleted successfully");
    } else {
      toastr.error("Some error occurred. Please try again");
    }
    return newState;
  }
);

// delete fswalekh Integration Element
reducerFactory.addAction(
  "DELETE_SWALEKH_INTEGRATION_NODE",
  "deleteSwalekhIntegrationElement",
  async (id) => {
    const response = await ProjectApi.deleteSwalekhIntegrationElement({ id });
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("No translation node deleted successfully");
    } else {
      toastr.error("Some error occurred. Please try again");
    }
    return newState;
  }
);

// delete ignore pattern element
reducerFactory.addAction(
  "DELETE_IGNORE_PATTERN",
  "deleteIgnorePatternElement",
  async (id) => {
    const response = await ProjectApi.deleteIgnorePatternElement({ id });
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Ignore pattern node deleted successfully");
    } else {
      toastr.error("Some error occurred. Please try again");
    }
    return newState;
  }
);
reducerFactory.addAction(
  "SET_TRANSLITERATION_DATA",
  "setTransliterationData",
  async (params) => {
    // reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.setTransliterationData(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    console.log(action.data)
    if (action.data.success) {
      toastr.success("Transliteration set successfully");
    } else {
      toastr.error("“Please upload a correct format");
    }
    // newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "CHANGE_CACHE_CONFIG",
  "changeCacheConfig",
  async ({key,status,projectId}) => {
    const response = await ProjectApi.toggleCacheConfigStatus(key,status,projectId);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({},state);
    if (action.data.success) {
      const newData = action.data.data.updatedDocument;
      newState.project = {...newState.project,...newData};
    } else {
      toastr.error("Some error occurred. Please try again");
    }

    return newState;
  }
)

reducerFactory.addAction(
  "CHANGE_META_TAGS",
  "changeMetaTags",
  async ({key,status,projectId}) => {
    const response = await ProjectApi.toggleMetaTagsStatus(key,status,projectId);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({},state);
    if (action.data.success) {
      const newData = action.data.data.updatedDocument;
      newState.project = {...newState.project,...newData};
    } else {
      toastr.error("Some error occurred. Please try again");
    }

    return newState;
  }
)

// delete a transliteration
reducerFactory.addAction(
  "DELETE_TRANSLITERATION",
  "deleteTransliteration",
  async (params) => {
    const response = await ProjectApi.deleteTransliteration(params);
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.success("TM deleted successfully", "TM Delete");
    } else {
      toastr.error("TM deletion request failed", "TM Delete");
    }
    return state;
  }
);

// fetch transliteration data
reducerFactory.addAction(
  "GET_TRANSLITERATION_DATA",
  "getTransliterationData",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.getTransliterationData(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.transliterationData = action.data.data.data;
      newState.transliterationDataCount = action.data.data.totalCount;
      // toastr.success("Transliteration data fetched successfully");
    } else {
      toastr.error("Some error occurred. Please try again");
    }
    newState.loading = false;
    return newState;
  }
);

// fetch project id for free org admin
reducerFactory.addAction(
  "FETCH_PROJECT_ID",
  "fetchProjectId",
  async (params) => {
    const response = await ProjectApi.fetchProjectId(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.freeOrgProjectId = action.data.data.projectId;
      newState.project = action.data.data.project;
    } 
    newState.loading = false;
    return newState;
  }
);
reducerFactory.addAction(
  "CACHE_CLEAR_CALL",
  "clearCacheCall",
  async () => {
    const response = await ProjectApi.clearCacheCall();
    return response;
  },
  (state, action) => {
    if (action.data.success) {
      toastr.success("Cache cleared successfully");
    } else {
      toastr.info("There was a problem while clearing cache");
    }
    return state;
  }
);

reducerFactory.addAction(
  "FETCH_LAST_PROCESSED_URL",
  "getLastProcessedUrl",
    async (params)=>{
        const response = await ProjectApi.getLastProcessedUrl(params);
        return response;
    },
    (state,action)=>{
        const newState = Object.assign({},state);
        if (action.data.success) {
          newState.project = {
            ...newState.project,
            lastProcessedUrl: action.data.data.url,
            parsedUrls: action.data.data.parsedUrls,
            status: action.data.data.status
          };
        } else {
            newState.lastProcessedUrl = newState.project.baseUrl;
        }
        return newState;
    }
);

reducerFactory.addAction(
  "RESTART_CRAWLING",
  "restartCrawling",
  async (params)=>{
    const response = await ProjectApi.restartCrawling(params);
    return response;
  },
  (state,action)=>{
    const newState = Object.assign({},state);
    if (action.data.success) {
      newState.project.status = configUI.projectCreationStatusMap.CRAWLING;
      toastr.success("crawling request has been accepted")
    } else {
      toastr.info(action.data.message);
    }

    return newState
  }
)

reducerFactory.addAction(
  "FETCH_USER_TM_COUNT",
  "fetchUserTmCount",
    async (params)=>{
        const response = await ProjectApi.fetchTmCountByQuery({query:{...params,translationType:"USER"}});
        return response;
    },
    (state,action)=>{
        const newState = Object.assign({},state);
        if (action.data.success) {
          newState.userTranslatedCount = action.data.data
        } else {
            console.log("Error fetching user translated tm count")
        }
        return newState;
    }
);

reducerFactory.addAction(
  "FETCH_MACHINE_TM_COUNT",
  "fetchMachineTmCount",
    async (params)=>{
        const response = await ProjectApi.fetchTmCountByQuery({query:{...params,translationType:"MACHINE"}});
        return response;
    },
    (state,action)=>{
        const newState = Object.assign({},state);
        if (action.data.success) {
          newState.machineTranslatedCount = action.data.data
        } else {
            console.log("Error fetching machine translated tm count")
        }
        return newState;
    }
);

reducerFactory.addAction(
  "FETCH_PBK_TM_COUNT",
  "fetchPbkTmCount",
    async (params)=>{
        const response = await ProjectApi.fetchTmCountByQuery({query:{...params,translationType:"PBK"}});
        return response;
    },
    (state,action)=>{
        const newState = Object.assign({},state);
        if (action.data.success) {
          newState.pbkTranslatedCount = action.data.data
        } else {
            console.log("Error fetching pbk translated tm count")
        }
        return newState;
    }
);

reducerFactory.addAction(
  "FETCH_NO_TM_COUNT",
  "fetchNoTransTmCount",
    async (params)=>{
        const response = await ProjectApi.fetchTmCountByQuery({query:{...params,translationType:"NO_TRANSLATION_AVAILABLE"}});
        return response;
    },
    (state,action)=>{
        const newState = Object.assign({},state);
        if (action.data.success) {
          newState.newContentCount = action.data.data
        } else {
            console.log("Error fetching new content tm count")
        }
        return newState;
    }
);

reducerFactory.addAction(
  "FETCH_DOMAINS",
  "fetchAllDomains",
    async (params)=>{
        const response = await ProjectApi.fetchAllDomains(params);
        return response;
    },
    (state,action)=>{
        const newState = Object.assign({},state);
        if (action.data.success) {
          newState.domains = action.data.data
        } else {
            console.log("Error fetching domains")
        }
        return newState;
    }
);

reducerFactory.addAction(
  "FETCH_DOMAIN_WISE_URL",
  "fetchDomainWiseUrl",
    async (params)=>{
      reducerFactory.action(`${reducerName}Loading`, true);
        const response = await ProjectApi.fetchDomainWiseUrl(params);
        return response;
    },
    (state,action)=>{
        const newState = Object.assign({},state);
        if (action.data.success) {
          newState.allUrlList = action.data.data.urls
        } else {
            console.log("Error fetching domains")
        }
        newState.loading=false;
        return newState;
    }
);

reducerFactory.addAction(
  "UPDATE_TRANSLATION_COUNT",
  "updateTranslationCount",
    async (params)=>{
      return params;
    },
    (state,action)=>{
      const {data} = action
        const newState = Object.assign({},state);
        newState[data.from] = newState[data.from] - data.count;
        newState[data.to] = newState[data.to] + data.count;
        return newState;
    }
);
reducerFactory.addAction(
  "ANALYSIS_WORD_COUNT","analysisWordCount",
    async (projectId)=>{
       toastr.info("Please wait while we analysis wordcount.Wordcount will get updated shortly in projectInfo");
        const response = await ProjectApi.analysisWordCount(projectId);
        return response;
    },
    (state,action)=>{
        const newState = Object.assign({},state);
        if (action.data.success) {
          // newState.domains = action.data.
        } else {
            console.log("Error fetching domains")
        }
        return newState;
    }
);

reducerFactory.addAction(
  "PROJECT_ACCESS_VERTIFICATION","projectAccessVerification",
    async (params)=>{
        const response = await ProjectApi.projectAccessVerification(params);
        return response;
    },
    (state,action)=>{
        const newState = Object.assign({},state);
        if (action.data.success) {
          // newState.domains = action.data.
        } else {
            // console.log("Error fetching domains")
        }
        return newState;
    }
);

reducerFactory.addAction(
  "API_TRANSLATION_PROJECT","apTranslationProject",
async (params)=>{
  const response = await ProjectApi.apTranslationProject(params);
  return response;
},
(state,action)=>{
  const newState =Object.assign({},state);
  if(action.data.success){
      toastr.success("Project Created Successfully");
  }else{
    toastr.info("Project with already exists");
  }
  return newState;
}
);

reducerFactory.addAction(
  "DELETE_API_TRANSLATION_PROJECT","deleteApiTranslationProject",
  async (params)=>{
    const response = await ProjectApi.deleteApiTranslationProject(params);
    return response;
  },
  (state,action)=>{
    const newState = Object.assign({},state);
    console.log("state",action);
    if(action.data.success){
      toastr.success("Project Deleted Successfully");
    }else{
      toastr.warning("Error while deleting the project");
    }
    return newState;
  }
);
reducerFactory.addAction(
  "SET_GLOSSARY_TRANSLITERATION_DATA",
  "setGlossaryTransliterationData",
  async (params) => {
    // reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.setGlossaryTransliterationData(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    console.log(action.data)
    if (action.data.success) {
      toastr.success("Glossary Transliteration set successfully");
    } else {
      toastr.error("“Please upload a correct format");
    }
    // newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "GET_GLOSSARY_TRANSLITERATION_DATA",
  "getGlossaryTransliterationData",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.getGlossaryTransliterationData(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      newState.glossarytransliterationData = action.data.data.data;
      newState.glossarytransliterationDataCount = action.data.data.totalCount;
      // toastr.success("Transliteration data fetched successfully");
    } else {
      toastr.error("Some error occurred. Please try again");
    }
    newState.loading = false;
    return newState;
  }
);
reducerFactory.addAction(
  "DELETE_GLOSSARY_TM",
  "deleteGlossaryTm",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.deleteGlossaryTm(params);
    return response;
  },
  (state, action) => {
    const newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success("Segment deleted successfully");
    } else {
      toastr.error("Some error occurred. Please try again");
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction(
  "FETCH_GLOSSARY_LIST",
  "fectchGlossaryList",
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await ProjectApi.fectchGlossaryList(params);
    return response;
  },
  (state, action) => {
      const url = window.URL.createObjectURL(new Blob([action?.data])) 
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "maskTermsList.csv")
      document.body.appendChild(link)
      link.click()
      link.remove()
      toastr.success("Mask terms List Downloaded  successfully");
    return state;
  }
);



export default reducerFactory;
