import axios from "./index";

//route to fetch all organisations by page
export async function fetch(params) {
  return axios.post("organisation/list", params).then((res) => res.data);
}

//route to fetch all organisations
export async function fetchAll() {
  return axios.post("organisation/all").then((res) => res.data);
}

//route to fetch organisation details
export async function fetchOrganisationDetails(organisationId) {
  return axios
    .post("organisation/details", { organisationId })
    .then((res) => res.data);
}

//route to edit organisation data

export async function editOrganisation(organisationId, editObject) {
  return axios
    .post("organisation/edit", {
      organisationId,
      editObject,
    })
    .then((res) => res.data);
}

//route to create new organisation
export async function createOrganisation(params) {
  return axios.post("auth/newOrganisation", params).then((res) => res.data);
}