import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import SignIn from '../pages/AuthPages/SignIn'
import SignUp from '../pages/AuthPages/SignUp'
import { connect } from 'react-redux';
import EmailAuthentication from './AuthPages/EmailAuthentication';
import ForgotPassword from './AuthPages/ForgotPassword';
import UpdatePassword from './AuthPages/UpdatePassword';
import authReducer from '../redux/modules/auth';
import { Row, Col } from 'antd';
import LeftBanner from '../components/layout/LeftBanner/LeftBanner';
import NotificationBanner from './AuthPages/NotificationBanner/NotificationBanner';
import BackgroundWave from "../assets/images/freeTrialBackground.svg";
import TermsAndConditions from '../components/Modals/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../components/Modals/privacyPolicy/privacyPolicy';

class AuthRouter extends Component {
    componentDidMount() {
        this.verifyTokenExist()
    }

    verifyTokenExist = () => {
        const { authReducer } = this.props;
        authReducer.verifyClientToken()
    }
    render() {
        const freeTrial = this.props.location.pathname.includes("/register");
        const policy = this.props.location.pathname.includes("/privacy-policy");
        const leftBannnerSpan=freeTrial || policy ? 24 : 10;
        const bannerStyle = freeTrial || policy
            ? {
                width: "100%",
                backgroundColor: "#002e43",
                height: "99.9vh",
                backgroundImage: `url(${BackgroundWave})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                overflowY: "hidden",
               
            }
            : {
                width: "100%",
                backgroundColor: "#002e43",
                height: "100vh",
            };
        return (
                <Switch>
                    <Route path='/' exact component={SignUp} />
                    <Route path='/sign-in' exact component={SignIn} />
                    <Route path='/forgotPassword' exact component={ForgotPassword} />
                    <Route path='/verification/:uuid' exact component={EmailAuthentication} />
                    <Route path='/new-password/:token' component={UpdatePassword} />
                    <Route path='/notification/:message' exact component={NotificationBanner} />
                    {/* <Route path='/privacy-policy' exact render={() => <>
                        <div style={{ padding: "10px 200px", background: "aliceblue" }}> <TermsAndConditions /></div>
                    </>} /> */
                    }
                    <Route path='/privacy-policy' exact render={() => <>
                        <div style={{ padding: "10px 140px", background: "aliceblue" }}> <PrivacyPolicy /></div>
                    </>} />
                    <Route path="/register" exact render={() => <>
                        <div style={bannerStyle}> <LeftBanner freeTrial={freeTrial} /></div>
                    </>} />
                    <Redirect to="/" />
                </Switch>
        )
           
    }
}

export default connect(
    state => ({
        freeOrgProjectId: state.get("project").freeOrgProjectId,
    }),
    dispatch => ({
        authReducer: authReducer.getActions(dispatch)
    })
)(withRouter(AuthRouter))