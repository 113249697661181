import React, { Component } from "react";
import SignUpForm from "../../components/forms/SignUpForm";
import { connect } from "react-redux";
import authReducer from "../../redux/modules/auth";
import { Content } from "antd/lib/layout/layout";
import { Image } from "antd";
import SignupIllustration from "../../assets/images/Illustrations/login.svg";
import FreeOrgAdminForm from "../../components/forms/FreeOrgAdminFormNew/FreeOrgAdminFormNew";
import LeftBanner from "../../components/layout/LeftBanner/LeftBanner";
import BackgroundWave from "../../assets/images/freeTrialBackground.svg";
import AnuvadakLogo from "../../assets/images/anuvadak-login-logo.png"
import bagroundImage from "../../assets/images/bg1.png";
import { Row, Col } from 'antd';


class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onSubmitForm = async (formData) => {
    const { authReducer } = this.props;
    const data = await authReducer.signUp(formData);
    if (data.success) {
      const message =
        "Verification email has been sent to the registered email id".replace(
          " ",
          "%20"
        );
      this.props.history.push(`/notification/${message}`);
    }
  };
  render() {
    const freeTrial = this.props.location.pathname.includes("/register");
    console.log(freeTrial,"------");
    const policy = this.props.location.pathname.includes("/privacy-policy");
    const leftBannnerSpan=freeTrial || policy ? 24 : 10;
    const bannerStyle = freeTrial || policy
        ? {
            width: "100%",
            backgroundColor: "#002e43",
            height: "99.9vh",
            backgroundImage: `url(${BackgroundWave})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            overflowY: "hidden",
           
        }
        : {
            width: "100%",
            backgroundColor: "#002e43",
            height: "100vh",
        };
    return (
      <Row style={{
        backgroundImage:`url(${bagroundImage})`,
        backgroundRepeat: 'repeat',
        // aspectRatio:'16/7.7',
        // backgroundSize:'cover'
         minHeight:'100vh'
         }}>
           {/* {!(freeTrial || policy ) && <Col xs={24} sm={24} md={24} lg={14} xl={14} span={14} >
                <div style={bannerStyle}> <LeftBanner freeTrial={freeTrial} /></div>
            </Col>} */}
           <div
             style={{
                 display: "flex",
                 justifyContent: 'center',
                 padding:'2.5rem 2rem',
                 paddingBottom:'0px'

              }}
             >
                <img src={AnuvadakLogo} height={40} id="login-logo" alt="anuvadak-logo" />
          </div>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
           <Content className="signup-container">
           <FreeOrgAdminForm/>
         </Content>
       </Col>
    </Row>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    authReducer: authReducer.getActions(dispatch),
  })
)(SignUp);
