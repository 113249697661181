import React, { useState } from 'react';
import {
    Button, Form, Input
} from "antd";
import { Link } from "react-router-dom";

const ForgotPasswordForm = ({ onSubmitForm, propsObject, uiConfig }) => {
    const [email, setEmail] = useState("");
    const onChangeInputField = (inputField) => {
        setEmail(inputField.email);
    }
    return <div>
        <div className="text-center"><h2>Forgot Pasword</h2></div>
        {propsObject.mailSent ? propsObject.message : ''}
        {uiConfig.mailerServiceEnabled === "true" ? "" : <div style={{ fontSize: "14px", marginLeft: "10px", color: "red" }}>{`Whitelist Email Service`}</div>}
        <div>
            <Form
                name="basic"
                initialValues={{ remember: true }}
                className="row-col"
                onValuesChange={onChangeInputField}
            >
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: "Please input your email!" },
                    ]}
                >
                    <Input type="email" disabled={!uiConfig.mailerServiceEnabled} placeholder="Enter your valid email id." />
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{ width: "100%" }}
                        onClick={() => onSubmitForm(email)}
                        type="primary"
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
        <div>
            <p className="font-semibold text-muted text-center">
                Already have an account?{" "}
                <Link to="/sign-in" className="font-bold text-dark">
                    Sign In
                </Link>
            </p>
        </div>
    </div>
}

export default ForgotPasswordForm;