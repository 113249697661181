import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useParams ,useLocation} from "react-router-dom";
import authReducer from "../../redux/modules/auth";
import { Spin, Image } from "antd";
import { Content } from "antd/lib/layout/layout";
import VerifyingIllustration from "../../assets/images/Illustrations/verify_email.svg";
import { Row } from "antd";

const EmailAuthentication = ({ authReducer }) => {
  let history = useHistory();
  const { uuid } = useParams();
  useEffect(() => {
    authReducer.verifyEmailId({uuid}).then(res=>{
      if(!res?.success){
        history.push('/');
      }else{
        history.push('/app');
      }
    });
  }, []);

  return (
    <Row>
      <Content className="sign-container">
        <div
          className="flex-row"
          style={{
            justifyContent: "center",
            padding: "3em 0 3em 0",
          }}
        >
          <Image height={400} src={VerifyingIllustration} preview={false} />
        </div>
        <Spin
          size="large"
          tip="Please wait don't refresh your page, while we are verifying your email Id....."
        />
      </Content>
    </Row>
  );
};

export default connect(
  (state) => ({}),
  (dispatch) => ({
    authReducer: authReducer.getActions(dispatch),
  })
)(EmailAuthentication);
