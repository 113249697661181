import React, { Component } from "react";
import { connect } from "react-redux";
import authReducer from "../../redux/modules/auth";
import PropsTypes from "prop-types";
import { Image, Layout } from "antd";
import { Row, Col } from 'antd';
import SignInForm from "../../components/forms/SignInForm";
import SigninIllustration from "../../assets/images/Illustrations/login.svg";
import LeftBanner from "../../components/layout/LeftBanner/LeftBanner";
import BackgroundWave from "../../assets/images/freeTrialBackground.svg";
import toastr from 'toastr';
const { Content } = Layout;

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attempts: 0,
      locked: false,
    };
  }
  onSubmitForm = (formData) => {
    const { recaptchaValue } =formData;
    // if(!recaptchaValue){
    //   toastr.warning("Please validate Captcha");
    //   return
    // }
    const { authReducer } = this.props;
    authReducer.login(formData).then((response) => {
      if (
        !response?.success &&
        response?.data
      ) {
        const { locked, attempts } = response.data;
        this.setState({
          locked,
          attempts,
        });
      }
    });
  };

  render() {
    const { locked, attempts } = this.state;
    const freeTrial = this.props.location.pathname.includes("/register");
    const policy = this.props.location.pathname.includes("/privacy-policy");
    const leftBannnerSpan=freeTrial || policy ? 24 : 10;
    const bannerStyle = freeTrial || policy
        ? {
            width: "100%",
            backgroundColor: "#002e43",
            height: "99.9vh",
            backgroundImage: `url(${BackgroundWave})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            overflowY: "hidden",
           
        }
        : {
            width: "100%",
            backgroundColor: "#002e43",
            height: "100vh",
        };
    return (
      <Row>
      {!(freeTrial || policy ) && <Col xs={24} sm={24} md={24} lg={14} xl={14} span={14} >
           <div style={bannerStyle}> <LeftBanner freeTrial={freeTrial} /></div>
       </Col>}
     <Col xs={24} sm={24} md={24} lg={leftBannnerSpan} xl={leftBannnerSpan}>
          <Content className="sign-container">
        <div
          className="flex-row"
          style={{
            justifyContent: "center",
            padding: "3em 0 3em 0",
          }}
        >
          <Image height={100} src={SigninIllustration} preview={false} />
        </div>
        <SignInForm
          onSubmitForm={(value) => this.onSubmitForm(value)}
          locked={locked}
          attempts={attempts}
        />
          </Content>
      </Col>
    </Row>
    );
  }
}

SignIn.propsTypes = {
  authReducer: PropsTypes.object.isRequired,
};
export default connect(
  (state) => ({}),
  (dispatch) => ({
    authReducer: authReducer.getActions(dispatch),
  })
)(SignIn);
