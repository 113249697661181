import axios from "./index";

export async function multiDomainScopeStats(params){
    return await axios.post('/multi/domain/stats', params).then(res => res.data)
}
export async function fetchDomains(params){
    return await axios.post('/fetch/domains', params).then(res => res.data)
}
export async function fetchDomainScope(body){
    return await axios.post('/paginated/domain/list', body).then(res => res.data)
}

export async function fetchMultiDomains(body){
    return await axios.post('/domains', body).then(res => res.data)
}
export async function assignColloborator(body){
    return await axios.post('/domains/assignColloborator', body).then(res => res.data)
}

export async function getCollaboratorAssignedList(body){
    return await axios.post('/domains/getCollaboratorAssignedList', body).then(res => res.data)
}

export async function onMultiDomainColloboratorDelete(body){
    return await axios.post('/domains/onMultiDomainColloboratorDelete', body).then(res => res.data)
}

export async function fetchDomainWiseWordCount(body){
    return await axios.post('/domain/wise/wordcount', body).then(res => res.data)
}

export async function fetchDomainWiseTraffic(body){
    return await axios.post('/domain/wise/traffic', body).then(res => res.data)
}
export async function fetchDomainWiseNMTCall(body){
    return await axios.post('/domain/wise/nmtCall', body).then(res => res.data)
}

export async function toggleDomainActiveStatus(body){
    return await axios.post('/domain/toggle', body).then(res => res.data)
}

export async function updateDomainScope(body){
    return await axios.post('/domain/update', body).then(res => res.data)
}