import ReducerFactory from "../../utils/reducerFactory";
import toastr from "toastr";
import * as DemoApi from "../api/demo";

const reducerName = "demo";
const initialState = {
  demoIds: [],
  totalDemoIds: 0,
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

//create new Demo Id
reducerFactory.addAction(
  "CREATE_DEMO_ID",
  "createDemoId",
  async (params) => {
    const response = await DemoApi.createDemoId(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.data?.success) {
      toastr.success("Demo Id generated");
    }
    return newState;
  }
);

//fetch Demo Ids
reducerFactory.addAction(
  "FETCH_DEMO_IDS",
  "fetchDemoIds",
  async (params) => {
    const response = await DemoApi.fetchDemoIds(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.success) {
      newState.demoIds = action.data.data.demoIdList;
      newState.totalDemoIds = action.data.data.totalDemoIds;
    }
    return newState;
  }
);

//edit Demo Id
reducerFactory.addAction(
  "EDIT_DEMO_IDS",
  "editDemoEntry",
  async (params) => {
    const response = await DemoApi.editDemoEntry(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.success) {
      toastr.success("Entry edited successfully");
    } else {
      toastr.error("Edit failed");
    }
    return newState;
  }
);

//delete demo ID
reducerFactory.addAction(
  "DELETE_DEMO_ID",
  "deleteDemoEntry",
  async (params) => {
    const response = await DemoApi.deleteDemoEntry(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.success) {
      toastr.success("Entry delete successfully");
    } else {
      toastr.error("Delete failed");
    }
    return newState;
  }
);


export default reducerFactory;
