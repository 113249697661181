import React from "react";
import ForgotPasswordForm from "../../components/forms/ForgotPasswordForm";
import { connect } from "react-redux";
import authReducer from "../../redux/modules/auth";
import { Image, Layout } from "antd";
import ForgotPasswordIllustration from "../../assets/images/Illustrations/forgot_password.svg";
import LeftBanner from "../../components/layout/LeftBanner/LeftBanner";
import BackgroundWave from "../../assets/images/freeTrialBackground.svg"
import { Row, Col } from 'antd';
const { Content } = Layout;

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "Set password link has been sent to your mail Id",
      mailSent: false,
    };
  }
  onSubmitForm = (email) => {
    const { authReducer } = this.props;
    authReducer.forgotPassword(email).then((res) => {
      if (res.success) {
        this.setState({ mailSent: true });
      }
    });
  };

  render() {
    const { passwordInputType, confirmPasswordInputType } = this.state;
    const freeTrial = this.props.location.pathname.includes("/register");
    const policy = this.props.location.pathname.includes("/privacy-policy");
    const leftBannnerSpan = freeTrial || policy ? 24 : 10;
    const bannerStyle = freeTrial || policy
      ? {
        width: "100%",
        backgroundColor: "#002e43",
        height: "99.9vh",
        backgroundImage: `url(${BackgroundWave})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflowY: "hidden",

      }
      : {
        width: "100%",
        backgroundColor: "#002e43",
        height: "100vh",
      };
    const { uiConfig } = this.props;
    return (
      <Row>
        {!(freeTrial || policy) && <Col xs={24} sm={24} md={24} lg={14} xl={14} span={14} >
          <div style={bannerStyle}> <LeftBanner freeTrial={freeTrial} /></div>
        </Col>}
        <Col xs={24} sm={24} md={24} lg={leftBannnerSpan} xl={leftBannnerSpan}>
          <Content className="sign-container">
            <div
              className="flex-row"
              style={{
                justifyContent: "center",
                padding: "3em 0 3em 0",
              }}
            >
              <Image
                height={150}
                src={ForgotPasswordIllustration}
                preview={false}
              />
            </div>
            <ForgotPasswordForm
              onSubmitForm={this.onSubmitForm}
              propsObject={this.state}
              uiConfig={uiConfig}
            />
          </Content>
        </Col>
      </Row>
    );
  }
}

export default connect(
  (state) => ({
    uiConfig: state.get("authentication").uiConfig,
  }),
  (dispatch) => ({
    authReducer: authReducer.getActions(dispatch),
  })
)(ForgotPassword);
