import axios from "./index";

export async function addToScope(formValues) {
    return await axios.post(`/scope/add`, formValues).then(
        res => res.data
    )
}

//fetch url list using project id
export async function fetchUrlList(params) {

    const { projectId, pageNumber, pageSize, searchText, selecteditems } = params;
    return axios.get(`/scope/${projectId}/${pageNumber}/${pageSize}?searchText=${searchText}`,)
        .then((res) => res.data);
}

//fetch url list using project id and Dominselected
export async function fetchUrlListbyDomain(params) {
    const { projectId, pageNumber, pageSize, searchText, selecteditems } = params;
    return axios.post(`/scope/fetchByDomain`, { projectId: projectId, pageNumber: pageNumber, pageSize: pageSize, searchText: searchText, selecteditems: selecteditems },)
        .then((res) => res.data);
}

//fetch url list using project id and Dominselected
export async function fetchUrlListbyurl(params) {
    const { projectId, pageNumber, pageSize, searchText, selectedurl } = params;
    return axios.post(`/scope/fetchByurl`, { projectId: projectId, pageNumber: pageNumber, pageSize: pageSize, searchText: searchText, selectedurl: selectedurl },)
        .then((res) => res.data);
}
//fetch url list using project id
export async function fetchUniqueDomains(params) {
    const { projectId, pageNumber, pageSize, searchText } = params;
    return axios.post(`/scope/Uniquedomains`, { projectId: projectId })
        .then((res) => res.data);
}

// toggle ignoreUrl field
export async function toggleScope(body) {
    return axios.post(`/scope/toggle`, body).then(res => res.data);
}
//taggle track url field
export async function toggleTrackUrl(body) {
    return axios.post(`/scope/tracking/toggle`, body).then(res => res.data);
}

// toggle ignoreUrl field
export async function projectResync(body) {
    return axios.post(`/scope/project/resync`, body).then(res => res.data);
}
//get Tracking Urls
export async function getTrackingUrls(params) {

    return await axios.post("/scope/getTrackingUrls", params).then(res => res.data)
}

//get TrackingFiles List By Url
export async function getTrackingFilesListByUrl(params) {

    return await axios.post("/scope/getTrackingFilesListByUrl", params).then(res => res.data)
}


//get TrackingFiles src for the slected date
export async function getTrackingFilesrc(params) {

    return await axios.post("/scope/getTrackingFilesrc", params).then(res => res.data)
}

//get htmls for selected date to compare both the htmls
export async function getFilehtmls(params) {

    return await axios.post("/scope/getFilehtmls", params).then(res => res.data)
}

export async function enableUrlTrack(params) {

    return await axios.post("/scope/enableUrlTrack", params).then(res => res.data)
}

export async function fetchActiveUrls(formData) {
    console.log(formData);
    const config = {
        headers: {
            "content-type": "multipart/form-data",
        },
    };
    return await axios.post("scope/upload", formData, config).then(res => res.data)
}

export async function projectContentTracker(params) {

    return await axios.post("/tracker/projectContentTracker", params).then(res => res.data)
}

export async function verifyScriptIntegration(params) {

    return await axios.post("/parser/verifyScriptIntegration", params).then(res => res.data)
}

export async function getPreviewUrl(params) {

    return await axios.post("/scope/getPreviewUrl", params).then(res => res.data)
}



