import * as AuthApi from '../api/auth';
import toastr from 'toastr';
import ReducerFactory from '../../utils/reducerFactory';

const reducerName = 'authentication';
const initialState = {
  loading: true,
  user: {},
  token: null,
  isLoggedIn: true,
  uiConfig: {
    axiosBaseUrl: "",
    uiUrl: "",
    swalekhUrl: "",
    keyboard: "",
    keyboardMode: "phonetic",
    renderBaseUrl: "",
    renderSubDomain: false,
  },
  sendOtpLoader: false,
  resendOtpLoader: false,
  verifyOtpLoader: false,
  submitFormLoader: false
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

reducerFactory.addAction('AUTH_LOADING', `${reducerName}Loading`,
  (status) => status, (state, action) => {
    const newState = Object.assign({}, state);
    newState.loading = action.data;
    return newState;
  }
);

reducerFactory.addAction('LOGOUT', `logout`,
  (status) => status, (state, action) => {
    const newState = Object.assign({}, state);
    newState.isLoggedIn = false;
    newState.user = {}
    return newState;
  }
);

reducerFactory.addAction(
  "LOGIN_REDIRECT",
  `loginRedirect`,
  (status) => status,
  (state, action) => {
    const newState = Object.assign({}, state);
    newState.isLoggedIn = true;
    return newState;
  }
);


reducerFactory.addAction('SEND_OTP_LOADING', 'sendOtpLoader',
  (status) => status, (state, action) => {
    const newState = Object.assign({}, state);
    newState.sendOtpLoader = action.data;
    return newState;
  }
);
reducerFactory.addAction('RESEND_OTP_LOADING', 'resendOtpLoader',
  (status) => status, (state, action) => {
    const newState = Object.assign({}, state);
    newState.resendOtpLoader = action.data;
    return newState;
  }
);
reducerFactory.addAction('VERIFY_OTP_LOADING', 'verifyOtpLoader',
  (status) => status, (state, action) => {
    const newState = Object.assign({}, state);
    newState.verifyOtpLoader = action.data;
    return newState;
  }
);
reducerFactory.addAction('SUBMIT_FORM_LOADING', 'submitFormLoader',
  (status) => status, (state, action) => {
    const newState = Object.assign({}, state);
    newState.submitFormLoader = action.data;
    return newState;
  }
);


reducerFactory.addAction('LOGIN', 'login',
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await AuthApi.login(formValues);
    return response;
  }, (state, action) => {
    let newState;
    if (action.data.success) {
      window.localStorage.setItem('avk_token', action.data.data.token);
      window.localStorage.setItem('role', btoa(action.data.data.user.role));
      newState = Object.assign({}, state);
      newState.isLoggedIn = true;
      newState.user = action.data.data.user;
      newState.accountLocked = false;
    } else {
      newState = Object.assign({}, state);
      newState.error = action.data.message;
      toastr.warning(action.data.message);
      newState.isLoggedIn = false;
      newState.attemptsRemaining = 2
      newState.accountLocked = true
    }
    newState.loading = false;
    return newState;
  }
);


reducerFactory.addAction('SIGN_UP', 'signUp',
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await AuthApi.signUp(formValues);
    return response;
  }, (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      toastr.success(action.data.message);
    } else {
      newState = Object.assign({}, state);
      newState.error = action.data.message;
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);


reducerFactory.addAction('VERIFY_EMAIL_ID', 'verifyEmailId',
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await AuthApi.verifyEmailId(formValues);
    return response;
  }, (state, action) => {
    let newState;
    if (action.data.success) {
      window.localStorage.setItem('avk_token', action.data.data);
      newState = Object.assign({}, state);
      newState.isLoggedIn = true;
    } else {
      newState = Object.assign({}, state);
      newState.error = action.data.message;
      toastr.warning(action.data.message);
      newState.isLoggedIn = false;
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('VERIFY_CLIENT_TOKEN', 'verifyClientToken',
  async () => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await AuthApi.verifyClientToken();
    return response;
  }, (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.user = action.data.data.user;
    } else {
      newState = Object.assign({}, state);
      newState.isLoggedIn = false;
    }
    newState.loading = false
    return newState;
  }
);

reducerFactory.addAction('FETCH_CONFIG', 'fetchConfig',
  async () => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await AuthApi.fetchConfig();
    return response;
  }, (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.uiConfig = action.data.data;
    } else {
      newState = Object.assign({}, state);
      // toastr.warning(action.data.message);
    }
    newState.loading = false
    return newState;
  }
);

reducerFactory.addAction('USER_VERIFICATION', 'userVerification',
  async (formData) => {
    const response = await AuthApi.userVerification(formData);
    return response;
  }, (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
      newState.authDetails = action.data.data;
    } else {
      newState = Object.assign({}, state);
    }
    newState.loading = false
    return newState;
  }
);

reducerFactory.addAction('UPDATE_PASSWORD', 'updatePassword',
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await AuthApi.updatePassword(formValues);
    return response;
  }, (state, action) => {
    let newState;
    if (action.data.success) {
      window.localStorage.setItem('avk_token', action.data.data);
      newState = Object.assign({}, state);
      newState.isLoggedIn = true;
      toastr.success(action.data.message)
    } else {
      newState = Object.assign({}, state);
      newState.error = action.data.message;
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('FORGOT_PASSWORD', 'forgotPassword',
  async (formValues) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await AuthApi.forgotPassword(formValues);
    return response;
  }, (state, action) => {
    let newState;
    if (action.data.success) {
      newState = Object.assign({}, state);
    } else {
      newState = Object.assign({}, state);
      newState.error = action.data.message;
      toastr.warning(action.data.message);
    }
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('CHECK_PROJECT_EXISTS', 'checkProjectExists',
  async (params) => {
    reducerFactory.action(`${reducerName}Loading`, true);
    const response = await AuthApi.checkProjectExists(params);
    return response;
  }, (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    newState.loading = false;
    return newState;
  }
);

reducerFactory.addAction('VERIFY_OTP', 'verifyOtp',
  async (params) => {
    reducerFactory.action(`verifyOtpLoader`, true);
    const response = await AuthApi.verifyOtp(params);
    return response;
  }, (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message)
    } else {
      toastr.warning(action.data.message)
    }
    newState.resendOtpLoader = false;
    newState.sendOtpLoader = false;
    newState.verifyOtpLoader = false;
    return newState;
  }
);

reducerFactory.addAction('SEND_OTP', 'sendOtp',
  async (params) => {
    reducerFactory.action("sendOtpLoader", true);
    const response = await AuthApi.sendOtp(params);
    return response;
  }, (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message)
    } else {
      toastr.warning(action.data.message)
    }
    newState.resendOtpLoader = false;
    newState.sendOtpLoader = false;
    newState.verifyOtpLoader = false;
    return newState;
  }
);

reducerFactory.addAction('RESEND_OTP', 'resendOtp',
  async (params) => {
    reducerFactory.action("resendOtpLoader", true);
    const response = await AuthApi.resendOtp(params);
    return response;
  }, (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message)
    } else {
      toastr.warning(action.data.message)
    }
    newState.resendOtpLoader = false;
    newState.sendOtpLoader = false;
    newState.verifyOtpLoader = false;
    return newState;
  }
);
reducerFactory.addAction('ONBOARD_FREE_ORG_ADMIN', 'onBoardFreeOrgAdmin',
  async (params) => {
    reducerFactory.action("submitFormLoader", true);
    const response = await AuthApi.onBoardFreeOrgAdmin(params);
    return response;
  }, (state, action) => {
    let newState = Object.assign({}, state);
    if (action.data.success) {
      toastr.success(action.data.message)
      newState.isLoggedIn = true;
    } else {
      toastr.warning(action.data.message)
    }
    newState.resendOtpLoader = false;
    newState.sendOtpLoader = false;
    newState.verifyOtpLoader = false;
    newState.submitFormLoader = false;
    return newState;
  }
);

reducerFactory.addAction('CONTRACT_AGREED', 'contractAgreed',
  async (params) => {
    return {};
  }, (state, action) => {
    let newState = Object.assign({}, state);
    newState.user.contractAgreed = true;
    return newState;
  }
);

// reducerFactory.addAction('SET_LOGIN_TOKEN', "setLoginToken",
// async(params)=>{
// },(state,action)=>{
//   let newState = Object.assign({},state)
//   newState.isLoggedIn = true;
//   return newState;
// })

export default reducerFactory;