import React, { Component, Suspense } from 'react';
import "antd/dist/antd.css";
import { connect } from 'react-redux';
import "./assets/styles/main.css";
import "./assets/styles/common.css";
import "./assets/styles/responsive.css";
import AuthRouter from './pages/AuthRouter';
import { withRouter } from 'react-router-dom';
// import AppRouter from './pages/AppRouter';
import authReducer from './redux/modules/auth';
import { Spin } from 'antd'
import configUI from './appConfig';
const AppRouter = React.lazy(() => import('./pages/AppRouter'));
const FreeOrgRouter = React.lazy(() => import('./pages/FreeOrgRouter'));



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    this.fetchConfig()
  }

  verifyTokenExist = () => {
    const { authReducer } = this.props;
    authReducer.verifyClientToken()
  }

  fetchConfig = () => {
    const { authReducer } = this.props;
    authReducer.fetchConfig().then(res => {
      if (res && res.success) {
        this.verifyTokenExist()
      }
    })
  }

  render() {
    const { isLoggedIn, location, user } = this.props;
    if (!isLoggedIn || location.pathname.includes("/verification/") || location.pathname.includes("/new-password")) {
      return<AuthRouter />
    } else if ( user && user.role === configUI.roleObjMap.FREE_ORG_ADMIN ) {
      return (
        <Suspense
          fallback={
            <Spin spinning={!isLoggedIn} size="large" tip="Loading..." />
          }
        >
          <FreeOrgRouter />
        </Suspense>
      );
    } else {
      return <Suspense fallback={<Spin spinning={!isLoggedIn} size="large" tip="Loading..." />}>
        <AppRouter />
      </Suspense>
    }
  }
}

export default withRouter(connect(state => ({
  isLoggedIn: state.get("authentication").isLoggedIn,
  user: state.get("authentication").user
}), dispatch => ({
  authReducer: authReducer.getActions(dispatch)
}))(App));
