import ReducerFactory from "../../utils/reducerFactory";
import toastr from "toastr";
import * as DashboardApi from "../api/dashboard";

const reducerName = "dashboard";
const initialState = {
  stat: {},
  monthWiseStat: [],
  domainWiseStat: [],
  downloadData: [],
  siteVisitData: []
};

const reducerFactory = new ReducerFactory(reducerName, initialState);

//fetch role wise stat
reducerFactory.addAction(
  "FETCH_STAT",
  "fetchStat",
  async (params) => {
    const response = await DashboardApi.fetchStat(params);
    return response;
  },
  (state, action) => {
    let newState;
    newState = Object.assign({}, state);
    if (action?.data?.data?.success) {
      newState.stat = action.data.data.data;
    }
    return newState;
  }
);

//fetch monthwise stat
reducerFactory.addAction(
  "FETCH_MONTHWISE_STAT",
  "fetchMonthWiseStat",
  async (params) => {
    const response = await DashboardApi.fetchMonthWiseStat(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.data.success) {
      newState = Object.assign({}, state);
      newState.monthWiseStat = action.data.data.data.monthWiseData;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    return newState;
  }
);

//fetch domain wise stat
reducerFactory.addAction(
  "FETCH_DOMAINWISE_STAT",
  "fetchDomainWiseStat",
  async (params) => {
    const response = await DashboardApi.fetchDomainWiseStat(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.data.success) {
      newState = Object.assign({}, state);
      newState.domainWiseStat = action.data.data.data.domainWiseData;
      newState.siteVisitData = action.data.data.data.siteVisitData;
      newState.totalLanguages = action.data.data.data.totalLanguages;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    return newState;
  }
);

//fetch data to be downloaded
reducerFactory.addAction(
  "DOWNLOAD_REPORT",
  "downloadReport",
  async (params) => {
    const response = await DashboardApi.downloadReport(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.data.success) {
      newState = Object.assign({}, state);
      newState.downloadData = action.data.data.data.downloadData;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    return newState;
  }
);

//fetch Url Wise Stats
reducerFactory.addAction(
  "URL_WISE_STATS",
  "fetchUrlWiseStats",
  async (params) => {
    const response = await DashboardApi.fetchUrlWiseStats(params);
    return response;
  },
  (state, action) => {
    let newState;
    if (action.data.data.success) {
      newState = Object.assign({}, state);
      newState.urlWiseStatData = action.data.data.data;
    } else {
      newState = Object.assign({}, state);
      toastr.warning(action.data.message);
    }
    return newState;
  }
);

export default reducerFactory;

